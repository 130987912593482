import * as React from "react";
import { Image } from "react-native";
import { RPCError, RPCErrorCode } from "magic-sdk";
import { magicInstance } from "./connectors/magic";
import { MAGIC_LINK, MAGIC_OAUTH_APPLE, MAGIC_OAUTH_GOOGLE} from "./connectors/types";
import Web3 from "web3";

export const Wallets = [
  {
    name: "Magic",
    logo: (props) => {
      return <Image source={require("../assets/magic.svg")} {...props} />;
    },
  },
];

export async function activateConnector(connector, args) {
  let connectorInstance;
  switch (connector) {
    case MAGIC_LINK:
    case MAGIC_OAUTH_APPLE:
    case MAGIC_OAUTH_GOOGLE:
      try {
        let did;
        if (connector === MAGIC_LINK) {
          did = await magicInstance.auth.loginWithMagicLink({
            email: args.email,
            showUI: false,
          });
        } else if ([MAGIC_OAUTH_GOOGLE, MAGIC_OAUTH_APPLE].includes(connector)) {
          const result = await magicInstance.oauth.getRedirectResult();
          did = result?.magic?.idToken;
        }
        if (did) {
          connectorInstance = {
            web3: getWeb3(connector),
            user: await magicInstance.user.getMetadata(),
            did: did,
          };
        }
      } catch (err) {
        if (err instanceof RPCError) {
          console.log(err);
          switch (err.code) {
            case RPCErrorCode.UserAlreadyLoggedIn:
            case RPCErrorCode.MagicLinkFailedVerification:
            case RPCErrorCode.MagicLinkExpired:
            case RPCErrorCode.MagicLinkRateLimited:
              break;
            default:
              break;
          }
        }
      }
      break;
    default:
      console.log("Error choosing provider");
  }
  return connectorInstance;
}

export function deactivateConnector(connector) {
  switch (connector) {
    case MAGIC_LINK:
    case MAGIC_OAUTH_APPLE:
    case MAGIC_OAUTH_GOOGLE:
      magicInstance.user.logout();
      break;
    default:
      console.log("Error choosing provider");
  }
}

export function isConnectorActive(connector) {
  switch (connector) {
    case MAGIC_LINK:
    case MAGIC_OAUTH_APPLE:
    case MAGIC_OAUTH_GOOGLE:
      return magicInstance.user.isLoggedIn();
    default:
      console.log("Error choosing provider");
  }
}

export function getWeb3(connector) {
  switch (connector) {
    case MAGIC_LINK:
    case MAGIC_OAUTH_APPLE:
    case MAGIC_OAUTH_GOOGLE:
      return new Web3(magicInstance.rpcProvider);
    default:
      console.log("Error choosing provider");
  }
}

export function preActivateConnector(connector, provider) {
  switch (connector) {
    case MAGIC_OAUTH_APPLE:
    case MAGIC_OAUTH_GOOGLE:
      magicInstance.oauth.loginWithRedirect({
        provider: provider,
        redirectURI: window.location.href,
        //scope: ['user:email'] /* optional */,
      });
      break;
    default:
      console.log("Error choosing provider");
  }
}

export function getPreActivationRedirectConnector() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(String(prop)),
  });
  if ('google' === params.provider) {
    return MAGIC_OAUTH_GOOGLE;
  } else if ('apple' === params.provider) {
    return MAGIC_OAUTH_APPLE;
  }
  return null;
}
