import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { ActionButton, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { Frame } from "./shared";
import { withRouter } from "../router";

const AllowanceOfferOverlay = ({ isVisible, exit }) => {
  return (
    <Frame
      isVisible={isVisible}
      onBackdropPress={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={"allowance_overlay"}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <View style={styles.titleContainer}>
            {/*<SquadaOneText style={styles.titleText}>COMING SOON</SquadaOneText>*/}
            <SquadaOneText style={styles.offerValue}>
              WELCOME BONUS
            </SquadaOneText>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.contentText}>
              THE FIRST LEAGUE IS ON US!{"\n\n"}
              When you SIGN UP, you'll immediately get your{" "}
              <Text style={styles.highlightedTextPink}>WELCOME BONUS</Text> on
              your wallet.{"\n\n"}
              You'll be able to join or create any league with your welcome
              bonus and start{" "}
              <Text style={styles.highlightedTextGreen}>DEFYING</Text> your
              friends right away.
            </Text>
            <Text style={styles.contentSmallerText}>
              Don't worry! If you already have an account with us, you will get
              a bonus as well!
            </Text>
          </View>
        </View>
        <View style={styles.footerView}>
          <ActionButton
            containerStyle={styles.actionButtonContainer}
            onPress={() => {
              exit();
            }}
            title="CLOSE"
          />
        </View>
      </ScrollView>
    </Frame>
  );
};
export default withRouter(AllowanceOfferOverlay);

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "100%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  imageBackground: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: hp(10),
  },
  titleText: {
    color: Config.Color.WHITE,
    fontSize: hp(8),
    lineHeight: 0,
  },
  offerValue: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(8),
    marginLeft: "5%",
  },
  textContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: hp(4),
    marginLeft: "5%",
    width: "73%",
  },
  highlightedTextPink: {
    color: Config.Color.DFM_PINK,
    fontSize: hp(3),
    fontFamily: "Roboto-Bold",
  },
  highlightedTextGreen: {
    color: Config.Color.DFM_GREEN,
    fontSize: hp(3),
    fontFamily: "Roboto-Bold",
  },
  contentText: {
    color: Config.Color.WHITE,
    fontSize: hp(3),
    fontFamily: "Roboto-Medium",
  },
  contentSmallerText: {
    marginTop: hp(4),
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Italic",
  },
  actionButtonContainer: {
    width: "95%",
    borderRadius: 0,
  },
});
