import React from "react";
import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { hp } from "../../dimensions";

const CloseButton = ({ onPress, containerStyle, style, tintColor }) => (
  <TouchableOpacity style={containerStyle} onPress={onPress}>
    <Image
      style={[styles.image, style, tintColor && { tintColor }]}
      source={require("../../assets/exit.png")}
    />
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  image: {
    height: hp(3.8),
    width: hp(3.8),
    resizeMode: "contain",
  },
});

export default CloseButton;
