import React from "react";
import { default as RNModal } from "modal-react-native-web";

const Modal = (props) => {
  return (
    <RNModal
      {...props}
      appElement={document.getElementById("root") || undefined}
    />
  );
};

export { Modal };
