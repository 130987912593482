import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { Config } from "../../config";

export const magicInstance = new Magic(Config.MAGIC_API_KEY, {
  network: {
    rpcUrl: Config.NETWORK.signerRpcUrl,
    chainId: Config.NETWORK.chainId,
  },
  extensions: [new OAuthExtension()],
});
