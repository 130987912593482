import Web3 from "web3";
import { gql } from "@apollo/client";
import { client } from "../apollo";
import { Config, NULL_ADDRESS } from "../config";
import { adjustDates } from "../formatting";
import { sendCustomMetaTransaction } from "./biconomy";

export const MAX_UINT256 = Web3.utils
  .toBN("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
  .toString();

function getRefereeInst(web3) {
  return new web3.eth.Contract(
    require("../assets/referee-abi.json"),
    Config.Referee.ADDRESS
  );
}

function getRefereeAllowanceVaultInst(web3) {
  return new web3.eth.Contract(
    require("../assets/referee-allowance-vault-abi.json"),
    Config.RefereeAllowanceVault.ADDRESS
  );
}

export function getTokenInst(web3, token) {
  return new web3.eth.Contract(token.abi, token.address);
}

const infuraWeb3 = new Web3(
  new Web3.providers.HttpProvider(Config.NETWORK.providerRpcUrl)
);
export const infuraWeb3Struct = {
  web3: infuraWeb3,
  refereeInst: getRefereeInst(infuraWeb3),
  refereeAllowanceVaultInst: getRefereeAllowanceVaultInst(infuraWeb3),
  tokensInsts: Config.Referee.Tokens.NON_NATIVE.map((t) =>
    getTokenInst(infuraWeb3, t)
  ),
};

export async function getFixtureClashes(fixtureId, refereeInst) {
  let clashes = null;
  await refereeInst.methods
    .getMatchClashes(fixtureId)
    .call()
    .then(async (cs) => {
      let parsedClashes = [];
      const promises = cs.map(async (clash) => {
        return new Promise(async (resolve) => {
          parsedClashes.push(await parseClash(clash));
          resolve();
        });
      });
      await Promise.all(promises);
      clashes = parsedClashes;
    })
    .catch(console.log);
  return clashes;
}

//Converts the 'amount' (in Wei) to Ether with at most 3 decimal places
//(e.g., 0.0946 * 10^8 Wei -> 0.095 Ether)
export function parseToken(amount, token = { decimals: 18 }) {
  if (amount) {
    const n = Number(amount / Math.pow(10, token.decimals));
    return Math.round((n + Number.EPSILON) * 1000) / 1000;
  }
}

//Floors the 'amount' (in Wei) considering 'nMaxFractionDigits'
//(e.g., 0.0946 * 10^8 Wei -> 0.094 * 10^8 Wei, considering 3 decimal places)
export function floorToken(
  amount,
  token = { decimals: 18, maxFractionDigits: 0 }
) {
  if (amount) {
    const pow = 10 ** token.maxFractionDigits;
    const n = Number(amount / Math.pow(10, token.decimals));
    return Web3.utils.toWei(String(Math.floor(n * pow) / pow));
  }
}

export function shortenAddress(address) {
  return address.slice(0, 5) + "..." + address.slice(-4, -1);
}

export async function fetchUserPredictions(wallets, isAccessingOwnLeagues) {
  async function parsePredictions(predictions) {
    let predictionsMap = new Map();
    const promises = predictions.map((prediction) => {
      return new Promise(async (resolve) => {
        const c = prediction[1];
        const clash = await parseClash(c);
        if (isAccessingOwnLeagues || clash.isListed) {
          predictionsMap.set(clash.id, {
            user: prediction[0],
            clash: clash,
            results: prediction[2].map((r, i) => {
              return { homeScore: r, awayScore: prediction[3][i] };
            }),
            status: parseInt(prediction[4]),
            date: parseInt(prediction[5]),
          });
        }
        resolve();
      });
    });
    return Promise.all(promises).then(() => [...predictionsMap.values()]);
  }

  async function getPredictionsByPublicAddress(publicAddress) {
    return getRefereeInst(infuraWeb3)
      .methods.getUserPredictions(publicAddress)
      .call();
  }

  return await Promise.all(wallets.map(getPredictionsByPublicAddress))
    .then((p) => p.flat())
    .then(parsePredictions)
    .catch(console.error);
}

export async function parseClash(clash, withFixtures) {
  /**
   * If a NUL character appears on the string 'a', the function removes it and all the following characters
   * @param a
   * @returns {string|*}
   */
  function trimNull(a) {
    const c = a.indexOf("\0");
    return c > -1 ? a.substr(0, c) : a;
  }

  async function parsePredictions(predictionsArray) {
    let predictionsMap = new Map();
    const promises = predictionsArray.map((prediction) => {
      return new Promise(async (resolve) => {
        const { user } = prediction;
        predictionsMap.set(user, {
          id: prediction.id,
          user: user,
          results: prediction.homeScores.map((r, i) => {
            return { homeScore: r, awayScore: prediction.awayScores[i] };
          }),
          status: parseInt(prediction.status),
          date: parseInt(prediction.timestamp),
          points: parseInt(prediction.points),
        });
        resolve();
      });
    });
    await Promise.all(promises);
    return predictionsMap;
  }

  async function fetchFixtures(fixturesIds) {
    async function fetchFixture(id) {
      return await fetch(Config.COACH_URL + `/match/${id}`)
        .then((response) => response.json())
        .then((fixture) => {
          if (fixture.date) {
            return adjustDates(fixture);
          } else {
            console.log("Fixture with id " + id + " is not on coach");
          }
        })
        .catch(console.error);
    }

    const promises = fixturesIds.map((fId) => {
      return new Promise((resolve) => fetchFixture(fId).then(resolve));
    });
    return await Promise.all(promises);
  }

  const parsedClash = {
    id: clash.id,
    //Parsing bytes32 from Solidity
    title: trimNull(Web3.utils.hexToAscii(clash.title)),
    user: clash.user,
    amount: clash.amount,
    tokenAddress: clash.token,
    fixturesIds: clash.matchesIds,
    hasFinished: clash.hasFinished,
    predictions: await parsePredictions(clash.predictions),
    isListed: !clash.isPrivate,
  };

  if (withFixtures) {
    parsedClash["fixtures"] = await fetchFixtures(parsedClash.fixturesIds);
  }

  const getFixturesStatuses = client.query({
    query: gql`
      query Statuses($id: [ID]) {
        fixtures(id: $id) {
          status
        }
      }
    `,
    variables: {
      id: parsedClash.fixturesIds,
    },
  });
  const res = await getFixturesStatuses;
  const { fixtures } = res.data;
  parsedClash["hasStarted"] = fixtures.some((f) =>
    Config.Status.Started.some((s) => s.CODE.includes(f.status))
  );

  return parsedClash;
}

//TODO: Temporary function, get another solution ASAP
export function predictionToClash(prediction) {
  const clash = prediction.clash;
  clash["pendingPrediction"] = JSON.parse(JSON.stringify(prediction));
  return clash;
}

export function populateCreateClashTx(
  tokenAddress,
  title,
  amount,
  isListed,
  fixturesIds,
  results,
  isAllowance = false
) {
  const { asciiToHex, padRight } = Web3.utils;
  const { refereeInst: noAllowancesRefereeInst, refereeAllowanceVaultInst } =
    infuraWeb3Struct;
  const refereeInst = isAllowance
    ? refereeAllowanceVaultInst
    : noAllowancesRefereeInst;
  if (tokenAddress === NULL_ADDRESS) {
    return refereeInst.methods.createClashNatively(
      padRight(asciiToHex(title), 64),
      //In Referee it is stored as isPrivate, therefore the boolean negation
      !isListed,
      fixturesIds,
      results.map((r) => r.homeScore),
      results.map((r) => r.awayScore)
    );
  } else {
    return refereeInst.methods.createClash(
      tokenAddress,
      padRight(asciiToHex(title), 64),
      amount,
      //In Referee it is stored as isPrivate, therefore the boolean negation
      !isListed,
      fixturesIds,
      results.map((r) => r.homeScore),
      results.map((r) => r.awayScore)
    );
  }
}

export function populateSubmitPredictionTx(
  tokenAddress,
  clashId,
  results,
  isAllowance = false
) {
  const { refereeInst: noAllowancesRefereeInst, refereeAllowanceVaultInst } =
    infuraWeb3Struct;
  const refereeInst = isAllowance
    ? refereeAllowanceVaultInst
    : noAllowancesRefereeInst;

  if (tokenAddress === NULL_ADDRESS) {
    return refereeInst.methods.enterClashNatively(
      clashId,
      results.map((r) => r.homeScore),
      results.map((r) => r.awayScore)
    );
  } else {
    return refereeInst.methods.enterClash(
      clashId,
      results.map((r) => r.homeScore),
      results.map((r) => r.awayScore)
    );
  }
}

export function populateAddScoresTx(predictionId, matchesIndexes, results) {
  const { refereeInst } = infuraWeb3Struct;
  return refereeInst.methods.addScores(
    predictionId,
    matchesIndexes,
    results.map((r) => r.homeScore),
    results.map((r) => r.awayScore)
  );
}

export async function sendMetaTxWithFallback(
  isMetaTx,
  tx,
  token,
  account,
  web3
) {
  let success = false;
  if (isMetaTx) {
    await sendCustomMetaTransaction(
      tx,
      token.caption + "_ExecuteMetaTransaction",
      account,
      new web3.eth.Contract(token.abi, token.address),
      token.address,
      web3
    )
      .then(() => {
        success = true;
      })
      .catch((error) =>
        console.error("Error on attempting approve with meta-tx: " + error)
      );
  }
  if (!success) {
    await sendTransaction(tx, token.address, account, null, web3);
  }
}

export const approveInfinitelyToken = async (
  account,
  tokenAddress,
  minimumAmount,
  web3
) => {
  const token = Config.Referee.Tokens.BY_ADDRESS[tokenAddress];
  const tokenInst = getTokenInst(web3, token);
  const allowance = await tokenInst.methods
    .allowance(account, Config.Referee.ADDRESS)
    .call();
  if (allowance < minimumAmount) {
    const tx = tokenInst.methods.approve(Config.Referee.ADDRESS, MAX_UINT256);
    await sendMetaTxWithFallback(
      //We support the gas costs of every tx at this moment, therefore the 'true' invariably
      true,
      tx,
      token,
      account,
      web3,
      tokenAddress
    );
  }
};

export async function getEstimatedGas(txToEstimate, props) {
  const gasUnits = await txToEstimate.estimateGas(props);
  const gasPrice = await infuraWeb3Struct.web3.eth.getGasPrice();
  return gasUnits * gasPrice;
}

export async function sendTransaction(transactionData, to, from, value, web3) {
  let data, gas;
  if (transactionData) {
    gas = await transactionData.estimateGas({
      from: from,
      value: value,
    });
    gas = Math.ceil(gas * 1.5);
    data = transactionData.encodeABI();
  }
  const gasPrice = Math.ceil(
    (await infuraWeb3Struct.web3.eth.getGasPrice()) * 1.2
  );
  return web3.eth.sendTransaction({
    from: from,
    to: to,
    gas: gas,
    gasPrice: gasPrice,
    data: data,
    value: value,
  });
}
