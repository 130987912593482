import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { hp } from "../dimensions";
import { Frame } from "./shared";
import { ActionButton, UserItem } from "../components";
import EmptyListMessage from "../components/EmptyListMessage";

const usersList = (users, history, userStruct, props, exit) => {
  return users.map((user) => {
    return (
      <View key={user.username}>
        <UserItem
          user={user}
          exit={exit}
          namePress={() => {
            history.push("/user/" + user.username);
          }}
          userStruct={userStruct}
          props={props}
        />
      </View>
    );
  });
};

const emptyList = (username, title) => {
  let code = title === "FOLLOWERS" ? "NO_FOLLOWERS" : "NO_FOLLOWING";
  return <EmptyListMessage code={code} string={username} />;
};

const UsersListOverlay = ({
  exit,
  isVisible,
  title,
  users,
  history,
  userStruct,
  props,
  username,
}) => {
  const component =
    users.length > 0
      ? usersList(users, history, userStruct, props, exit)
      : emptyList(username, title);

  return (
    <Frame
      isVisible={isVisible}
      headerText={title}
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.bodyView}>{component}</View>
        <View style={styles.footerView}>
          <ActionButton onPress={exit} title="CLOSE" />
        </View>
      </ScrollView>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
});

export default UsersListOverlay;
