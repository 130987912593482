import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  FlatList,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Loading, TabList, FixtureCard, SquadaOneText } from "../components";
import { ResultIcon } from "../components/fixture-components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { adjustDates } from "../formatting";
import { sortFixtures } from "../sorting";

const TeamScreen = ({ match, history }) => {
  const [fixtures, setFixtures] = useState([]);
  const [selected, setSelected] = useState(0);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    const id = match.params.id;
    const fetchTeam = () => {
      fetch(Config.COACH_URL + `/team/${id}`)
        .then((res) => res.json())
        .then((t) => {
          setTeam({
            id: id,
            name: t.name,
            imageURL: t.logo_url,
            url: Config.FavoritesCategories.Teams.URL + "/" + id,
          });
        })
        .catch(console.error);
      fetch(Config.COACH_URL + `/team/${id}/matches`)
        .then((res) => res.json())
        .then((fs) =>
          setFixtures(
            fs
              .filter(
                (f) =>
                  !Config.Status.Blacklist.some((s) =>
                    s.CODE.includes(f.status)
                  )
              )
              .map(adjustDates)
          )
        )
        .catch(console.error);
    };
    fetchTeam();
    const intervalId = setInterval(fetchTeam, 20000);
    return () => clearInterval(intervalId);
  }, [match.params.id]);

  if (!team) return <Loading />;
  return (
    <View style={styles.main}>
      <View style={styles.teamContainer}>
        <Image style={styles.teamLogo} source={{ uri: team.imageURL }} />
        <SquadaOneText variant={"h2"} style={styles.teamName}>
          {team.name.toUpperCase()}
        </SquadaOneText>
      </View>
      <TabList
        tabs={Config.TEAM_SCREEN_TABS}
        setSelected={setSelected}
        selected={selected}
      />
      <View
        style={styles.optionsContainer}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        {
          {
            0: (
              <FlatList
                style={styles.matchesList}
                showsVerticalScrollIndicator={false}
                data={fixtures
                  .filter((fixture) =>
                    Config.Status.Unfinished.some((s) =>
                      s.CODE.includes(fixture.status)
                    )
                  )
                  .sort(sortFixtures)}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => history.push("/match/" + item.id)}
                  >
                    <FixtureCard
                      variant={"team_page_next"}
                      fixture={item}
                      onlyHours
                      clashesNumber={"Join"}
                      statusContainerBackground={{
                        backgroundColor: "rgba(55,2,68,0.6)",
                      }}
                      dynamicContainer={
                        <View style={styles.leaguesContainer}>
                          <SquadaOneText
                            adjustsFontSizeToFit
                            numberOfLines={2}
                            variant={"home_leagues_counter"}
                            style={{ textAlign: "center" }}
                          >
                            {"Join\nLEAGUES"}
                          </SquadaOneText>
                        </View>
                      }
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => item.id}
              />
            ),
            1: (
              <FlatList
                style={styles.matchesList}
                showsVerticalScrollIndicator={false}
                data={fixtures
                  .filter(
                    (fixture) =>
                      !Config.Status.Unfinished.some((s) =>
                        s.CODE.includes(fixture.status)
                      )
                  )
                  .sort(sortFixtures)
                  .reverse()}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => history.push("/match/" + item.id)}
                  >
                    <FixtureCard
                      variant={"team_page_past"}
                      fixture={item}
                      team={team.id}
                      statusContainerBackground={{
                        backgroundColor: "rgba(55,2,68,0.6)",
                      }}
                      dynamicContainer={
                        <ResultIcon fixture={item} teamId={team.id} />
                      }
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => item.id}
              />
            ),
          }[selected]
        }
      </View>
    </View>
  );
};
export default TeamScreen;

const styles = StyleSheet.create({
  matchesList: {
    paddingVertical: 5,
  },
  main: {
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
  teamContainer: {
    height: "28%",
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: Config.Color.WHITE,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: hp(3),
  },
  teamLogo: {
    height: "60%",
    width: "100%",
    resizeMode: "contain",
  },
  teamName: {
    paddingTop: 5,
    color: Config.Color.WHITE,
  },
  optionsContainer: {
    height: "65%",
    width: Dimensions.MAX_WIDTH,
  },
  leaguesContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    borderLeftWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    justifyContent: "center",
  },
});
