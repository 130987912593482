import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { gql, useQuery } from "@apollo/client";
import { Loading, RobotoText, SquadaOneText } from "../../components";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import { withRouter } from "../../router";
import { Score, Status } from "../match-components";
import { getMatchStatus } from "../match-components/Details";

const GET_FIXTURE = gql`
  query GetFixture($id: [ID]!) {
    fixtures(id: $id) {
      homeTeam {
        name
        id
        logo
      }
      awayTeam {
        name
        logo
        id
      }
      score {
        awayTeam
        homeTeam
      }
      competition {
        id
        league {
          name
          logoUrl
        }
      }
      updatedAt
      status
      date
    }
  }
`;

const Header = ({
  fixtureId,
  variant,
  history,
  inputComponent,
  disableComponents = false,
}) => {
  const { data, loading } = useQuery(GET_FIXTURE, {
    variables: { id: fixtureId },
  });

  if (loading) return <Loading />;
  const fixture = data?.fixtures[0];

  let fixtureStatusDetails = getMatchStatus(fixture);
  const { competition, homeTeam, awayTeam } = fixture;

  let extraStyles =
    variant === "fixture_page" ? fixturePageStyles : overlayLightStyles;

  let finalComponent =
    inputComponent !== undefined ? (
      inputComponent
    ) : (
      <Score statusDetails={fixtureStatusDetails} variant={variant} />
    );

  return (
    <View style={styles.mainContainer}>
      <View style={styles.innerContainer}>
        <View style={styles.detailsTopContainer}>
          <View style={styles.competitionContainer}>
            <TouchableOpacity
              onPress={() => history.push("/competition/" + competition.id)}
              disabled={!history || disableComponents}
            >
              <RobotoText style={extraStyles.competitionName}>
                {competition.league.name}
              </RobotoText>
            </TouchableOpacity>
          </View>
          <View style={styles.statusContainer}>
            <Status statusDetails={fixtureStatusDetails} variant={variant} />
          </View>
        </View>
        <View style={styles.detailsBottomContainer}>
          <TouchableOpacity
            onPress={() => history.push("/team/" + homeTeam.id)}
            disabled={!history || disableComponents}
            style={styles.teamContainer}
          >
            <View style={styles.teamLogoContainer}>
              <Image
                style={styles.teamLogo}
                source={{
                  uri: homeTeam.logo,
                }}
              />
            </View>
            <View style={styles.teamNameContainer}>
              <SquadaOneText style={extraStyles.teamName}>
                {homeTeam.name}
              </SquadaOneText>
            </View>
          </TouchableOpacity>
          <View style={styles.scoreContainer}>{finalComponent}</View>
          <TouchableOpacity
            onPress={() => history.push("/team/" + awayTeam.id)}
            disabled={!history || disableComponents}
            style={styles.teamContainer}
          >
            <View style={styles.teamLogoContainer}>
              <Image
                style={styles.teamLogo}
                source={{
                  uri: awayTeam.logo,
                }}
              />
            </View>
            <View style={styles.teamNameContainer}>
              <SquadaOneText style={extraStyles.teamName}>
                {awayTeam.name}
              </SquadaOneText>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
export default withRouter(Header);

const fixturePageStyles = StyleSheet.create({
  teamName: {
    color: Config.Color.WHITE,
    fontSize: hp(2.5),
    textAlign: "center",
  },
  competitionName: {
    color: Config.Color.WHITE,
    fontSize: hp(1.5),
  },
});

const overlayLightStyles = StyleSheet.create({
  teamName: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
    textAlign: "center",
  },
  competitionName: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(1.5),
  },
});

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
  },
  innerContainer: {
    width: "95%",
    marginLeft: "2.5%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  detailsTopContainer: {
    height: "20%",
    width: "100%",
    flexDirection: "row",
  },
  competitionContainer: {
    height: "100%",
    width: "50%",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  competitionName: {
    fontSize: hp(1.5),
    color: Config.Color.WHITE,
  },
  statusContainer: {
    alignSelf: "center",
    width: "50%",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  detailsBottomContainer: {
    height: "80%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  logo: {
    height: "50%",
    width: "100%",
    resizeMode: "contain",
  },
  scoreContainer: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  teamContainer: {
    height: "100%",
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  teamLogoContainer: {
    height: "60%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  teamNameContainer: {
    height: "40%",
    width: "100%",
  },
  teamLogo: {
    height: "60%",
    width: "100%",
    resizeMode: "contain",
  },
});
