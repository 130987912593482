import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { SquadaOneText } from "./index";
import { hp } from "../dimensions";
import { Config } from "../config";

const ScoreNowButton = ({ action }) => {
  return (
    <TouchableOpacity style={styles.scoreNowCTA} onPress={action}>
      <SquadaOneText style={styles.scoreNowText}>SCORE NOW</SquadaOneText>
    </TouchableOpacity>
  );
};
export default ScoreNowButton;

const styles = StyleSheet.create({
  scoreNowText: {
    fontSize: hp(2.5),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },
  scoreNowCTA: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Config.Color.DFM_GREEN,
  },
});
