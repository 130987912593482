import React from "react";
import { Text } from "react-native";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";

const SquadaOneText = ({
  variant,
  variableHeight,
  style = {},
  ...otherProps
}) => {
  const defaultStyles = { fontFamily: "SquadaOne-Regular" };

  switch (variant) {
    case "header":
      defaultStyles.fontSize = hp(3.8);
      defaultStyles.color = Config.Color.WHITE;
      break;
    case "h1":
      defaultStyles.fontSize = hp(3.4);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "h2":
      defaultStyles.fontSize = hp(2.8);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "h3":
      defaultStyles.fontSize = hp(2.2);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "h4":
      defaultStyles.fontSize = hp(1.6);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "welcome":
      defaultStyles.fontSize = hp(6);
      defaultStyles.color = Config.Color.WHITE;
      break;
    case "home_leagues_counter":
      defaultStyles.fontSize = hp(2);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "match_competition_name":
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      defaultStyles.fontSize = 0.55 * 0.38 * variableHeight;
      break;
    case "match_leagues_counter":
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      defaultStyles.fontSize = 0.5 * 0.65 * 0.62 * variableHeight;
      break;
    case "league_card_title":
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      defaultStyles.fontSize = 0.6 * Dimensions.CARD_HEIGHT * 0.45;
      break;
    case "leaderboard_position":
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      defaultStyles.fontSize = hp(3);
      break;
    case "leaderboard_points":
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      defaultStyles.fontSize = hp(3);
      break;
    case "profile_counters":
      defaultStyles.color = Config.Color.DFM_PINK;
      defaultStyles.fontSize = hp(4);
      break;
    case "profile_counter_titles":
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(1.8);
      break;
    case "score":
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(3);
      break;
    case "pink":
      defaultStyles.color = Config.Color.DFM_PINK;
      break;
    case "bold":
      break;
    case "medium":
      break;
    case "regular":
    default:
      break;
  }

  return <Text {...otherProps} style={[defaultStyles, style]} />;
};

export default SquadaOneText;
