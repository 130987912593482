import { Dimensions as RNDimensions, Platform } from "react-native";

export function getAppWindowWidth() {
  const { height, width } = RNDimensions.get("window");
  if (Platform.OS !== "web" || height > width) {
    return width;
  }
  return height * (9 / 16);
}

export default class Dimensions {
  static MAX_HEIGHT = hp(100);
  static MAX_WIDTH = getAppWindowWidth();
  static BAR_HEIGHT = 0.06 * this.MAX_HEIGHT;
  static MAX_APP_HEIGHT = this.MAX_HEIGHT - 2 * this.BAR_HEIGHT;
  static TAB_LIST_HEIGHT =
    Platform.OS === "web" ? 0.07 * this.MAX_APP_HEIGHT : "7%";
  static CARD_HEIGHT = 0.12 * this.MAX_APP_HEIGHT;
  static FIXTURE_HEIGHT = 0.8 * this.CARD_HEIGHT;
  static OVERLAY_HEADER_HEIGHT = 0.06 * this.MAX_APP_HEIGHT;
  static OVERLAY_BUTTON_SIZE = 0.018 * this.MAX_HEIGHT;
}

/**
 * hp stands for heightByPercentage
 * @param percentage
 * @returns {number}
 */
export function hp(percentage) {
  return RNDimensions.get("window").height * (percentage / 100);
}

/**
 * wp stands for widthByPercentage
 * @param percentage
 * @returns {number}
 */
export function wp(percentage) {
  return RNDimensions.get("window").width * (percentage / 100);
}
