import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import SquadaOneText from "../SquadaOneText";

const Score = ({ variant, statusDetails }) => {
  let scores = statusDetails.scores;
  let home_score = "";
  let away_score = "";
  let showPenalties =
    scores.home_penalties !== -1 && scores.away_penalties !== -1;

  if (scores.home !== -1 && scores.away !== -1) {
    home_score = scores.home;
    away_score = scores.away;
  }

  let scoreStyle;
  switch (variant) {
    case "match_stats_overlay":
      scoreStyle = styles.mainScoreOverlay;
      break;
    case "fixture_page":
      scoreStyle = styles.fixturePageScore;
      break;
    default:
      scoreStyle = styles.mainScore;
      break;
  }

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <SquadaOneText style={scoreStyle}>{home_score}</SquadaOneText>
        {Boolean(showPenalties) && (
          <SquadaOneText style={styles.penaltiesScore}>
            ({scores.home_penalties})
          </SquadaOneText>
        )}
      </View>
      <SquadaOneText style={[scoreStyle, { paddingHorizontal: 5 }]}>
        :
      </SquadaOneText>
      <View styles={{ flexDirection: "column", alignItems: "center" }}>
        <SquadaOneText style={scoreStyle}>{away_score}</SquadaOneText>
        {Boolean(showPenalties) && (
          <SquadaOneText style={styles.penaltiesScore}>
            ({scores.away_penalties})
          </SquadaOneText>
        )}
      </View>
    </View>
  );
};

export default Score;

const styles = StyleSheet.create({
  mainScore: {
    fontSize: hp(3),
    color: Config.Color.DFM_YELLOW,
    textAlign: "center",
  },
  mainScoreOverlay: {
    fontSize: hp(5),
    color: Config.Color.DFM_YELLOW,
    textAlign: "center",
  },
  fixturePageScore: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(6),
    textAlign: "center",
  },
  penaltiesScore: {
    fontSize: hp(2),
    color: Config.Color.DFM_YELLOW,
    textAlign: "center",
  },
});
