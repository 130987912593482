import AsyncStorage from '@react-native-community/async-storage';

export async function getAuthToken() {
  const userStruct = JSON.parse(await AsyncStorage.getItem("@user") ?? '{}');
  return userStruct['token'] ?? null;
}

export async function setAuthToken(token) {
  let userStruct = JSON.parse(await AsyncStorage.getItem("@user") ?? '{}');
  userStruct['token'] = token;
  AsyncStorage.setItem("@user", JSON.stringify(userStruct));
  return token;
}
