import React from "react";
import { View, SectionList, Platform, TouchableOpacity } from "react-native";
import { Loading, FixtureCard, RobotoText } from "../components";
import BannerHomePage from "../components/BannerHomePage";
import LeaguesCounter from "../components/fixture-components/LeaguesCounter";
import { Config } from "../config";
import Dimensions, { getAppWindowWidth, hp } from "../dimensions";
import { formatFixturesDayDate } from "../formatting";

const Bar = () => (
  <View
    style={{
      height: 3,
      width: 0.1 * getAppWindowWidth(),
      backgroundColor: Config.Color.WHITE,
    }}
  />
);

const Header = ({ index }) => {
  const d = new Date();
  d.setDate(d.getDate() + index - 1);

  return (
    <View
      style={{
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: Config.Color.DFM_DARK_PURPLE,
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: "5%",
      }}
    >
      <Bar />
      <RobotoText
        variant="bold"
        style={{
          color: Config.Color.WHITE,
          paddingHorizontal: "5%",
          paddingVertical: "1%",
          fontSize: hp(3),
        }}
      >
        {formatFixturesDayDate(d).toUpperCase()}
      </RobotoText>
      <Bar />
    </View>
  );
};

const HomeScreen = ({ fixtures, history }) => {
  if (!fixtures) return <Loading />;
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "space-evenly",
        backgroundColor: Config.Color.DFM_DARK_PURPLE,
        borderBottomWidth: 1,
        borderBottomColor: Config.Color.WHITE,
      }}
      onMoveShouldSetResponderCapture={
        Platform.OS === "web" ? () => true : undefined
      }
    >
      <BannerHomePage />
      <SectionList
        sections={fixtures}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => history.push("/match/" + item.id)}>
            <FixtureCard
              variant={"home_page"}
              fixture={item}
              statusContainerBackground={{
                backgroundColor: "rgba(255,255,255,0.4)",
              }}
              dynamicContainer={<LeaguesCounter fixtureId={item.id} />}
            />
          </TouchableOpacity>
        )}
        renderSectionHeader={({ section: { key } }) => <Header index={key} />}
        stickySectionHeadersEnabled={true}
        showsVerticalScrollIndicator={false}
        getItemLayout={(data, index) => {
          return {
            index: index,
            length: Dimensions.FIXTURE_HEIGHT,
            offset: Dimensions.FIXTURE_HEIGHT * index,
          };
        }}
        initialScrollIndex={
          fixtures[0]?.data.length - (Platform.OS === "web" ? 0 : 1)
        }
      />
    </View>
  );
};

export default HomeScreen;
