import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { SquadaOneText, Loading } from "../index";
import { hp } from "../../dimensions";
import { gql, useQuery } from "@apollo/client";
import { digestResult } from "../../formatting";
import PlayerScore from "./PlayerScore";

const GET_FIXTURE = gql`
  query GetFixture($id: [ID]!) {
    fixtures(id: $id) {
      score {
        awayTeam
        homeTeam
      }
      updatedAt
      status
      date
    }
  }
`;

function getPoints(finalScore, userScore) {
  if (
    finalScore.homeScore === userScore.homeScore &&
    finalScore.awayScore === userScore.awayScore
  ) {
    return 3;
  } else if (digestResult(finalScore) === digestResult(userScore)) {
    return 1;
  }
  return 0;
}

const ScoreDisplay = ({
  fixtureId,
  prediction,
  isAuthUser,
  hasJoined,
  showTitle,
  scoreNowComponent,
}) => {
  const { Finished, Upcoming } = Config.Status;
  const { data, loading } = useQuery(GET_FIXTURE, {
    variables: { id: fixtureId },
  });

  if (loading) return <Loading />;
  const fixture = data?.fixtures[0];

  const scoreNow = () => {
    if (scoreNowComponent !== undefined) return scoreNowComponent;

    return (
      <View>
        <SquadaOneText style={styles.viewScores}>SCORE NOT ADDED</SquadaOneText>
      </View>
    );
  };

  const viewScoresComponent = () => {
    return (
      <View>
        <SquadaOneText style={styles.viewScores}>View Scores</SquadaOneText>
      </View>
    );
  };

  const hiddenScoreComponent = () => {
    return (
      <View>
        <Image
          style={styles.icon}
          source={require("../../assets/hidden.png")}
        />
      </View>
    );
  };

  const scoreComponent = () => {
    let points;
    if (hasScore && hasEnded) {
      const finalScore = {
        homeScore: Number(fixture.score.homeTeam),
        awayScore: Number(fixture.score.awayTeam),
      };
      const userScore = {
        homeScore: Number(prediction.homeScore),
        awayScore: Number(prediction.awayScore),
      };
      points = getPoints(finalScore, userScore);
    }

    return (
      <PlayerScore
        prediction={prediction}
        showTitle={showTitle}
        points={points}
      />
    );
  };

  const notScoredComponent = () => {
    return (
      <View style={{ alignItems: "center" }}>
        <SquadaOneText style={styles.notScoredText}>NOT SCORED</SquadaOneText>
        <SquadaOneText style={styles.points}>+0pts</SquadaOneText>
      </View>
    );
  };

  // JOINED & SCORED: Display score
  // JOINED & NOT SCORED & NOT STARTED: Display score now button
  // JOINED & NOT SCORED & STARTED: Not scored with 0pts
  // NOT JOINED: View Scores
  let hasEnded = Finished.some((s) => s.CODE.includes(fixture.status));
  let notStarted = Upcoming.some((s) => s.CODE.includes(fixture.status));
  let hasScore =
    Boolean(prediction) &&
    Number(prediction.homeScore) !== Config.PREDICTION_NOT_ADDED &&
    Number(prediction.awayScore) !== Config.PREDICTION_NOT_ADDED;
  let canScore = isAuthUser && hasJoined && !hasScore && notStarted;

  let canSee =
    (prediction && isAuthUser && hasJoined) ||
    (prediction && !isAuthUser && !notStarted && hasJoined);

  let component;
  if (hasScore) {
    component = canSee ? scoreComponent() : hiddenScoreComponent();
  } else if (hasJoined) {
    component = canScore ? scoreNow() : notScoredComponent();
  } else if (isAuthUser) {
    component = viewScoresComponent();
  } else {
    component = hiddenScoreComponent();
  }

  return <View style={styles.picksContainer}>{component}</View>;
};
export default ScoreDisplay;

const styles = StyleSheet.create({
  picksContainer: {
    backgroundColor: Config.Color.DFM_YELLOW,
    borderLeftWidth: 2,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  notScoredText: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
    overflow: "visible",
  },
  score: {
    fontSize: hp(3),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  points: {
    fontSize: hp(1.5),
    color: Config.Color.DFM_LIGHT_PURPLE,
  },
  icon: {
    height: hp(3.5),
    width: hp(3.5),
    resizeMode: "contain",
  },
  viewScores: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },
});
