import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import { getScore } from "../../formatting";
import { SquadaOneText } from "../index";

function getResult(fixture, teamId) {
  let result = "L";
  const { score, penaltyScore } = getScore(fixture);
  const playsHome = parseInt(teamId) === parseInt(fixture.home_team.id);
  if (
    (playsHome && score.home > score.away) ||
    (!playsHome && score.home < score.away) ||
    (Config.Status.FullPenalties.CODE.includes(fixture.status) &&
      ((playsHome && penaltyScore.home > penaltyScore.away) ||
        (!playsHome && penaltyScore.home < penaltyScore.away)))
  ) {
    result = "W";
  } else if (
    !Config.Status.FullPenalties.CODE.includes(fixture.status) &&
    score.home === score.away
  ) {
    result = "D";
  }
  return result;
}

const ResultIcon = ({ fixture, teamId }) => {
  let result = getResult(fixture, teamId);
  let mainText;
  let color;
  let icon;

  switch (result) {
    case "W":
      mainText = "WON";
      icon = require("../../assets/thumbs-up-dark-purple.png");
      color = Config.Color.DFM_GREEN;
      break;
    case "D":
      mainText = "DRAW";
      icon = require("../../assets/hand-shake-dark-purple.png");
      color = Config.Color.DFM_YELLOW;

      break;
    case "L":
      mainText = "LOST";
      icon = require("../../assets/thumbs-down-dark-purple.png");
      color = Config.Color.DFM_PINK;
      break;
    default:
      mainText = "TBD";
      color = Config.Color.GREY_67;
      break;
  }

  return (
    <View style={[styles.resultContainer, { backgroundColor: color }]}>
      {!Boolean(icon) && <Image source={icon} style={styles.icon} />}
      <SquadaOneText style={styles.text} adjustsFontSizeToFit numberOfLines={1}>
        {mainText}
      </SquadaOneText>
    </View>
  );
};
export default ResultIcon;

const styles = StyleSheet.create({
  resultContainer: {
    borderLeftWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  text: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
  },
  icon: {
    height: hp(2),
    width: hp(2),
    resizeMode: "contain",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
  },
});
