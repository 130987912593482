import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import Dimensions, { hp } from "../../dimensions";
import { RobotoText } from "../index";

const Status = ({ statusDetails }) => {
  const defaultStyles = {};

  let statusText;
  let statusTextStyle;
  let statusContainerStyle;

  if (statusDetails.status === "LIVE") {
    statusText = "Live " + statusDetails.liveDetail;
    defaultStyles.height = 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT;
    defaultStyles.width = 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT;
    defaultStyles.borderRadius = 10000;
    defaultStyles.backgroundColor = Config.Color.DFM_DARK_PURPLE;
    defaultStyles.marginRight = 5;
    statusContainerStyle = styles.liveStatusContainer;
    statusTextStyle = styles.liveStatusText;
  } else if (statusDetails.status === "UPCOMING") {
    statusText = statusDetails.date + " | " + statusDetails.time;
    statusContainerStyle = styles.scheduledStatusContainer;
    statusTextStyle = styles.scheduledStatusText;
  } else if (statusDetails.status === "FINISHED") {
    statusText = "Ended | " + statusDetails.date;
    statusContainerStyle = styles.finishedStatusContainer;
    statusTextStyle = styles.finishedStatusText;
  } else if (statusDetails.status === "TBA" || statusDetails.status === "TBD") {
    statusText = statusDetails.status;
    statusContainerStyle = styles.scheduledStatusContainer;
    statusTextStyle = styles.scheduledStatusText;
  }

  return (
    <View style={[styles.statusContainer, statusContainerStyle]}>
      <View style={[defaultStyles]} />
      <RobotoText style={[styles.statusText, statusTextStyle]}>
        {statusText}
      </RobotoText>
    </View>
  );
};
export default Status;

const styles = StyleSheet.create({
  statusContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    height: "100%",
    paddingVertical: hp(0.5),
    paddingHorizontal: hp(1),
  },
  statusText: {
    fontSize: hp(1.5),
    color: Config.Color.WHITE,
    overflow: "visible",
  },
  liveStatusContainer: { backgroundColor: Config.Color.DFM_GREEN },
  liveStatusText: { color: Config.Color.DFM_DARK_PURPLE },
  scheduledStatusContainer: { backgroundColor: Config.Color.DFM_DARK_PURPLE },
  scheduledStatusText: { color: Config.Color.WHITE },
  finishedStatusContainer: { backgroundColor: Config.Color.GREY_67 },
  finishedStatusText: { color: Config.Color.DFM_DARK_PURPLE },
});
