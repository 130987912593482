import React from "react";
import { StyleSheet, View } from "react-native";
import { RobotoText } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";

const messages = {
  NO_LEAGUES_UPCOMING_MATCH:
    "You're still on time to DEFY your friends on this match. Create a league now.",
  NO_LEAGUES_MATCH_STARTED:
    "Check the upcoming matches on the home page and do not lose another chance to DEFY your friends.",
  MATCH_NOT_STARTED: "Match has not started yet.",
  NO_LEAGUES: "Create a league and DEFY your friends.",
  NO_LEAGUES_ONGOING: "Create a league and DEFY your friends.",
  NO_LEAGUES_HISTORY: "You haven't participated in any finished league yet.",
  NO_FOLLOWING: "REPLACE_STRING is not following anyone yet.",
  NO_FOLLOWERS: "No one is following REPLACE_STRING yet.",
  NO_OTHER_LEAGUES: "There are no other leagues coming up.",
  NOT_ADDING_PREDICTIONS: "No scores were added.",
};

const EmptyListMessage = ({ code, string, withContainer }) => {
  let message = messages[code];
  let containerStyle = styles.container;
  let textStyle = styles.message;
  if (string) message = message.replace("REPLACE_STRING", string);
  if (withContainer) {
    containerStyle = styles.whiteContainer;
    textStyle = styles.whiteContainerText;
  }

  return (
    <View style={containerStyle}>
      <RobotoText style={textStyle}>{message}</RobotoText>
    </View>
  );
};

export default EmptyListMessage;

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  whiteContainer: {
    backgroundColor: Config.Color.LIGHT_GREY,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  whiteContainerText: {
    fontFamily: "Roboto-MediumItalic",
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  purple: {
    color: Config.Color.DFM_LIGHT_PURPLE,
  },
  message: {
    fontFamily: "Roboto-MediumItalic",
    fontSize: hp(2),
    color: Config.Color.GREY_67,
  },
});
