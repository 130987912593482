import React, { useEffect, useState } from "react";
import { Config } from "../config";
import { infuraWeb3Struct, parseToken } from "../web3/utils";
import { Text } from "react-native";

const Allowance = ({ walletAddress, styles }) => {
  const Tokens = Config.Referee.Tokens;
  const [allowance, setAllowance] = useState(0);

  useEffect(() => {
    if (Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN] !== undefined) {
      let tokenAddress = Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN].address;
      if (walletAddress) {
        infuraWeb3Struct.refereeAllowanceVaultInst.methods
          .allowanceOf(tokenAddress, walletAddress)
          .call()
          .then((allowance) => setAllowance(allowance));
      }
    }
  });

  return (
    <Text style={styles}>
      {parseToken(allowance, Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN])}
    </Text>
  );
};

export default Allowance;
