import React from "react";
import MessageOverlay from "./MessageOverlay";
import { Config } from "../config";

export const ErrorMessages = {
  DEFAULT: "Something went wrong. Try again later",
  NO_NATIVE_TOKEN:
    "You don't have enough " +
    Config.Referee.Tokens.NATIVE.caption +
    " to complete this action. Top up and try again",
  INSUFFICIENT_FUNDS: "There are not enough funds to complete this action",
  TRANSACTION_REVERTED:
    "Your transaction was reverted. Check out your balance and try again",
};

const ErrorOverlay = ({
  buttonText = "TRY AGAIN",
  exit,
  isVisible,
  text = ErrorMessages.DEFAULT,
}) => (
  <MessageOverlay
    buttonText={buttonText}
    exit={exit}
    imageSource={require("../assets/error-light-purple.png")}
    isVisible={isVisible}
    text={text}
    title="OOPS!"
  />
);

export default ErrorOverlay;
