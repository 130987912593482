import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  ActionButton,
  Loading,
  OtherPlayerScoreItem,
  RobotoText,
  SquadaOneText,
  Stats,
} from "../components";
import { Frame } from "./shared";
import { Config } from "../config";
import { hp } from "../dimensions";
import Header from "../components/fixture-components/Header";

/*
 * LEAGUE MATCH OVERLAY
 * Accessible from the match component on a given League Page
 */

const LeagueMatchOverlay = ({
  exit,
  isVisible,
  fixture,
  matchPredictions,
  playersUsernames,
  loggedInUsername,
  hasJoined,
  history,
  scoreNowComponent,
}) => {
  let homeTeamWinsCounter = 0;
  let drawCounter = 0;
  let awayTeamWinsCounter = 0;
  let totalPredictions = 0;
  let fixtureId = null;
  let homeWinsStats = 0;
  let awayWinsStats = 0;
  let drawWinsStats = 0;

  if (fixture !== undefined) {
    fixtureId = fixture.id;
    matchPredictions.forEach((item) => {
      if (
        item !== undefined &&
        Number(item.homeScore) !== Config.PREDICTION_NOT_ADDED &&
        Number(item.awayScore) !== Config.PREDICTION_NOT_ADDED
      ) {
        totalPredictions++;
        let diff = item.homeScore - item.awayScore;
        if (diff === 0) drawCounter++;
        else if (diff > 0) homeTeamWinsCounter++;
        else awayTeamWinsCounter++;
      }
    });
  }

  if (totalPredictions !== 0) {
    homeWinsStats = Math.round((homeTeamWinsCounter / totalPredictions) * 100);
    drawWinsStats = Math.round((drawCounter / totalPredictions) * 100);
    awayWinsStats = Math.round((awayTeamWinsCounter / totalPredictions) * 100);
  }

  const leagueMatchStats = [
    {
      title: "Home Team Wins",
      value: homeWinsStats + "%",
    },
    {
      title: "Draw",
      value: drawWinsStats + "%",
    },
    {
      title: "Away Team Wins",
      value: awayWinsStats + "%",
    },
  ];

  return (
    <Frame
      isVisible={isVisible}
      headerText="MATCH SCORES"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {fixtureId === null && <Loading />}
        {fixtureId !== null && (
          <View>
            <View style={styles.bodyView}>
              <TouchableOpacity
                style={styles.matchContainer}
                onPress={() => history.push("/match/" + fixtureId)}
              >
                <Header fixtureId={fixtureId} variant={"match_stats_overlay"} />
              </TouchableOpacity>
              <View style={styles.statsMainContainer}>
                <Stats stats={leagueMatchStats} />
              </View>
              <View style={styles.scoresContainer}>
                <SquadaOneText variant={"h2"}>PLAYERS SCORES</SquadaOneText>
                <RobotoText style={styles.message}>
                  Check the other players scores once the match has started.
                  These scores are only visible to players who have also joined
                  the league.
                </RobotoText>
                <View styles={styles.scoresList}>
                  {matchPredictions !== undefined &&
                    matchPredictions.map((item, index) => {
                      return (
                        <OtherPlayerScoreItem
                          fixtureId={fixtureId}
                          playerPrediction={item}
                          hasJoined={hasJoined}
                          loggedInUsername={loggedInUsername}
                          key={playersUsernames[index]}
                          player={playersUsernames[index]}
                          scoreNowComponent={scoreNowComponent}
                        />
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={styles.footerView}>
              <ActionButton onPress={exit} title="CLOSE" />
            </View>
          </View>
        )}
      </ScrollView>
    </Frame>
  );
};
export default LeagueMatchOverlay;

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  matchContainer: {
    backgroundColor: Config.Color.WHITE,
    width: "100%",
    height: hp(18),
  },
  statsMainContainer: {
    width: "100%",
    height: hp(12),
    marginVertical: 20,
  },
  message: {
    marginVertical: 5,
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  scoresContainer: {
    width: "100%",
  },
  scoresList: {
    width: "100%",
  },
});
