import React from "react";
import { StyleSheet, View, TouchableOpacity, Image } from "react-native";
import FootballIcon from "../assets/football.png";
import PlayersIcon from "../assets/players.png";
import UserIcon from "../assets/user.png";
import { gql, useQuery } from "@apollo/client";
import { RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { withRouter } from "../router";
import { parseToken, shortenAddress } from "../web3/utils";
import IconText from "./IconText";
import LeagueStatus from "./LeagueStatus";
import ScoreDisplay from "./fixture-components/ScoreDisplay";

const GET_USERNAME = gql`
  query Users($publicAddress: [String]) {
    wallets(publicAddress: $publicAddress) {
      user {
        username
      }
    }
  }
`;

const GetUsernameByPublicAddress = (userAddress) => {
  // TODO: Implement proper error handling
  const { data } = useQuery(GET_USERNAME, {
    variables: {
      publicAddress: userAddress,
    },
  });
  return data?.wallets.length === 1
    ? data?.wallets[0].user.username
    : shortenAddress(userAddress);
};

const LeagueCard = ({
  userStruct,
  account,
  clash,
  history,
  prediction,
  showYourScore,
  showTitle,
  selectedFixture,
  showPrize,
}) => {
  const { fixturesIds, predictions, user, isListed } = clash;
  const username = GetUsernameByPublicAddress(user);

  let myPrediction = prediction ?? null;

  if (myPrediction === null && userStruct && account) {
    const wallets = [account].concat(
      userStruct.wallets !== undefined &&
        userStruct.wallets.filter((w) => w !== account)
    );
    const wallet = wallets.find((w) => clash.predictions.get(w));
    myPrediction = predictions.get(wallet);
  }

  myPrediction !== undefined &&
    myPrediction !== null &&
    myPrediction["results"].forEach((p, i) => {
      if (
        p !== undefined &&
        Number(p["homeScore"]) === Config.PREDICTION_NOT_ADDED &&
        Number(p["awayScore"]) === Config.PREDICTION_NOT_ADDED
      ) {
        myPrediction["results"][i] = undefined;
      }
    });

  const nPredictions = predictions ? predictions.size : 1;
  const token = Config.Referee.Tokens.BY_ADDRESS[clash.tokenAddress];
  const hasJoined = !!myPrediction;

  let matchesCounterText = fixturesIds.length + " match";
  if (fixturesIds.length > 1) matchesCounterText = matchesCounterText + "es";

  let playersCounterText = nPredictions + " Player";
  if (nPredictions > 1) playersCounterText = playersCounterText + "s";

  let prizeText =
    nPredictions * parseToken(clash.amount, token) + "\n" + token.caption;
  let ticketPriceText = parseToken(clash.amount, token) + " " + token.caption;

  let selectedFixtureResults;
  let scoreComponent;
  let hasScoreToShow = false;
  if (selectedFixture !== undefined && hasJoined) {
    hasScoreToShow = true;
    selectedFixtureResults =
      selectedFixture &&
      myPrediction &&
      myPrediction.date &&
      myPrediction.results[
        fixturesIds.findIndex((id) => id === selectedFixture.id)
      ];

    scoreComponent = (
      <ScoreDisplay
        fixtureId={selectedFixture.id}
        prediction={selectedFixtureResults}
        isAuthUser={true}
        hasJoined={hasJoined}
        showTitle={true}
      />
    );
  }

  let isLeaguePage = history.location.pathname.split("/")[1] === "league";

  const handleSelectClash = (id) => {
    history.push("/league/" + id);
  };

  return (
    <TouchableOpacity
      onPress={() => handleSelectClash(clash.id)}
      disabled={!clash.id || isLeaguePage}
      style={{
        height: Dimensions.CARD_HEIGHT * 1.1,
        width: "100%",
      }}
    >
      <View style={styles.leagueCardContainer}>
        <View style={styles.prizeContainer}>
          <SquadaOneText
            style={styles.prizeText}
            adjustsFontSizeToFit
            numberOfLines={2}
          >
            {prizeText}
          </SquadaOneText>
          <Image
            source={require("../assets/moneybag-yellow.png")}
            style={styles.prizeImage}
          />
        </View>
        <View style={styles.contentContainer}>
          {Boolean(showTitle) && (
            <View style={styles.titleContainer}>
              {!Boolean(isListed) && (
                <Image
                  style={styles.listedIcon}
                  source={require("../assets/lock-light-purple.png")}
                />
              )}
              <SquadaOneText
                adjustsFontSizeToFit
                numberOfLines={1}
                variant={"league_card_title"}
                style={styles.titleText}
              >
                {clash.title.toUpperCase()}
              </SquadaOneText>
            </View>
          )}
          <View style={styles.detailsMainContainer}>
            <View style={styles.detailsContainer}>
              <IconText
                iconSource={UserIcon}
                text={"Created by: " + username}
              />
              <IconText iconSource={PlayersIcon} text={playersCounterText} />
              <IconText iconSource={FootballIcon} text={matchesCounterText} />
            </View>
            <View style={styles.ticketContainer}>
              <Image
                source={require("../assets/ticket-light-purple.png")}
                style={styles.ticketImage}
              />
              <RobotoText
                style={styles.ticketPrice}
                variant={"bold"}
                adjustsFontSizeToFit
              >
                {ticketPriceText}
              </RobotoText>
            </View>
          </View>
        </View>
        <View style={styles.statusContainer}>
          {showYourScore && hasScoreToShow && (
            <View style={styles.playerScoreContainer}>{scoreComponent}</View>
          )}
          {(!showYourScore || (showYourScore && !hasScoreToShow)) && (
            <View style={styles.leagueStatusContainer}>
              <LeagueStatus
                hasLeagueFinished={clash.hasFinished}
                hasLeagueStarted={clash.hasStarted}
                myPrediction={myPrediction}
                showPrize={showPrize}
                token={token}
              />
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default withRouter(LeagueCard);

const styles = StyleSheet.create({
  leagueCardContainer: {
    flexDirection: "row",
    backgroundColor: Config.Color.WHITE,
    height: "100%",
  },
  prizeContainer: {
    flexDirection: "row",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    height: "100%",
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
  },
  prizeImage: {
    height: 0.4 * Dimensions.CARD_HEIGHT,
    width: 0.4 * Dimensions.CARD_HEIGHT,
    resizeMode: "contain",
    marginHorizontal: 0.02 * Dimensions.CARD_HEIGHT,
  },
  prizeText: {
    color: Config.Color.DFM_YELLOW,
    fontSize: 0.25 * Dimensions.CARD_HEIGHT,
    textAlign: "center",
    lineHeight: 0.225 * Dimensions.CARD_HEIGHT,
    marginHorizontal: 0.02 * Dimensions.CARD_HEIGHT,
  },
  contentContainer: {
    flexDirection: "column",
    backgroundColor: Config.Color.WHITE,
    borderTopColor: Config.Color.DFM_DARK_PURPLE,
    borderBottomColor: Config.Color.DFM_DARK_PURPLE,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    height: "100%",
    width: "60%",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "2%",
  },
  titleContainer: {
    flexDirection: "row",
    width: "100%",
    height: "30%",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
  },
  listedIconContainer: {
    flexDirection: "row",
    backgroundColor: Config.Color.WHITE,
    borderRadius: 2,
    marginLeft: 5,
    alignItems: "center",
  },
  listedIcon: {
    height: hp(2.5),
    width: hp(2.5),
    resizeMode: "contain",
    marginRight: 5,
  },
  listedText: {
    fontSize: hp(1.5),
    color: Config.Color.DFM_PINK,
  },
  detailsMainContainer: {
    flexDirection: "row",
    width: "100%",
    height: "70%",
  },
  detailsContainer: {
    flexDirection: "column",
    height: "100%",
    width: "60%",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  ticketContainer: {
    flexDirection: "column",
    height: "100%",
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  ticketImage: {
    height: 0.25 * Dimensions.CARD_HEIGHT,
    width: 0.25 * Dimensions.CARD_HEIGHT,
    resizeMode: "contain",
  },
  ticketPrice: {
    color: Config.Color.DFM_LIGHT_PURPLE,
    fontSize: 0.14 * Dimensions.CARD_HEIGHT,
  },
  statusContainer: {
    flexDirection: "column",
    backgroundColor: Config.Color.WHITE,
    height: "100%",
    width: "15%",
    justifyContent: "center",
    alignItems: "center",
    flex: 2,
  },
  leagueStatusContainer: {
    width: "100%",
    flex: 1,
  },
  playerScoreContainer: {
    backgroundColor: Config.Color.WHITE,
    width: "100%",
    height: "100%",
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    borderLeftWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  joinStatusText: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: 0.15 * Dimensions.CARD_HEIGHT,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  test: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: 0.1 * Dimensions.CARD_HEIGHT,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
});
