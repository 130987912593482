import React from "react";
import MessageOverlay from "./MessageOverlay";

const CongratulationsOverlay = ({
  isVisible,
  exit,
  text,
  trackingScreenName,
}) => {
  return (
    <MessageOverlay
      isVisible={isVisible}
      exit={exit}
      title="CONGRATULATIONS!"
      text={text}
      imageSource={require("../assets/thumbs-up-light-purple.png")}
      buttonText="CLOSE"
      trackingScreenName={trackingScreenName}
    />
  );
};

export default CongratulationsOverlay;
