import React from "react";
import SubstitutionImage from "../../../assets/substitution.png";
import TimelineItem from "./TimelineItem";

export default function Substitution({ event, isHome }) {
  const { playerIn, playerOut } = event;

  return (
    <TimelineItem
      mainText={playerIn.name}
      secondaryText={playerOut.name}
      iconSource={SubstitutionImage}
      hasSecondaryText
      isHome={isHome}
      minutes={event.minute}
      minutesExtra={event.minuteExtra}
    />
  );
}
