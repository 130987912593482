import { 
  ENVIRONMENT,
  FIREBASE_API_KEY,
  // FIREBASE_AUTH_DOMAIN,
  // FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  // FIREBASE_STORAGE_BUCKET,
  // FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
 } from '@env';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent as logEventFirebase,
  setUserId as setUserIdFirebase,
} from "firebase/analytics";
import { EVENTS } from "./events";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  projectId: FIREBASE_PROJECT_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
  // authDomain: FIREBASE_AUTH_DOMAIN,
  // databaseURL: FIREBASE_DATABASE_URL,
  // storageBucket: FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
};

// Initialize Firebase
const app = initializeApp(ENVIRONMENT === 'PRODUCTION' ? firebaseConfig : { apiKey: 'noKey', projectId: 'noId', appId: 'noId', measurementId: 'noId' });
const analytics = getAnalytics(app);

const dynamicLinks = null;
const messaging = null;

const logEvent = (eventName, eventParams) =>
  new Promise((resolve, reject) => {
    try {
      logEventFirebase(analytics, eventName, eventParams);
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const logScreenView = (screenName) =>
  new Promise((resolve, reject) => {
    try {
      logEventFirebase(analytics, EVENTS.SCREEN_VIEW, {
        firebase_screen: screenName,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const logLogin = (method) =>
  new Promise((resolve, reject) => {
    try {
      logEventFirebase(analytics, EVENTS.LOGIN, { method });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const logJoinGroup = (group_id) =>
  new Promise((resolve, reject) => {
    try {
      logEventFirebase(analytics, EVENTS.JOIN_GROUP, { group_id });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const setUserId = (id) =>
  new Promise((resolve, reject) => {
    try {
      setUserIdFirebase(analytics, id);
      resolve();
    } catch (error) {
      reject(error);
    }
  });

export {
  dynamicLinks,
  messaging,
  logEvent,
  logScreenView,
  logLogin,
  logJoinGroup,
  setUserId,
};
