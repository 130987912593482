import React, { useRef } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ActionButton, RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { logEvent } from "../firebase";
import { EVENTS } from "../firebase/events";
import Transak from "../web3/transak";
import { Icon } from "react-native-elements";
import Balance from "./Balance";
import Clipboard from "@react-native-clipboard/clipboard";
import Toast from "react-native-toast-notifications";
import Allowance from "./Allowance";

const WalletInfo = ({ userStruct, wallet }) => {
  const showAllowance = Config.FEATURE_FLAG_ALLOWANCE;
  const nativeTokenCaption = Config.Referee.Tokens.NATIVE.caption;
  const toastRef = useRef();
  const transak = useRef();

  const sortedAllTokensList = React.useMemo(
    () =>
      Config.Referee.Tokens.ALL.sort((a, b) => {
        if (a.caption === nativeTokenCaption) return -1;
        else if (b.caption === nativeTokenCaption) return 1;
        return 0;
      }),
    [nativeTokenCaption]
  );

  const providersIcon = {
    MAGIC_LINK: require("../assets/email-light-purple.png"),
    MAGIC_OAUTH_GOOGLE: require("../assets/google-light-purple.png"),
    MAGIC_OAUTH_APPLE: require("../assets/apple-light-purple.png"),
  };

  const currentAccount = userStruct.account;
  const isActive = wallet.publicAddress === currentAccount;

  const initTransak = () => {
    logEvent(EVENTS.TOP_UP_WALLET_START);
    transak.current.init();
  };

  return (
    <View style={styles.container}>
      <View style={styles.providerAddressContainer}>
        <Image
          style={styles.providerIcon}
          source={providersIcon[wallet.provider]}
        />
        <View style={styles.walletContainer}>
          <RobotoText style={styles.label}>
            Wallet Address{" "}
            {isActive && (
              <Icon
                name={"circle"}
                type="font-awesome"
                size={hp(1.6)}
                color={Config.Color.DFM_GREEN}
              />
            )}
          </RobotoText>
          <TouchableOpacity
            onPress={() => {
              Clipboard.setString(wallet.publicAddress);
              toastRef.current.show("Copied to clipboard");
            }}
          >
            <RobotoText style={styles.text}>{wallet.publicAddress}</RobotoText>
          </TouchableOpacity>
        </View>
      </View>
      {showAllowance && (
        <View style={styles.balancesContainer}>
          <View style={styles.allowancesTitleContainer}>
            <RobotoText style={styles.balancesTitle}>Allowance</RobotoText>
            <View style={styles.newLabel}>
              <Image
                style={styles.newLabelIcon}
                source={require("../assets/offer-dark-purple.png")}
              />
              <SquadaOneText
                adjustsFontSizeToFit
                numberOfLines={1}
                style={styles.newLabelText}
              >
                WELCOME BONUS
              </SquadaOneText>
            </View>
          </View>
          <View style={styles.balanceContainer}>
            <Image
              style={styles.tokenIcon}
              source={require("../assets/tokens/usdc-dark-purple.png")}
            />
            <RobotoText style={styles.tokenName}>
              {Config.ALLOWANCE_TOKEN}
            </RobotoText>
            <Allowance
              walletAddress={wallet.publicAddress}
              styles={styles.allowanceValue}
            />
          </View>
        </View>
      )}
      <View style={styles.balancesContainer}>
        <View style={styles.titleContainer}>
          <RobotoText style={styles.balancesTitle}>Balances</RobotoText>
          {isActive && (
            <View style={styles.buttonContainer}>
              <ActionButton
                variant={"primary"}
                onPress={initTransak}
                title="TOP UP"
                titleStyle={{ color: Config.Color.DFM_DARK_PURPLE }}
                containerStyle={styles.topUpButton}
              />
              <Transak account={userStruct.account} ref={transak} />
            </View>
          )}
        </View>
        {sortedAllTokensList.map((token, index) => (
          <View style={styles.balanceContainer} key={index}>
            <Image style={styles.tokenIcon} source={token.logo} />
            <RobotoText style={styles.tokenName}> {token.caption}</RobotoText>
            <Balance
              fromActiveWallet={isActive}
              index={index}
              userStruct={userStruct}
              walletAddress={wallet.publicAddress}
            />
          </View>
        ))}
      </View>
      <Toast ref={toastRef} />
    </View>
  );
};

export default WalletInfo;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: "4%",
    borderWidth: 1,
    borderColor: Config.Color.DFM_DARK_PURPLE,
  },
  providerAddressContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: Config.Color.DFM_DARK_PURPLE,
  },
  walletTitle: {
    fontSize: hp(2.2),
    marginBottom: "2%",
  },
  providerIcon: {
    height: hp(3),
    width: hp(3),
    resizeMode: "contain",
    marginHorizontal: "2%",
  },
  walletContainer: {
    justifyContent: "center",
    flexDirection: "column",
    padding: "2%",
  },
  label: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
    fontFamily: "Roboto-Bold",
  },
  text: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(1.6),
    fontFamily: "Roboto-Regular",
  },
  balancesContainer: {
    marginLeft: "12%",
    marginRight: "2%",
    marginVertical: "2%",
  },
  allowancesTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  newLabel: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Config.Color.DFM_YELLOW,
    paddingHorizontal: "2%",
    paddingVertical: "1%",
    borderRadius: 5,
    marginLeft: "2%",
    alignSelf: "flex-start",
  },
  newLabelIcon: {
    height: hp(1.5),
    width: hp(1.5),
    resizeMode: "contain",
    marginRight: "2%",
  },
  newLabelText: {
    fontSize: hp(1.8),
    overflow: "visible",
  },
  allowanceValue: {
    color: Config.Color.DFM_PINK,
    fontSize: hp(2.5),
    fontFamily: "SquadaOne-Regular",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    width: hp(10),
  },
  topUpButton: {
    width: "100%",
    height: hp(4),
  },
  balancesTitle: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
    fontFamily: "Roboto-Bold",
  },
  balanceContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: "2%",
  },
  tokenIcon: {
    height: hp(3),
    width: hp(3),
    resizeMode: "contain",
    marginHorizontal: "2%",
  },
  tokenName: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
    fontFamily: "Roboto-Medium",
    marginRight: "2%",
  },
});
