import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Loading, SquadaOneText } from "./index";
import { logEvent, messaging } from "../firebase";
import { EVENTS } from "../firebase/events";
import { Config } from "../config";
import { hp } from "../dimensions";
import { gql, useMutation, useQuery } from "@apollo/client";
import { publishNotification } from "../firebase/utils";

const GET_USER = gql`
  query GetProfileUser($username: [String]) {
    users(username: $username) {
      id
      username
      photo
      email
      followers {
        username
        publicAddress
      }
      following {
        username
        publicAddress
      }
    }
  }
`;

const CREATE_FOLLOW = gql`
  mutation CreateFollow($input: CreateFollowInput!) {
    createFollow(input: $input) {
      username
      followers {
        username
        publicAddress
      }
      following {
        username
        publicAddress
      }
    }
  }
`;

const DELETE_FOLLOW = gql`
  mutation DeleteFollow($input: DeleteFollowInput!) {
    deleteFollow(input: $input) {
      username
      followers {
        username
        publicAddress
      }
      following {
        username
        publicAddress
      }
    }
  }
`;

const displayButton = (pressButton, containerStyle, textStyle, text) => {
  return (
    <TouchableOpacity
      style={[styles.buttonContainer, containerStyle]}
      onPress={pressButton}
    >
      <SquadaOneText style={[styles.buttonText, textStyle]}>
        {text}
      </SquadaOneText>
    </TouchableOpacity>
  );
};

const FollowButton = ({ loggedInUserStruct, otherUsername }) => {
  const { data, loading } = useQuery(GET_USER, {
    variables: { username: otherUsername },
  });

  const [followUser] = useMutation(CREATE_FOLLOW, {
    refetchQueries: [{ query: GET_USER }],
  });

  const [unfollowUser] = useMutation(DELETE_FOLLOW, {
    refetchQueries: [{ query: GET_USER }],
  });

  if (loading || data?.users === undefined) return <Loading />;
  const otherUser = data.users[0];
  const { followers } = otherUser;
  let isUserBeingFollowedByAuthenticatedUser, pressButton;
  const { account, id, username } = loggedInUserStruct;
  let component;

  if (account) {
    if (username) {
      isUserBeingFollowedByAuthenticatedUser = followers.some(
        (follower) => follower.username === username
      );

      pressButton = () => {
        const context = {
          headers: {
            "user-public-address": account,
          },
        };

        logEvent(
          isUserBeingFollowedByAuthenticatedUser
            ? EVENTS.UNFOLLOW
            : EVENTS.FOLLOW,
          { user_id: otherUser.id }
        );

        if (isUserBeingFollowedByAuthenticatedUser) {
          if (messaging) {
            messaging().unsubscribeFromTopic("f" + otherUsername);
          }
        } else {
          const notification = {
            title: username + " is now following you!",
          };
          const notifData = {
            path: "/user/" + username,
          };
          publishNotification("u" + otherUsername, notification, notifData);
          if (messaging) {
            messaging().subscribeToTopic("f" + otherUsername);
          }
        }

        return isUserBeingFollowedByAuthenticatedUser
          ? unfollowUser({
              variables: {
                input: {
                  follower: id,
                  following: {
                    disconnect: [{ user: otherUser.id }],
                  },
                },
              },
              context,
            })
          : followUser({
              variables: {
                input: {
                  follower: id,
                  following: {
                    connect: [{ user: otherUser.id }],
                  },
                },
              },
              context,
            });
      };

      component = isUserBeingFollowedByAuthenticatedUser
        ? displayButton(
            pressButton,
            styles.unfollowButtonContainer,
            styles.unfollowButtonText,
            "UNFOLLOW"
          )
        : displayButton(
            pressButton,
            styles.followButtonContainer,
            styles.followButtonText,
            "FOLLOW"
          );
    } else {
      isUserBeingFollowedByAuthenticatedUser = false;
    }
  } else {
    isUserBeingFollowedByAuthenticatedUser = false;
  }

  return <View style={{ width: "100%" }}>{component}</View>;
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    paddingVertical: 10,
  },
  buttonText: {
    fontSize: hp(2),
  },
  followButtonContainer: {
    backgroundColor: Config.Color.DFM_GREEN,
  },
  followButtonText: {
    color: Config.Color.DFM_DARK_PURPLE,
  },
  unfollowButtonContainer: {
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  unfollowButtonText: {
    color: Config.Color.DFM_GREEN,
  },
});

export default FollowButton;
