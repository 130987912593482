import React from 'react';
import {
    View,
    Image,
} from 'react-native';
import {Config} from "../../config";
import RubikText from '../RubikText';

const Team = ({team, fixtureHeight}) => {
    const TEAM_SIZE = (fixtureHeight / 2) - 2;
    return (
        <View style={{
            height: '50%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 2,
        }}>
            <Image
                style={{
                    height: TEAM_SIZE,
                    width: TEAM_SIZE,
                    resizeMode: 'stretch',
                    marginRight: 10,
                }}
                source={{uri: team.logo_url}}
            />
            <RubikText
                adjustsFontSizeToFit
                numberOfLines={1}
                variant="medium"
                style={{ fontSize: 0.6 * TEAM_SIZE, color: Config.Color.BLACK }}
            >
                {team.name}
            </RubikText>
        </View>
    );
}
export default Team;
