import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  BackHandler,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { ToastProvider } from "react-native-toast-notifications";
import { ApolloProvider, gql } from "@apollo/client";
import AsyncStorage from "@react-native-community/async-storage";
import { client } from "./apollo";
import codePush from "./codePush";
import { AppBody, AppHeader, LoginSwitch, NavBar } from "./components";
import { LoginState } from "./components/LoginSwitch";
import { Config, NULL_ADDRESS } from "./config";
import Dimensions from "./dimensions";
import {
  dynamicLinks,
  logEvent,
  logJoinGroup,
  logLogin,
  messaging,
  setUserId,
} from "./firebase";
import { addDevice } from "./firebase/devices";
import { EVENTS } from "./firebase/events";
import { BranchEvent, EVENTS as BranchEvents } from "./branch";
import { getScoresTopics, publishNotification } from "./firebase/utils";
import { removeFromArray } from "./formatting";
import { useScreenTracking } from "./hooks";
import { loginInGate, refreshLoginInGate } from "./login";
import { Router, withRouter } from "./router";
import Sentry from "./sentry";
import {
  activateConnector,
  deactivateConnector,
  getPreActivationRedirectConnector,
  getWeb3,
  isConnectorActive,
} from "./web3";
import { magicInstance } from "./web3/connectors/magic";
import {
  MAGIC_LINK,
  MAGIC_OAUTH_APPLE,
  MAGIC_OAUTH_GOOGLE,
} from "./web3/connectors/types";
import { addWallet } from "./web3/wallets";
import {
  populateCreateClashTx,
  populateSubmitPredictionTx,
  sendTransaction,
} from "./web3/utils";
import { sendMetaTransaction } from "./web3/biconomy";
import { TransactionContext } from "./contexts/TransactionContext";

function AppWithoutRouter({ history, location }) {
  const [emailToLogin, setEmailToLogin] = useState(null);
  const [loginState, setLoginState] = useState(LoginState.DEFAULT);
  const [pendingPredictions, setPendingPredictions] = useState([]);
  const [userStruct, setUserStruct] = useState({});
  const [web3, setWeb3] = useState(null);
  const [transactionConcluded, setTransactionConcluded] = useState(false);
  const transaction = useMemo(
    () => ({ transactionConcluded, setTransactionConcluded }),
    [transactionConcluded]
  );

  useEffect(() => {
    const backAction = () => {
      if (location.pathname !== "/") {
        history.goBack();
      } else {
        Alert.alert(
          "Exiting DEFYME.io",
          "Are you sure you want to exit the app?",
          [
            {
              text: "Cancel",
              onPress: () => null,
              style: "cancel",
            },
            { text: "YES", onPress: () => BackHandler.exitApp() },
          ]
        );
      }
      return true;
    };
    BackHandler.addEventListener("hardwareBackPress", backAction);
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", backAction);
  }, [history, location.pathname]);

  useEffect(() => {
    if (messaging) {
      messaging()
        .requestPermission()
        .then((authStatus) => {
          const enabled =
            authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
            authStatus === messaging.AuthorizationStatus.PROVISIONAL;

          if (enabled) {
            console.log("Authorization status:", authStatus);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (messaging) {
      const handleRemoteMessage = (remoteMessage) => {
        if (remoteMessage) {
          console.log(remoteMessage);
          history.push(remoteMessage.data.path);
        }
      };
      messaging().onNotificationOpenedApp(handleRemoteMessage);
      messaging().getInitialNotification().then(handleRemoteMessage);
    }

    //The following if condition aims on verifying that the app is running on an environment that supports this module
    if (dynamicLinks) {
      const handleDynamicLink = (link) => {
        if (link && link.url.startsWith(Config.APP_URL)) {
          history.push(link.url.slice(Config.APP_URL.length));
        }
      };
      const unsubscribeDynamicLinks = dynamicLinks().onLink(handleDynamicLink);
      dynamicLinks().getInitialLink().then(handleDynamicLink);
      return unsubscribeDynamicLinks;
    }
  }, [history]);

  const createClash = async (
    title,
    tokenAddress,
    amount,
    fixturesIds,
    results,
    source,
    isListed,
    isMetaTx,
    isFromAllowance
  ) => {
    const { account, username } = userStruct;
    const { Predicted } = Config.Referee.Events;
    const predictionTx = {
      date: null,
      clash: {
        tokenAddress: tokenAddress,
        amount: amount,
        title: title,
        user: account,
        username: username,
        fixturesIds: fixturesIds,
        isListed: isListed,
      },
      results: results,
      type: Predicted.STATE,
    };
    setPendingPredictions([predictionTx].concat(pendingPredictions));

    const createTx = populateCreateClashTx(
      tokenAddress,
      title,
      String(amount),
      isListed,
      fixturesIds,
      results,
      isFromAllowance
    );

    let sendMethod;
    if (tokenAddress === NULL_ADDRESS) {
      sendMethod = sendTransaction(
        createTx,
        isFromAllowance
          ? Config.RefereeAllowanceVault.ADDRESS
          : Config.Referee.ADDRESS,
        account,
        amount,
        web3
      );
    } else {
      if (isMetaTx) {
        const methodCode = isFromAllowance
          ? "RefereeAllowanceVault_CreateClash"
          : "Referee_CreateClash";
        sendMethod = sendMetaTransaction(
          createTx,
          methodCode,
          account,
          isFromAllowance
            ? Config.RefereeAllowanceVault.ADDRESS
            : Config.Referee.ADDRESS,
          web3
        );
      } else {
        sendMethod = sendTransaction(
          createTx,
          isFromAllowance
            ? Config.RefereeAllowanceVault.ADDRESS
            : Config.Referee.ADDRESS,
          account,
          undefined,
          web3
        );
      }
    }

    return sendMethod
      .then(async (receipt) => {
        setTransactionConcluded(true);
        const clashStorageLogs = receipt["logs"].filter(
          (log) => log["address"] === Config.ClashStorage.ADDRESS
        );
        //clashId is the 3rd topic of the 1st log regarding ClashStorage (corresponding to event ClashCreated)
        const clashId = clashStorageLogs[0]["topics"][2];
        const notification = {
          title: title,
          body: username + " created a new league. Do you feel DEFIED?",
        };
        const notifData = {
          path: "/league/" + clashId,
        };
        await publishNotification("f" + username, notification, notifData);
        if (messaging) {
          await messaging().subscribeToTopic("o" + clashId);
          getScoresTopics(fixturesIds, results).forEach((topic) =>
            messaging().subscribeToTopic(topic)
          );
        }
        const txRemoved = removeFromArray(predictionTx, pendingPredictions);
        predictionTx["clash"]["id"] = clashId;
        predictionTx["date"] = Date.now();
        setPendingPredictions(txRemoved);
        logEvent(EVENTS.CREATE_LEAGUE_SUCCESS, { from: source });
        if (BranchEvent) {
          const event = new BranchEvent(BranchEvents.CREATE_LEAGUE, {
            custom_data: { totalMatches: fixturesIds.length },
          });
          event.logEvent().catch(console.error);
        }
        return clashId;
      })
      .catch((error) => {
        logEvent(EVENTS.CREATE_LEAGUE_ERROR, { from: source });
        Sentry.captureException(error);
        setPendingPredictions(
          removeFromArray(predictionTx, pendingPredictions)
        );
        throw error;
      });
  };

  const submitPrediction = async (
    clashId,
    tokenAddress,
    amount,
    fixturesIds,
    results,
    title,
    isMetaTx,
    isFromAllowance
  ) => {
    const { account, username } = userStruct;
    const transaction = {
      date: null,
      clash: {
        id: clashId,
        tokenAddress: tokenAddress,
        amount: amount,
        user: account,
        username: username,
        fixturesIds: fixturesIds,
      },
      results: results,
      type: Config.Referee.Events.Predicted.STATE,
    };
    setPendingPredictions([transaction].concat(pendingPredictions));

    const predictionTx = populateSubmitPredictionTx(
      tokenAddress,
      clashId,
      results,
      isFromAllowance
    );

    let sendMethod;
    if (tokenAddress === NULL_ADDRESS) {
      sendMethod = sendTransaction(
        predictionTx,
        isFromAllowance
          ? Config.RefereeAllowanceVault.ADDRESS
          : Config.Referee.ADDRESS,
        account,
        amount,
        web3
      );
    } else {
      if (isMetaTx) {
        const methodCode = isFromAllowance
          ? "RefereeAllowanceVault_EnterClash"
          : "Referee_EnterClash";

        sendMethod = sendMetaTransaction(
          predictionTx,
          methodCode,
          account,
          isFromAllowance
            ? Config.RefereeAllowanceVault.ADDRESS
            : Config.Referee.ADDRESS,
          web3
        );
      } else {
        sendMethod = sendTransaction(
          predictionTx,
          isFromAllowance
            ? Config.RefereeAllowanceVault.ADDRESS
            : Config.Referee.ADDRESS,
          account,
          undefined,
          web3
        );
      }
    }

    return sendMethod
      .then(async () => {
        setTransactionConcluded(true);
        const notification = {
          title: title,
          body: username + " accepted the defy and joined your league!",
        };
        const notifData = {
          path: "/league/" + clashId,
        };
        await publishNotification("o" + clashId, notification, notifData);
        if (messaging) {
          await messaging().subscribeToTopic("c" + clashId);
          getScoresTopics(fixturesIds, results).forEach((topic) =>
            messaging().subscribeToTopic(topic)
          );
        }
        const txRemoved = removeFromArray(transaction, pendingPredictions);
        transaction["date"] = Date.now();
        setPendingPredictions(txRemoved);
        logEvent(EVENTS.JOIN_LEAGUE_SUCCESS);
        if (BranchEvent) {
          const event = new BranchEvent(BranchEvents.BET_PLACED);
          event.logEvent().catch(console.error);
        }
        logJoinGroup(clashId);
      })
      .catch((error) => {
        logEvent(EVENTS.JOIN_LEAGUE_ERROR);
        Sentry.captureException(error);
        setPendingPredictions(removeFromArray(transaction, pendingPredictions));
        throw error;
      });
  };

  const switchLoginState = (loginState) => () => {
    setLoginState((prevState) =>
      prevState === loginState ? LoginState.DEFAULT : loginState
    );
  };

  const fetchDataAfterRegistration = async (username, email, wallet) => {
    userStruct["username"] = username;
    userStruct["email"] = email;
    userStruct["wallets"] = [wallet];
    await AsyncStorage.setItem("@user", JSON.stringify(userStruct));
    setUserStruct({ ...userStruct });
    setLoginState(LoginState.USERNAME_SUCCESSFULLY_SET);
  };

  const logout = () => {
    deactivateConnector(userStruct.wallet);
    setEmailToLogin(null);
    setLoginState(LoginState.DEFAULT);
    setPendingPredictions([]);
    setUserStruct({});
    setWeb3(null);
    AsyncStorage.removeItem("@user");
    logEvent(EVENTS.LOGOUT);
    setUserId(null);
  };

  const login = useCallback(async (walletProvider, args = {}) => {
    const fetchUserData = async (web3, _userStruct, did) => {
      return await loginInGate(did).then(async (token) => {
        if (token) {
          _userStruct["token"] = token;
          const accounts = await web3.eth.getAccounts();
          const publicAddress = accounts[0];
          if (publicAddress) {
            _userStruct["account"] = publicAddress;
            const getUserByEmail = client.query({
              query: gql`
                query GetUser($email: [String]) {
                  users(email: $email) {
                    id
                    username
                    email
                    wallets {
                      publicAddress
                      provider
                    }
                    devices {
                      id
                      token
                    }
                  }
                }
              `,
              variables: {
                email: _userStruct["email"],
              },
            });
            await getUserByEmail
              .then(async (res) => {
                const { users } = res.data;
                // Does the user exist?
                if (users.length > 0) {
                  const user = users[0];
                  const { id, username, email, wallets, devices } = user;
                  _userStruct["id"] = id;
                  _userStruct["username"] = username;
                  _userStruct["email"] = email;
                  _userStruct["wallets"] = wallets.map((w) => w.publicAddress);

                  let wallet = wallets.find(
                    (w) => w.publicAddress === publicAddress
                  );
                  if (undefined === wallet) {
                    // Ok...this user is adding a
                    // new wallet, let's create it
                    await addWallet(user.id, publicAddress, walletProvider)
                      .then((w) => {
                        wallet = w;
                        logEvent(EVENTS.ADDITIONAL_WALLET_ADDED_SUCCESS, {
                          walletProvider: walletProvider,
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                        logEvent(EVENTS.ADDITIONAL_WALLET_ADDED_ERROR, {
                          walletProvider: walletProvider,
                        });
                      });
                  }
                  if (messaging) {
                    await messaging().registerDeviceForRemoteMessages();
                    const deviceToken = await messaging().getToken();
                    const device = devices.find((d) => d.token === deviceToken);
                    if (undefined === device) {
                      // Ok...this user is adding a
                      // new device, let's create it
                      addDevice(user.id, deviceToken, publicAddress, token)
                        .then(() => {
                          logEvent(EVENTS.ADDITIONAL_DEVICE_ADDED_SUCCESS, {
                            deviceToken: deviceToken,
                          });
                        })
                        .catch((error) => {
                          console.error(error);
                          logEvent(EVENTS.ADDITIONAL_DEVICE_ADDED_ERROR, {
                            deviceToken: deviceToken,
                          });
                        });
                    }
                    await messaging()
                      .subscribeToTopic("u" + username)
                      .catch(console.error);
                  }
                  await setUserId(String(id));
                } else {
                  // No user detected?
                  // Let's start the user creation flow
                  logEvent(EVENTS.CREATE_ACCOUNT);
                  setLoginState(LoginState.INITIAL_ASK_FOR_USERNAME);
                }
              })
              .catch(console.error);
          }
          return _userStruct;
        }
      });
    };

    if (walletProvider === MAGIC_LINK) {
      setEmailToLogin(args.email);
      switchLoginState(LoginState.CHECK_EMAIL_INBOX)();
    }
    const connector = await activateConnector(walletProvider, args);
    if (connector) {
      setWeb3(connector.web3);
      const _userStruct = { wallet: walletProvider };
      if (
        [MAGIC_LINK, MAGIC_OAUTH_GOOGLE, MAGIC_OAUTH_APPLE].includes(
          walletProvider
        )
      ) {
        logLogin(walletProvider);
        _userStruct["email"] = connector.user.email;
      }
      let finalUserStruct = await fetchUserData(
        connector.web3,
        _userStruct,
        connector.did
      );
      if (finalUserStruct) {
        AsyncStorage.setItem("@user", JSON.stringify(finalUserStruct));
        setLoginState(LoginState.DEFAULT);
        if (walletProvider === MAGIC_LINK) {
          setEmailToLogin(null);
        }
      } else {
        finalUserStruct = {};
        AsyncStorage.removeItem("@user");
        setLoginState(LoginState.LOGIN_SCREEN);
      }
      setUserStruct(finalUserStruct);
      return finalUserStruct;
    }
  }, []);

  useEffect(() => {
    const initialLoading = new Promise(async (resolve) => {
      let finalUserStruct = {};
      const connector = getPreActivationRedirectConnector();
      if (null !== connector) {
        finalUserStruct = await login(connector);
        resolve(finalUserStruct);
      } else {
        let _userStruct = await AsyncStorage.getItem("@user");
        if (_userStruct) {
          _userStruct = JSON.parse(_userStruct);
          const { token, wallet } = _userStruct;
          if (await refreshLoginInGate(token)) {
            const _isConnectorActive = await isConnectorActive(wallet);
            if (_isConnectorActive) {
              setWeb3(getWeb3(wallet));
              logLogin(wallet);
            } else {
              //Magic session expired, therefore a light session without signing access to the blockchain is activated.
              //It stays active until a transaction that requires the user's signature is issued.
              _userStruct["isLightSessionOn"] = true;
              logLogin("LightSession");
            }
            finalUserStruct = _userStruct;
            setUserId(String(finalUserStruct.id));
          } else {
            await AsyncStorage.removeItem("@user");
          }
        }
        setUserStruct(finalUserStruct);
        resolve(finalUserStruct);
      }

      if (finalUserStruct.account && finalUserStruct.username === undefined) {
        setLoginState(LoginState.SHOW_INTRO);
      }
    });
    setUserStruct({ initialLoading: initialLoading });
  }, [login]);

  useScreenTracking();

  return (
    <TransactionContext.Provider value={transaction}>
      <SafeAreaView style={styles.main}>
        {Platform.OS !== "web" && <magicInstance.Relayer />}
        <View style={styles.appFrame}>
          <AppHeader
            toggleLoginScreen={switchLoginState(LoginState.LOGIN_SCREEN)}
            userStruct={userStruct}
            pathname={location.pathname}
          />
          <AppBody
            createClash={createClash}
            logout={logout}
            pendingPredictions={pendingPredictions}
            submitPrediction={submitPrediction}
            switchLoginState={switchLoginState}
            userStruct={userStruct}
          />
          <LoginSwitch
            emailToLogin={emailToLogin}
            fetchDataAfterRegistration={fetchDataAfterRegistration}
            login={login}
            state={loginState}
            switchState={switchLoginState}
            userStruct={userStruct}
          />
          <NavBar
            logout={logout}
            switchLoginState={switchLoginState}
            userStruct={userStruct}
          />
        </View>
      </SafeAreaView>
    </TransactionContext.Provider>
  );
}

const AppWithRouter = withRouter(AppWithoutRouter);

function AppWithoutCodePush() {
  return (
    <ToastProvider>
      <ApolloProvider client={client}>
        <Router>
          <AppWithRouter />
        </Router>
      </ApolloProvider>
    </ToastProvider>
  );
}

const App = codePush(AppWithoutCodePush);
export default App;

const stylesWeb = StyleSheet.create({
  main: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Config.Color.GREY,
  },
  appFrame: {
    height: Dimensions.MAX_HEIGHT,
    width: Dimensions.MAX_WIDTH,
  },
});

const stylesNative = StyleSheet.create({
  main: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Config.Color.CY_PURPLE,
  },
  appFrame: {
    height: "100%",
    width: Dimensions.MAX_WIDTH,
  },
});

const styles = Platform.OS === "web" ? stylesWeb : stylesNative;
