import { ethers } from "ethers";
import Web3 from "web3";
import { BiconomyConfig } from "./config";
import { Config } from "../../config";

let helperAttributes = {};
helperAttributes.ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
helperAttributes.baseURL = BiconomyConfig.baseURL;
helperAttributes.biconomyForwarderAbi = BiconomyConfig.forwarderAbi;
helperAttributes.biconomyForwarderDomainData =
  BiconomyConfig.biconomyForwarderDomainData;
helperAttributes.domainType = BiconomyConfig.domainType;
helperAttributes.forwardRequestType = BiconomyConfig.forwardRequestType;

const getContractAddresses = async () => {
  let contractAddresses = {};
  let systemInfo = {};
  try {
    const apiInfo = `${helperAttributes.baseURL}/api/v2/meta-tx/systemInfo?networkId=${Config.NETWORK.chainId}`;
    const response = await fetch(apiInfo);
    systemInfo = await response.json();
  } catch (error) {
    console.error(error);
  }
  if (Boolean(BiconomyConfig.forwarderAddress)) {
    //Displaying warning in case of the forwarderAddress that is set on the config is not one of the recommended
    if (
      !systemInfo.biconomyForwarderAddresses?.includes(
        BiconomyConfig.forwarderAddress
      )
    ) {
      console.warn(
        "WARNING: Forwarder with address " +
          BiconomyConfig.forwarderAddress +
          " isn't included in systemInfo.biconomyForwarderAddresses"
      );
    }
    contractAddresses.biconomyForwarderAddress =
      BiconomyConfig.forwarderAddress;
  } else {
    contractAddresses.biconomyForwarderAddress =
      systemInfo.biconomyForwarderAddress;
  }
  return contractAddresses;
};

/**
 * Returns ABI and Forwarder's address
 * You can build biconomy forwarder contract object using above values and calculate the nonce
 */
const getBiconomyForwarderConfig = async () => {
  const contractAddresses = await getContractAddresses();
  const forwarderAddress = contractAddresses.biconomyForwarderAddress;
  return {
    abi: helperAttributes.biconomyForwarderAbi,
    address: forwarderAddress,
  };
};

/**
 * pass the below params in any order e.g. account=<account>,batchNone=<batchNone>,...
 * @param {*}  account - from (end user's) address for this transaction
 * @param {*}  to - target recipient contract address
 * @param {*}  gasLimitNum - gas estimation of your target method in numeric format
 * @param {*}  batchId - batchId
 * @param {*}  batchNonce - batchNonce which can be verified and obtained from the biconomy forwarder
 * @param {*}  data - functionSignature of target method
 * @param {*}  deadline - optional deadline for this forward request
 */
const buildForwardTxRequest = async ({
  account,
  to,
  gasLimitNum,
  batchId,
  batchNonce,
  data,
  deadline,
}) => {
  return {
    from: account,
    to: to,
    token: helperAttributes.ZERO_ADDRESS,
    txGas: gasLimitNum,
    tokenGasPrice: "0",
    batchId: parseInt(batchId),
    batchNonce: parseInt(batchNonce),
    deadline: deadline || Math.floor(Date.now() / 1000 + 3600),
    data: data,
  };
};

/**
 * pass your forward request
 * use this method to build message to be signed by end user in EIP712 signature format
 * @param {*} request - forward request object
 */
const getDataToSignForEIP712 = async (request) => {
  const contractAddresses = await getContractAddresses();
  const forwarderAddress = contractAddresses.biconomyForwarderAddress;
  let domainData = helperAttributes.biconomyForwarderDomainData;
  domainData.salt = ethers.utils.hexZeroPad(
    ethers.BigNumber.from(Config.NETWORK.chainId).toHexString(),
    32
  );
  domainData.verifyingContract = forwarderAddress;

  return JSON.stringify({
    types: {
      EIP712Domain: helperAttributes.domainType,
      ERC20ForwardRequest: helperAttributes.forwardRequestType,
    },
    domain: domainData,
    primaryType: "ERC20ForwardRequest",
    message: request,
  });
};

/**
 * pass your forward request
 * use this method to build message to be signed by end user in personal signature format
 * @param request
 */
const getDataToSignForPersonalSign = (request) => {
  return Web3.utils.soliditySha3(
    { type: "address", value: request.from },
    { type: "address", value: request.to },
    { type: "address", value: request.token },
    { type: "uint256", value: request.txGas },
    { type: "uint256", value: request.tokenGasPrice },
    { type: "uint256", value: request.batchId },
    { type: "uint256", value: request.batchNonce },
    { type: "uint256", value: request.deadline },
    { type: "bytes32", value: ethers.utils.keccak256(request.data) }
  );
};

/**
 * get the domain seperator that needs to be passed while using EIP712 signature type
 */
const getDomainSeperator = async () => {
  const contractAddresses = await getContractAddresses();
  const forwarderAddress = contractAddresses.biconomyForwarderAddress;
  let domainData = helperAttributes.biconomyForwarderDomainData;
  domainData.salt = ethers.utils.hexZeroPad(
    ethers.BigNumber.from(Config.NETWORK.chainId).toHexString(),
    32
  );
  domainData.verifyingContract = forwarderAddress;

  return ethers.utils.keccak256(
    ethers.utils.defaultAbiCoder.encode(
      ["bytes32", "bytes32", "bytes32", "address", "bytes32"],
      [
        ethers.utils.id(
          "EIP712Domain(string name,string version,address verifyingContract,bytes32 salt)"
        ),
        ethers.utils.id(domainData.name),
        ethers.utils.id(domainData.version),
        domainData.verifyingContract,
        domainData.salt,
      ]
    )
  );
};

const getSignatureParameters = (signature) => {
  if (!Web3.utils.isHexStrict(signature)) {
    throw new Error(
      'Given value "'.concat(signature, '" is not a valid hex string.')
    );
  }
  const r = signature.slice(0, 66);
  const s = "0x".concat(signature.slice(66, 130));
  let v = "0x".concat(signature.slice(130, 132));
  v = Web3.utils.hexToNumber(v);
  if (![27, 28].includes(v)) v += 27;
  return {
    r: r,
    s: s,
    v: v,
  };
};

const constructMetaTransactionMessage = (
  nonce,
  salt,
  functionSignature,
  contractAddress
) => {
  return Web3.utils.soliditySha3(
    { type: "uint256", value: nonce },
    { type: "address", value: contractAddress },
    { type: "uint256", value: salt },
    { type: "bytes", value: functionSignature }
  );
};

export {
  helperAttributes,
  getDomainSeperator,
  getDataToSignForPersonalSign,
  getDataToSignForEIP712,
  buildForwardTxRequest,
  getBiconomyForwarderConfig,
  getSignatureParameters,
  constructMetaTransactionMessage,
};
