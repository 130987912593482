import React from "react";
import { View, StyleSheet } from "react-native";
import { Loading, RubikText } from "../components";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { Frame } from "./shared";

const MAX_HEIGHT = 0.45 * Dimensions.MAX_HEIGHT;

const LoadingOverlay = ({
  exit,
  isVisible,
  loadingColor,
  text = " ",
  title = " ",
}) => {
  return (
    <Frame
      isVisible={isVisible}
      headerText={title}
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
    >
      <View style={styles.bodyView}>
        <Loading
          color={loadingColor}
          containerStyle={{ flex: undefined, height: 0.4 * MAX_HEIGHT }}
        />
        <RubikText
          adjustsFontSizeToFit
          numberOfLines={5}
          variant="bold"
          style={styles.descriptionText}
        >
          {text.toUpperCase()}
        </RubikText>
      </View>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  bodyView: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionText: {
    width: "85%",
    fontSize: 0.05 * MAX_HEIGHT,
    lineHeight: 0.09 * MAX_HEIGHT,
    color: Config.Color.WHITE,
    marginBottom: 0.07 * MAX_HEIGHT,
    textAlign: "center",
    overflow: "visible",
  },
});

export default LoadingOverlay;
