import React, { useCallback, useState } from "react";
import {
  Image,
  StyleSheet,
  TextInput as RNTextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Slider } from "@miblanchard/react-native-slider";
import { RobotoText } from "../../components";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import { useDebounce } from "../../hooks";
import { API as TransakApi } from "../../web3/transak";
import { floorToken, parseToken } from "../../web3/utils";

const AmountSelector = ({
  amount,
  containerStyle,
  maximumAmount,
  setAmount,
  showMessage,
  token,
}) => {
  const [lastSlideValue, setLastSlideValue] = useState(null);
  const [conversionPrice, setConversionPrice] = useState(null);
  const [convertedPrice, setConvertedPrice] = useState(0);

  const step =
    (1 / 10 ** token.maxFractionDigits) * Math.pow(10, token.decimals);

  const fetchConversionPrice = useCallback(() => {
    TransakApi.getConversionPrice(token.caption).then(setConversionPrice);
  }, [token.caption]);

  React.useEffect(fetchConversionPrice, [fetchConversionPrice, token.caption]);

  const debouncedConversionPrice = useDebounce(conversionPrice, 30000);

  React.useEffect(() => {
    if (debouncedConversionPrice) {
      fetchConversionPrice();
    } else {
      setConversionPrice(null);
    }
  }, [debouncedConversionPrice, fetchConversionPrice]);

  React.useEffect(() => {
    if (conversionPrice !== null && conversionPrice !== 0)
      setConvertedPrice(Math.round(amount / conversionPrice));
  }, [conversionPrice, amount]);

  const useTextInput = React.useCallback(
    (val) => {
      const sanitizedVal = Number(val);
      if (Number.isNaN(sanitizedVal) || sanitizedVal < 0) {
        setAmount(0);
        return;
      }
      let newAmount = floorToken(
        String(sanitizedVal * Math.pow(10, token.decimals)),
        token
      );
      if (parseToken(newAmount, token) > parseToken(maximumAmount, token)) {
        newAmount = maximumAmount;
      }
      setAmount(Number(newAmount));
    },
    [maximumAmount, setAmount, token]
  );

  return (
    <View style={containerStyle}>
      <View style={styles.sliderContainer}>
        <Slider
          value={amount}
          onValueChange={(amount) => setAmount(amount[0])}
          minimumValue={0}
          maximumValue={maximumAmount}
          step={step}
          thumbStyle={styles.sliderThumbStyle}
          thumbTintColor={Config.Color.DFM_YELLOW}
          minimumTrackTintColor={Config.Color.DFM_YELLOW}
          maximumTrackTintColor={Config.Color.DFM_DARK_PURPLE}
          trackStyle={styles.sliderTrackStyle}
          style={styles.slider}
          onSlidingComplete={(value) => {
            const amountSelected = value[0];
            if (
              amountSelected === Number(maximumAmount) &&
              lastSlideValue === amountSelected
            ) {
              showMessage();
            }
            setLastSlideValue(amountSelected);
          }}
        />
        <View style={styles.sliderDataView}>
          <TouchableOpacity
            disabled={amount === 0}
            onPress={() => {
              setAmount(amount - step);
            }}
          >
            <Image
              source={require("../../assets/minus-purple.png")}
              style={styles.sliderActionIcons}
            />
          </TouchableOpacity>
          <View
            style={{
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <RNTextInput
                adjustsFontSizeToFit
                numberOfLines={1}
                keyboardType="phone-pad"
                underlineColorAndroid={"transparent"}
                style={[
                  [
                    styles.sliderDataText,
                    {
                      outline: "none",
                    },
                  ],
                ]}
                value={String(parseToken(String(amount), token))}
                onChangeText={useTextInput}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <RobotoText
                variant={"regular"}
                style={{ fontSize: hp(1.5) }}
                adjustsFontSizeToFit
                numberOfLines={1}
              >
                ~ {parseToken(String(convertedPrice), token)}$
              </RobotoText>
            </View>
          </View>
          <TouchableOpacity
            disabled={amount + step > maximumAmount}
            onPress={() => {
              setAmount(amount + step);
            }}
          >
            <Image
              source={require("../../assets/plus-purple.png")}
              style={styles.sliderActionIcons}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sliderContainer: {
    backgroundColor: Config.Color.WHITE,
    padding: "5%",
  },
  slider: {
    width: "100%",
    alignItems: "stretch",
    justifyContent: "center",
  },
  sliderThumbStyle: { height: 24, width: 24 },
  sliderTrackStyle: {
    height: hp(0.5),
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  sliderDataView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sliderDataText: {
    textAlign: "center",
    fontFamily: "Roboto-Regular",
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  sliderActionIcons: {
    height: hp(2.5),
    width: hp(2.5),
    resizeMode: "contain",
  },
});

export default AmountSelector;
