import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ActionButton } from "../../components";
import { hp } from "../../dimensions";
import { Frame } from "../shared";
import RulesList from "../../components/RulesList";

const rules = [
  () =>
    "With Play As You Go mode activated, you can add your score prediction anytime before the game starts.",
  () => "Once the score is saved, you cannot change it.",
  () =>
    "If you forget to add your score prediction, you will receive 0 points for that match.",
  () => "Points are calculated at the end of 90’ or 120’ when applicable.",
];

const PlayAsYouGoRulesOverlay = ({ exit, isVisible }) => {
  return (
    <Frame
      isVisible={isVisible}
      headerText="PLAY AS YOU GO"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={"payg_rules"}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <RulesList list={rules} />
        </View>
        <View style={styles.footerView}>
          <ActionButton
            onPress={exit}
            title="CLOSE"
            containerStyle={styles.actionsButtonContainer}
          />
        </View>
      </ScrollView>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    flexDirection: "row",
    height: hp(15),
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsButtonContainer: {
    width: "100%",
  },
});

export default PlayAsYouGoRulesOverlay;
