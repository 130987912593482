export function sortByMinutes(a, b) {
  if (a.minute === b.minute) {
    if (a.type === "PENALTY" && a.minute_extra === b.minute_extra) {
      return a.team_id - b.team_id;
    }
    return a.minute_extra - b.minute_extra;
  }
  return a.minute - b.minute;
}

/** Compare two dates (could be of any type supported by the convert function above)
 * @returns number|NaN
 * -1 : if a < b
 * 0 : if a = b
 * 1 : if a > b
 * NaN : if a or b is an illegal date
 * NOTE: The code inside isFinite does an assignment (=).
 */
function compareDates(a, b) {
  /** Converts the date in d to a date-object.
   * @param d
   *  a date object: returned without modification
   *  an array: Interpreted as [year,month,day]. NOTE: month is 0-11.
   *  a number: Interpreted as number of milliseconds since 1 Jan 1970 (a timestamp)
   *  a string: Any format supported by the javascript engine, like
   *            "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
   *  an object: Interpreted as an object with year, month and date
   *             attributes.  **NOTE** month is 0-11.
   */
  function convertDate(d) {
    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : d.constructor === Number
      ? new Date(d)
      : d.constructor === String
      ? new Date(d)
      : typeof d === "object"
      ? new Date(d.year, d.month, d.date)
      : NaN;
  }

  return isFinite((a = convertDate(a).valueOf())) &&
    isFinite((b = convertDate(b).valueOf()))
    ? (a > b) - (a < b)
    : NaN;
}

export function sortFixtures(a, b) {
  const c1 = compareDates(a.date, b.date);
  if (c1 === 0) {
    const c2 = a.competition ? a.competition.id - b.competition.id : 0;
    if (c2 === 0) {
      return a.id - b.id;
    }
    return c2;
  }
  return c1;
}

export function sortByDate(a, b) {
  if (!a.date) return -1;
  if (!b.date) return 1;
  return compareDates(b.date, a.date);
}

export function sortPlayers(a, b) {
  if (a.uniform_number === b.uniform_number) {
    return a.name.localeCompare(b.name);
  }
  if (!a.uniform_number) {
    return 1;
  }
  if (!b.uniform_number) {
    return -1;
  }
  return a.uniform_number - b.uniform_number;
}

export function sortUsersAlphabetically(a, b) {
  return a.username.toLowerCase().localeCompare(b.username.toLowerCase());
}

export function sortLeaderboard(a, b) {
  if (a.balance === b.balance) {
    if (a.nClashes === b.nClashes) {
      return a.username.localeCompare(b.username);
    } else {
      return b.nClashes - a.nClashes;
    }
  }
  return b.balance - a.balance;
}

export function sortSagaLeaderboard(a, b) {
  if (a.points === b.points) {
    return a.user.localeCompare(b.user);
  }
  return b.points - a.points;
}

export function sortClashes(a, b) {
  if (a.hasFinished === b.hasFinished) {
    if (a.hasStarted === b.hasStarted) {
      return a.title.localeCompare(b.title);
    } else {
      return b.hasStarted - a.hasStarted;
    }
  }
  return b.hasFinished - a.hasFinished;
}
