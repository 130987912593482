import { gql } from '@apollo/client';
import { client } from '../../apollo';
import { getAuthToken } from '../../auth';

const ADD_WALLET = gql`
    mutation AddWallet(
        $userId: Int!,
        $publicAddress: String!,
        $walletProvider: WalletProvider!,
    ) {
        addWallet(
            user: $userId
            publicAddress: $publicAddress,
            provider: $walletProvider,
        ) {
            id
            publicAddress
            provider
        }
    }
`;

export async function addWallet(userId, publicAddress, walletProvider) {
  return client.mutate({
    mutation: ADD_WALLET,
    variables: {
      userId: parseInt(userId),
      publicAddress: publicAddress,
      walletProvider: walletProvider
    },
    context: {
      headers: {
        "user-public-address": publicAddress,
        Authentication: "Bearer " + await getAuthToken(),
      },
    },
  });
}



