import React, { useState } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { ActionButton } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { AmountSelector, Frame, Label, TextInput } from "./shared";
import ToggleButton from "../components/ToggleButton";
import BalancesToggleButton from "../components/BalancesToggleButton";
import { floorToken } from "../web3/utils";

const { Tokens } = Config.Referee;

const SetClashParamsOverlay = ({
  amount,
  balances,
  exit,
  isVisible,
  setAmount,
  setTitle,
  submit,
  title,
  titleInput,
  tokenIndex,
  showMessage,
  trackingScreenName,
  isListed,
  setIsListed,
  isAllowance,
  setIsAllowance,
  allowance,
}) => {
  const balance = floorToken(balances[tokenIndex], Tokens.ALL[tokenIndex]);
  const [currMax, setCurrMax] = useState(balance);

  let sourcesByToken = [];
  balances.forEach((balance, index) => {
    if (balance !== 0) {
      let token = Tokens.ALL[index];
      if (sourcesByToken[token.caption] === undefined) {
        sourcesByToken[token.caption] = [];
      }
      sourcesByToken[token.caption].push({
        balance: balance,
        isAllowance: false,
        token: token,
      });
    }
  });

  if (sourcesByToken[Config.ALLOWANCE_TOKEN] === undefined) {
    sourcesByToken[Config.ALLOWANCE_TOKEN] = [];
  }
  sourcesByToken[Config.ALLOWANCE_TOKEN].push({
    balance: allowance,
    isAllowance: true,
    token: Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN],
  });

  return (
    <Frame
      isVisible={isVisible}
      headerText="CREATE NEW LEAGUE"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={trackingScreenName}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <View style={styles.verticalPadded}>
            <Label isRequired style={styles.labelText}>
              Name your league
            </Label>
            <TextInput
              inputRef={titleInput}
              value={title}
              onChangeText={setTitle}
            />
          </View>
          {/*<View style={styles.verticalPadded}>*/}
          {/*  <Label isRequired style={styles.labelText}>*/}
          {/*    Select the league's token*/}
          {/*  </Label>*/}
          {/*  <Dropdown*/}
          {/*    items={Tokens.ALL}*/}
          {/*    selectedItem={tokenIndex}*/}
          {/*    selectItem={(tokenIndex) => {*/}
          {/*      setAmount(0);*/}
          {/*      setTokenIndex(tokenIndex);*/}
          {/*      setTokenAddress(Tokens.ALL[tokenIndex].address);*/}
          {/*      setCurrMax(0);*/}
          {/*    }}*/}
          {/*    style={styles.dropdown}*/}
          {/*    buttonTextStyle={styles.dropdownText}*/}
          {/*  />*/}
          {/*</View>*/}
          <View style={styles.verticalPadded}>
            <Label isRequired style={styles.labelText}>
              Select your source of Balance
            </Label>
            <BalancesToggleButton
              options={sourcesByToken[Tokens.ALL[tokenIndex].caption]}
              selected={isAllowance}
              setSelected={setIsAllowance}
              setCurrMax={setCurrMax}
            />
          </View>
          <View isRequired style={styles.verticalPadded}>
            <Label style={styles.labelText}>
              Select league ticket price ({Tokens.ALL[tokenIndex].caption})
            </Label>
            <AmountSelector
              amount={amount}
              maximumAmount={currMax}
              setAmount={setAmount}
              showMessage={showMessage}
              token={Tokens.ALL[tokenIndex]}
            />
          </View>
          <View style={styles.verticalPadded}>
            <Label style={styles.labelText}>League Accessibility</Label>
            <ToggleButton
              options={Config.LEAGUES_ACCESSIBILITY}
              values={Config.LEAGUES_ACCESSIBILITY_VALUES}
              selected={isListed}
              setSelected={setIsListed}
              descriptions={Config.LEAGUES_ACCESSIBILITY_DESCRIPTION}
            />
          </View>
        </View>
        <View style={styles.footerView}>
          <ActionButton
            onPress={submit}
            disabled={amount <= 0 || amount > currMax || !title}
            title="NEXT"
          />
        </View>
      </ScrollView>
    </Frame>
  );
};

export default SetClashParamsOverlay;

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    flex: 1,
  },
  frameView: {
    width: "95%",
    alignItems: undefined,
  },
  bodyView: {
    flex: 1,
  },
  dropdown: {
    height: hp(6),
    width: "100%",
    justifyContent: "flex-start",
    paddingHorizontal: "5%",
    fontSize: hp(2),
    fontFamily: "Roboto-Regular",
  },
  dropdownText: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
    fontFamily: "Roboto-Regular",
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  verticalPadded: {
    paddingBottom: "3%",
  },
  labelText: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Light",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Config.Color.WHITE,
    paddingHorizontal: "5%",
    paddingVertical: "2.5%",
    marginBottom: "2%",
  },
  checkboxIcon: {
    marginRight: "2%",
  },
  checkboxText: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontFamily: "Roboto-Regular",
    fontSize: hp(2),
  },
});
