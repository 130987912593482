import React from "react";
import { Text } from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";

const RobotoText = ({ variant, style, ...otherProps }) => {
  let defaultStyles = {};

  switch (variant) {
    case "black":
      defaultStyles.fontFamily = "Roboto-Black";
      break;
    case "blackItalic":
      defaultStyles.fontFamily = "Roboto-BlackItalic";
      break;
    case "bold":
      defaultStyles.fontFamily = "Roboto-Bold";
      break;
    case "boldItalic":
      defaultStyles.fontFamily = "Roboto-BoldItalic";
      break;
    case "italic":
      defaultStyles.fontFamily = "Roboto-Italic";
      break;
    case "light":
      defaultStyles.fontFamily = "Roboto-Light";
      break;
    case "lightItalic":
      defaultStyles.fontFamily = "Roboto-LightItalic";
      break;
    case "medium":
      defaultStyles.fontFamily = "Roboto-Medium";
      break;
    case "mediumItalic":
      defaultStyles.fontFamily = "Roboto-MediumItalic";
      break;
    case "thin":
      defaultStyles.fontFamily = "Roboto-Thin";
      break;
    case "thinItalic":
      defaultStyles.fontFamily = "Roboto-ThinItalic";
      break;
    case "match_team":
      defaultStyles.fontSize = hp(1.5);
      defaultStyles.fontFamily = "Roboto-Medium";
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      break;
    case "link":
      defaultStyles.fontFamily = "Roboto-MediumItalic";
      defaultStyles.textDecoration = "underline";
      break;
    case "leaderboard_player":
      defaultStyles.fontSize = hp(2);
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      break;
    case "profile_counters":
      defaultStyles.fontSize = hp(2);
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      defaultStyles.fontFamily = "Roboto-Medium";
      break;
    case "pink":
      defaultStyles.fontFamily = "Roboto-Regular";
      defaultStyles.color = Config.Color.DFM_PINK;
      break;
    case "your_score":
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(1);
      break;
    case "view_other_scores":
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      defaultStyles.fontSize = hp(1.5);
      break;
    case "powered":
      defaultStyles.fontFamily = "Roboto-Light";
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(1.9);
      defaultStyles.textAlignVertical = "center";
      break;
    case "regular":
    default:
      defaultStyles.fontFamily = "Roboto-Regular";
      defaultStyles.color = Config.Color.DFM_DARK_PURPLE;
      break;
  }

  return <Text {...otherProps} style={[defaultStyles, style]} />;
};

export default RobotoText;
