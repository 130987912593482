import { Config } from "../config";
import { dynamicLinks } from "../firebase";

export async function buildLink(path) {
  if (dynamicLinks) {
    return await dynamicLinks().buildShortLink(
      {
        link: Config.APP_URL + path,
        domainUriPrefix: Config.DYNAMIC_LINKS_DOMAIN,
        android: {
          packageName: Config.ANDROID_PACKAGE_NAME,
          fallbackUrl: Config.APP_URL + path,
        },
        ios: {
          bundleId: Config.IOS_BUNDLE_ID,
          fallbackUrl: Config.APP_URL + path,
        },
      },
      dynamicLinks.ShortLinkType.SHORT
    );
  }
  return null;
}

export function composeInviteObjData(amount, token, id, title, username) {
  return {
    notification: {
      title: `${title} is arriving!`,
      body: `${username} wants to clash against you with ${amount} ${token}`,
    },
    notifData: {
      path: "/league/" + id,
    },
    shareData: composeShareData(amount, token, id, title),
  };
}

export function composeShareData(amount, token, id, title) {
  return {
    path: "/league/" + id,
    text: `${title} is arriving! I dare you to join this league with ${amount} ${token}`,
  };
}

export function getScoresTopics(fixturesIds, results) {
  const topics = [];
  for (let i = 0; i < fixturesIds.length; i++) {
    const { homeScore, awayScore } = results[i];
    if (
      homeScore !== Config.PREDICTION_NOT_ADDED &&
      awayScore !== Config.PREDICTION_NOT_ADDED
    ) {
      topics.push("m" + fixturesIds[i] + "h" + homeScore + "a" + awayScore);
    }
  }
  return topics;
}

/**
 * Publishes a new notification towards the 'topic' subscribers through Firebase Cloud Messaging
 * @param topic - the topic that the notification addresses
 * @param notification - notification object (title, body, image)
 * @param data - any additional data to be sent
 */
export async function publishNotification(topic, notification, data) {
  await fetch(Config.MESSAGING_URL, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      topic: topic,
      notification: notification,
      data: data,
    }),
  }).catch(console.log);
}
