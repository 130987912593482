import {
  BLOCKCHAIN,
  CLASH_STORAGE_ADDRESS,
  FEATURE_FLAG_ALLOWANCE,
  FEATURE_FLAG_PAYG,
  ENVIRONMENT,
  MAGIC_API_KEY,
  NON_NATIVE_TOKENS,
  NON_NATIVE_TOKENS_ADDRESSES,
  NON_NATIVE_TOKENS_DECIMALS,
  NON_NATIVE_TOKENS_MFD,
  REFEREE_ADDRESS,
  REFEREE_ALLOWANCE_VAULT_ADDRESS,
} from "@env";
import { TokensABIs, TokensLogos } from "./assets/tokens";

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export class Config {
  static APP_URL = "https://app.defyme.io";
  static COACH_URL = "https://coach.clashya.com";
  static MESSAGING_URL = "https://messaging.clashya.com/api/firebase/send";
  static DYNAMIC_LINKS_DOMAIN = "https://clashya.page.link";
  static ANDROID_PACKAGE_NAME = "io.defyme";
  static IOS_BUNDLE_ID = "io.defyme";
  static MAGIC_API_KEY = MAGIC_API_KEY;
  static PREDICTION_NOT_ADDED = -1;
  static DISTRIBUTION_FEE = 0;
  static FEATURE_FLAG_PAYG = Boolean(Number.parseInt(FEATURE_FLAG_PAYG));
  static NETWORK = getNetwork(ENVIRONMENT === "PRODUCTION", BLOCKCHAIN);
  static ALLOWANCE_TOKEN = "USDC";
  static FEATURE_FLAG_ALLOWANCE = Boolean(Number(FEATURE_FLAG_ALLOWANCE));
  static RefereeAllowanceVault = class {
    static ADDRESS = REFEREE_ALLOWANCE_VAULT_ADDRESS;
  };
  static ClashStorage = class {
    static ADDRESS = CLASH_STORAGE_ADDRESS;
  };
  static Referee = class {
    static ADDRESS = REFEREE_ADDRESS;
    static Events = class {
      static ClashCreated = class {
        static STATE = "Created";
        static EVENT = "ClashCreated";
      };
      static Lost = class {
        static STATE = "Lost";
        static EVENT = "Lost";
        static CODE = -1;
      };
      static Predicted = class {
        static STATE = "Predicted";
        static EVENT = "Predicted";
      };
      static Prized = class {
        static STATE = "Prized";
        static EVENT = "Prized";
      };
      static Registered = class {
        static STATE = "Registered";
        static EVENT = "Registered";
      };
      static Reverted = class {
        static STATE = "Reverted";
        static EVENT = "Reverted";
        static CODE = -2;
      };
    };
    static Tokens = class {
      static NATIVE = {
        address: NULL_ADDRESS,
        caption: BLOCKCHAIN,
        logo: getNetworkLogo(BLOCKCHAIN),
        maxFractionDigits: getNetworkMaxFractionDigits(BLOCKCHAIN),
        decimals: 18,
      };
      static NON_NATIVE = getNonNativeTokens();
      static ALL = [this.NATIVE].concat(this.NON_NATIVE);
      static BY_ADDRESS = this.ALL.reduce(function (map, obj) {
        map[obj.address] = obj;
        return map;
      }, {});
      static BY_CAPTION = this.ALL.reduce(function (map, obj) {
        map[obj.caption] = obj;
        return map;
      }, {});
    };
  };
  static FavoritesCategories = class {
    static Competitions = class {
      static TITLE = "Competitions";
      static URL = "/competition";
      static TOPIC = "c";
      static INDEX = 0;
    };
    static Teams = class {
      static TITLE = "Teams";
      static URL = "/team";
      static TOPIC = "t";
      static INDEX = 1;
    };
    static Players = class {
      static TITLE = "Players";
      static URL = "/player";
      static TOPIC = "p";
      static INDEX = 2;
    };
  };
  static POSITIONS = ["Goalkeeper", "Defender", "Midfielder", "Attacker"];
  static FIXTURE_SCREEN_TABS = ["LEAGUES", "TIMELINE"];
  static TEAM_SCREEN_TABS = ["NEXT MATCHES", "LAST MATCHES"];
  static USER_SCREEN_TABS = ["ON GOING", "HISTORY"];
  static MY_PROFILE_SCREEN_TABS = ["WALLETS", "SETTINGS"];
  static CLASH_SCREEN_TABS = ["MATCHES", "LEADERBOARD"];
  static LEAGUES_SCREEN_TABS = ["MY LEAGUES", "OTHER LEAGUES", "MY HISTORY"];
  static LEAGUES_ACCESSIBILITY = ["PUBLIC", "PRIVATE"];
  static LEAGUES_ACCESSIBILITY_VALUES = {
    PUBLIC: true,
    PRIVATE: false,
  };
  static TX_SUBMITTING_MSGS = [
    { text: "Submitting your Transaction to the Blockchain", interval: 5000 },
    { text: "It’s still on it! Let’s wait a little while", interval: 5000 },
    { text: "It seems the network may be a bit busy", interval: 7000 },
    {
      text: "The blockchain might be under stress, feel free to close this window and check if it went through in a couple minutes",
      interval: 8000,
    },
  ];
  static LEAGUES_ACCESSIBILITY_DESCRIPTION = {
    PUBLIC: "Can be accessed by anyone.",
    PRIVATE: "Can only be accessed via shared link",
  };
  static Status = class {
    static NoDate = class {
      static CODE = ["TBA", "TO_BE_ANNOUNCED"];
    };
    static NotStarted = class {
      static CODE = ["NS", "NOT_STARTED"];
    };
    static FirstHalf = class {
      static CODE = ["1ST_HALF", "FIRST_HALF"];
    };
    static HalfTime = class {
      static CODE = ["HT", "HALF_TIME"];
      static SHORT = "HT";
      static LONG = "Half-time";
    };
    static SecondHalf = class {
      static CODE = ["2ND_HALF", "SECOND_HALF"];
    };
    static FullTimeBreak = class {
      static CODE = ["FT_BREAK", "FULL_TIME_BREAK"];
      static SHORT = "ET";
      static LONG = "Extra-time";
    };
    static ExtraTimeFirstHalf = class {
      static CODE = ["ET_1ST_HALF", "EXTRA_TIME_FIRST_HALF"];
    };
    static ExtraTimeSecondHalf = class {
      static CODE = ["ET_2ND_HALF", "EXTRA_TIME_SECOND_HALF"];
    };
    static ExtraTimeBreak = class {
      static CODE = ["ET_BREAK", "EXTRA_TIME_BREAK"];
      static SHORT = "ET";
      static LONG = "Extra-time break";
    };
    static Penalties = class {
      static CODE = ["PEN_LIVE", "PENALTY_LIVE"];
      static SHORT = "PEN";
      static LONG = "Penalties";
    };
    static FullTime = class {
      static CODE = ["FT", "FULL_TIME"];
    };
    static FullExtraTime = class {
      static CODE = ["AET", "FINISHED_AFTER_EXTRA_TIME"];
    };
    static FullPenalties = class {
      static CODE = ["FT_PEN", "FULL_TIME_PENALTY"];
    };
    static Postponed = class {
      static CODE = ["POSTP", "POSTPONED"];
    };
    static Upcoming = [this.NoDate, this.NotStarted];
    static Live = [
      this.FirstHalf,
      this.HalfTime,
      this.SecondHalf,
      this.FullTimeBreak,
      this.ExtraTimeFirstHalf,
      this.ExtraTimeBreak,
      this.ExtraTimeSecondHalf,
      this.Penalties,
    ];
    static Unfinished = this.Upcoming.concat(this.Live);
    static Finished = [this.FullTime, this.FullExtraTime, this.FullPenalties];
    static Started = this.Finished.concat(this.Live);
    static Blacklist = [this.Postponed];
    static all = [
      this.NoDate,
      this.NotStarted,
      this.FirstHalf,
      this.HalfTime,
      this.SecondHalf,
      this.FullTimeBreak,
      this.ExtraTimeFirstHalf,
      this.ExtraTimeBreak,
      this.ExtraTimeSecondHalf,
      this.Penalties,
      this.FullTime,
      this.FullExtraTime,
      this.FullPenalties,
      this.Postponed,
    ];
    static byCode = this.all.reduce(function (map, obj) {
      obj.CODE.forEach((code) => {
        map[code] = obj;
      });
      return map;
    }, {});
  };
  static Color = {
    WHITE: "#ffffff",
    BLACK: "#000000",
    GREY: "#909090",
    LIGHT_GREY: "#f6f6f6",
    DARK_GREY: "#505050",
    GREEN: "#00ff00",
    LIGHT_GREEN: "#b2ffb2",
    DARK_GREEN: "#0b8500",
    BLUE: "#003366",
    LINK_BLUE: "#0000EE",
    MEDIUM_AQUA_MARINE: "#66ddaa",
    YELLOW: "#f5d400",
    LIGHT_YELLOW: "#ffffcc",
    RED: "#f50000",
    LIGHT_RED: "#ff9999",
    CY_PURPLE: "#37033c",
    CY_DARK_PURPLE: "#330238",
    CY_GREEN: "#8bcc9c",
    CY_PINK: "#db2661",
    CY_YELLOW: "#f8b22e",
    KMS_NEON_BLUE: "#00FEE3",
    DFM_DARK_PURPLE: "#370647",
    DFM_LIGHT_PURPLE: "#AE26FF",
    DFM_YELLOW: "#F7E221",
    DFM_PINK: "#F0055C",
    DFM_GREEN: "#2BF773",
    DFM_LIGHT_BLUE: "#00FFDE",
    LIGHT_CYAN: "#93AEB8",
    GREY_67: "#ABABAB",
    TRANSPARENT: "rgba(0,0,0,0)",
    OVERLAY_BACKGROUND: "rgba(55, 6, 71, 0.95)",
  };

  static Assets = {
    DEFYME_LOGO: require("./assets/defyme-name.png"),
    DEFYME_LOGO_TAGLINE: require("./assets/defyme-tagline.png"),
  };
}

function getNetwork(IN_PROD, BLOCKCHAIN) {
  if (BLOCKCHAIN === "ETH") {
    return IN_PROD
      ? {
          signerRpcUrl: "https://eth-mainnet.public.blastapi.io", // Ethereum RPC URL
          providerRpcUrl: "https://eth-mainnet.public.blastapi.io", // Ethereum RPC URL
          chainId: 1, // Ethereum chain id
        }
      : {
          signerRpcUrl:
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", // Kovan RPC URL
          providerRpcUrl:
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", // Kovan RPC URL
          chainId: 42, // Kovan chain id
        };
  } else if (BLOCKCHAIN === "MATIC") {
    return IN_PROD
      ? {
          signerRpcUrl:
            "https://rpc.ankr.com/polygon", // Polygon RPC URL
          providerRpcUrl: "https://polygon-rpc.com", // Polygon RPC URL
          chainId: 137, // Polygon chain id
        }
      : {
          signerRpcUrl:
            "https://rpc.ankr.com/polygon_mumbai", // Mumbai RPC URL
          providerRpcUrl: "https://matic-mumbai.chainstacklabs.com", // Mumbai RPC URL
          chainId: 80001, // Mumbai chain id
        };
  } else {
    return IN_PROD
      ? {
          signerRpcUrl: "https://api.avax.network/ext/bc/C/rpc", // Avax C-Chain RPC URL
          providerRpcUrl: "https://api.avax.network/ext/bc/C/rpc", // Avax C-Chain RPC URL
          chainId: 43114, // Avax C-Chain chain id
        }
      : {
          signerRpcUrl: "https://api.avax-test.network/ext/bc/C/rpc", // Fuji RPC URL
          providerRpcUrl: "https://api.avax-test.network/ext/bc/C/rpc", // Fuji RPC URL
          chainId: 43113, // Fuji chain id
        };
  }
}

function getNetworkLogo(BLOCKCHAIN) {
  return TokensLogos[BLOCKCHAIN];
}

function getNetworkMaxFractionDigits(BLOCKCHAIN) {
  if (BLOCKCHAIN === "ETH") {
    return 3;
  } else if (BLOCKCHAIN === "MATIC") {
    return 0;
  } else {
    return 2;
  }
}

function getNonNativeTokens() {
  if (!NON_NATIVE_TOKENS) return [];
  const nonNativeTokens = NON_NATIVE_TOKENS.split(",");
  const nonNativeTokensAddresses = NON_NATIVE_TOKENS_ADDRESSES.split(",");
  const nonNativeTokensMFDs = NON_NATIVE_TOKENS_MFD.split(",");
  const nonNativeTokensDecimals = NON_NATIVE_TOKENS_DECIMALS.split(",");
  return nonNativeTokens.map((token, index) => {
    return {
      caption: token,
      address: nonNativeTokensAddresses[index],
      abi: TokensABIs[token.toUpperCase()],
      logo: TokensLogos[token.toUpperCase()],
      maxFractionDigits: nonNativeTokensMFDs[index],
      decimals: nonNativeTokensDecimals[index],
    };
  });
}
