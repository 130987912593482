import React from "react";
import YellowCard from "../../../assets/yellow-card.png";
import RedCard from "../../../assets/red-card.png";
import YellowRedCard from "../../../assets/yellow-red-card.png";
import TimelineItem from "./TimelineItem";

export default function Card({ event, isHome }) {
  const { player } = event;
  const images = {
    YELLOW_CARD: YellowCard,
    RED_CARD: RedCard,
    YELLOW_RED_CARD: YellowRedCard,
  };
  return (
    <TimelineItem
      mainText={player.name}
      iconSource={images[event.cardType + "_CARD"]}
      isHome={isHome}
      minutes={event.minute}
      minutesExtra={event.minuteExtra}
    />
  );
}
