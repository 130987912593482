import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  FlatList,
  Platform,
  StyleSheet,
} from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";
import { EVENTS } from "../firebase/events";
import { logEvent } from "../firebase";
import { CreateClashWizard } from "../overlays";
import { withRouter } from "../router";
import { sortClashes } from "../sorting";
import { FixtureStatus } from "../types/globalTypes";
import { infuraWeb3Struct, parseClash } from "../web3/utils";
import Loading from "./Loading";
import { checkLoginAndThen } from "./LoginSwitch";
import { LeagueCard, SquadaOneText } from "./index";
import EmptyListMessage from "./EmptyListMessage";

const FixtureClashesList = ({
  balance,
  competitions,
  createClash,
  fixture,
  logout,
  pendingPredictions,
  switchLoginState,
  users,
  userStruct,
}) => {
  const [clashes, setClashes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toCreateClash, setToCreateClash] = useState(false);

  const fetchClashes = useCallback(() => {
    infuraWeb3Struct.refereeInst.methods
      .getMatchClashes(fixture.id)
      .call()
      .then(async (clashes) => {
        let parsedClashes = [];
        const promises = clashes.map(async (clash) => {
          return new Promise(async (resolve) => {
            let parsedClash = await parseClash(clash);
            let canDisplay =
              parsedClash.isListed ||
              (!parsedClash.isListed &&
                userStruct?.wallets?.some((w) =>
                  parsedClash.predictions?.get(w)
                ));
            if (canDisplay) {
              parsedClashes.push(parsedClash);
            }
            resolve();
          });
        });
        await Promise.all(promises);
        setClashes(parsedClashes.sort(sortClashes));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setClashes([]);
        setIsLoading(false);
      });
  }, [fixture.id, userStruct.wallets]);

  useEffect(() => {
    fetchClashes();
  }, [fetchClashes]);

  const toggleToCreateClash = () => {
    if (toCreateClash) {
      setToCreateClash(false);
    } else {
      checkLoginAndThen(
        () => {
          setToCreateClash(true);
          logEvent(EVENTS.CREATE_LEAGUE_START, {
            from: "match",
            match_id: fixture.id,
          });
        },
        switchLoginState,
        userStruct,
        logout
      );
    }
  };

  const ClashItem = ({ clash }) => {
    return (
      <View style={styles.leagueContainer}>
        <LeagueCard
          userStruct={userStruct}
          account={userStruct.account}
          clash={clash}
          selectedFixture={fixture}
          prediction={clash.myPrediction}
          showYourScore={true}
          showTitle={true}
          showPrize={false}
        />
      </View>
    );
  };

  if (isLoading) return <Loading />;

  let parsedClashes = pendingPredictions
    .filter((p) => p.clash.fixturesIds.some((id) => id === fixture.id))
    .map((p) => {
      const clash = JSON.parse(JSON.stringify(p.clash));
      clash["myPrediction"] = p;
      return clash;
    })
    .concat(clashes);

  const isFixtureUpcoming = [
    FixtureStatus.NOT_STARTED,
    FixtureStatus.TO_BE_ANNOUNCED,
  ].includes(fixture.status);

  return (
    <View
      style={{
        alignItems: "flex-start",
        alignSelf: "center",
        height: "100%",
        width: "95%",
      }}
    >
      <View
        style={{ height: isFixtureUpcoming ? "85%" : "100%", width: "100%" }}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        {parsedClashes.length > 0 && (
          <FlatList
            showsVerticalScrollIndicator={false}
            data={parsedClashes}
            renderItem={({ item }) => <ClashItem clash={item} />}
            keyExtractor={(item, index) => index.toString()}
          />
        )}
        {parsedClashes.length <= 0 && (
          <EmptyListMessage
            code={
              isFixtureUpcoming
                ? "NO_LEAGUES_UPCOMING_MATCH"
                : "NO_LEAGUES_MATCH_STARTED"
            }
          />
        )}
      </View>
      {isFixtureUpcoming && (
        <View
          style={{ height: "15%", width: "100%", justifyContent: "center" }}
        >
          {/*CREATE BUTTON ON MATCH PAGE*/}
          <TouchableOpacity
            onPress={toggleToCreateClash}
            style={{
              flexDirection: "row",
              height: "60%",
              width: "95%",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "space-evenly",
              backgroundColor: Config.Color.DFM_GREEN,
              borderRadius: 0,
            }}
          >
            <SquadaOneText
              style={{
                fontSize: hp(2),
                color: Config.Color.DFM_DARK_PURPLE,
                paddingBottom: 3,
              }}
            >
              CREATE LEAGUE
            </SquadaOneText>
          </TouchableOpacity>
        </View>
      )}
      {isFixtureUpcoming && (
        <CreateClashWizard
          balance={balance}
          competitions={competitions}
          createClash={(
            title,
            tokenAddress,
            amount,
            fixturesIds,
            results,
            source,
            isListed,
            isMetaTx,
            isFromAllowance
          ) =>
            createClash(
              title,
              tokenAddress,
              amount,
              fixturesIds,
              results,
              source,
              isListed,
              isMetaTx,
              isFromAllowance
            ).then(fetchClashes)
          }
          fixedFixture={fixture}
          toCreateClash={toCreateClash}
          toggleToCreateClash={toggleToCreateClash}
          users={users}
          userStruct={userStruct}
          sourceScreen="fixture"
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  leagueContainer: {
    marginTop: "2%",
  },
});

export default withRouter(FixtureClashesList);
