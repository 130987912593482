import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { SquadaOneText } from "../index";
import { hp } from "../../dimensions";
import { TextInput } from "../../overlays/shared";

const ScoreInput = ({
  homePredictionInput,
  awayPredictionInput,
  predictions,
  currentFixture,
  handleBetValueInput,
}) => {
  useEffect(() => {
    if (predictions[currentFixture]?.["homeScore"] === undefined) {
      return homePredictionInput?.current?.focus();
    }
  });

  return (
    <View style={styles.predictionContainer}>
      <View style={styles.inputContainer}>
        <TextInput
          adjustsFontSizeToFit
          numberOfLines={1}
          keyboardType={"phone-pad"}
          underlineColorAndroid={"transparent"}
          inputStyle={styles.input}
          containerStyle={styles.inputContainer}
          inputRef={homePredictionInput}
          value={predictions[currentFixture]?.["homeScore"] ?? ""}
          onChangeText={(val) => {
            handleBetValueInput(
              val.slice(val.length - 1),
              "homeScore",
              "awayScore"
            );
          }}
          maxLength={1}
        />
      </View>
      <View style={styles.separatorContainer}>
        <SquadaOneText style={styles.separator}>:</SquadaOneText>
      </View>
      <View style={styles.inputContainer}>
        <TextInput
          adjustsFontSizeToFit
          numberOfLines={1}
          keyboardType={"phone-pad"}
          underlineColorAndroid={"transparent"}
          inputStyle={styles.input}
          containerStyle={styles.inputContainer}
          inputRef={awayPredictionInput}
          value={predictions[currentFixture]?.["awayScore"] ?? ""}
          onChangeText={(val) => {
            handleBetValueInput(
              val.slice(val.length - 1),
              "awayScore",
              "homeScore"
            );
          }}
        />
      </View>
    </View>
  );
};
export default ScoreInput;

const styles = StyleSheet.create({
  predictionContainer: {
    flexDirection: "row",
  },
  inputContainer: {
    width: hp(8),
    height: hp(8),
  },
  input: {
    width: "100%",
    height: "100%",
    fontSize: hp(5),
    fontFamily: "SquadaOne-Regular",
    color: Config.Color.DFM_YELLOW,
    textAlign: "center",
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
  },
  inputReadonly: {
    width: "100%",
    height: "100%",
    fontSize: hp(5),
    fontFamily: "SquadaOne-Regular",
    textAlign: "center",
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    backgroundColor: Config.Color.GREY_67,
    color: Config.Color.DFM_DARK_PURPLE,
  },
  separatorContainer: {
    height: hp(8),
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
    paddingHorizontal: 5,
  },
  separator: {
    fontSize: hp(5),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },
});
