import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { gql, useQuery } from "@apollo/client";
import { FixtureClashesList, Loading, TabList } from "../components";
import Timeline from "../components/fixture-components/Timeline";
import { Config } from "../config";
import { hp } from "../dimensions";
import { FixtureStatus } from "../types/globalTypes";
import { Redirect } from "../router";
import { logScreenView } from "../firebase";
import EmptyListMessage from "../components/EmptyListMessage";
import Header from "../components/fixture-components/Header";

const GET_FIXTURE = gql`
  query GetFixture($id: [ID]!) {
    fixtures(id: $id) {
      id
      status
      date
      updatedAt
      score {
        homeTeam
        awayTeam
      }
      competition {
        id
        caption
        league {
          name
          logoUrl
        }
      }
      homeTeam {
        id
        name
        logo
      }
      awayTeam {
        id
        name
        logo
      }
    }
  }
`;

export default function FixtureScreen(props) {
  const { match } = props;
  const { params, path } = match;
  const [selected, setSelected] = useState(
    path === "/match/:id/timeline" ? 1 : 0
  );

  const { data, loading } = useQuery(GET_FIXTURE, {
    variables: { id: params.id },
  });

  React.useEffect(() => {
    let screenName =
      `match_${Config.FIXTURE_SCREEN_TABS[selected]}`.toLowerCase();
    logScreenView(screenName);
  }, [selected]);

  if (loading) return <Loading />;

  const fixture = data?.fixtures[0];
  if (!fixture) return <Redirect to="/" />;

  return (
    <View style={styles.gameContainer}>
      <View style={styles.headerContainer}>
        <Header fixtureId={fixture.id} variant={"fixture_page"} />
      </View>
      <TabList
        tabs={Config.FIXTURE_SCREEN_TABS}
        selected={selected}
        setSelected={setSelected}
      />
      <View style={{ height: "68%", width: "100%" }}>
        {
          [
            <FixtureClashesList {...props} fixture={fixture} />,
            [FixtureStatus.NOT_STARTED, FixtureStatus.TO_BE_ANNOUNCED].includes(
              fixture.status
            ) ? (
              <View style={styles.emptyMessageContainer}>
                <EmptyListMessage code={"MATCH_NOT_STARTED"} />
              </View>
            ) : (
              <Timeline fixture={fixture} />
            ),
          ][selected]
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  gameContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  headerContainer: {
    height: "25%",
    width: "100%",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    borderTopWidth: 1,
    borderTopColor: Config.Color.WHITE,
  },
  message: {
    fontSize: hp(2.5),
    textAlign: "center",
    textAlignVertical: "center",
    paddingVertical: "25%",
    color: Config.Color.GREY,
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: Config.Color.GREY,
  },
  periodText: {
    textAlign: "center",
    color: Config.Color.GREY,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  emptyMessageContainer: {
    width: "95%",
    marginLeft: "2.5%",
  },
});
