import React from "react";
import { StyleSheet } from "react-native";
import { Button } from "react-native-elements";
import { Config } from "../config";
import { hp } from "../dimensions";

const ActionButton = ({
  containerStyle,
  buttonStyle,
  disabledStyle,
  titleStyle,
  disabledTitleStyle,
  iconContainerStyle,
  variant,
  ...props
}) => {
  let _buttonStyle, _titleStyle;

  switch (variant) {
    case "link":
      _buttonStyle = styles.buttonLink;
      _titleStyle = styles.titleLink;
      break;
    case "secondary":
      _buttonStyle = styles.buttonSecondary;
      _titleStyle = styles.titleSecondary;
      break;
    case "primary":
    default:
      _buttonStyle = styles.buttonPrimary;
      _titleStyle = styles.titlePrimary;
      break;
  }

  return (
    <Button
      {...props}
      containerStyle={[styles.container, containerStyle]}
      buttonStyle={[_buttonStyle, buttonStyle]}
      titleStyle={[_titleStyle, titleStyle]}
      disabledStyle={disabledStyle}
      disabledTitleStyle={disabledTitleStyle}
      iconContainerStyle={[iconContainerStyle]}
    />
  );
};

const styles = StyleSheet.create({
  container: { justifyContent: "center", width: "40%", borderRadius: 0 },
  buttonPrimary: {
    backgroundColor: Config.Color.DFM_GREEN,
    height: hp(6),
    borderRadius: 0,
  },
  titlePrimary: {
    fontFamily: "SquadaOne-Regular",
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
  },
  // PREVIOUS BUTTON
  buttonSecondary: {
    height: hp(6),
    borderWidth: 2,
    borderRadius: 0,
    borderColor: Config.Color.DFM_GREEN,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  titleSecondary: {
    fontFamily: "SquadaOne-Regular",
    color: Config.Color.DFM_GREEN,
    fontSize: hp(2),
  },
  // Link
  buttonLink: {
    backgroundColor: "transparent",
  },
  titleLink: {
    fontFamily: "Roboto-Regular",
    color: Config.Color.DFM_GREEN,
    textDecorationLine: "underline",
    fontSize: hp(1.8),
  },
});

export default ActionButton;
