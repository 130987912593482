import React, { useEffect, useState } from "react";
import { Config, NULL_ADDRESS } from "../config";
import {
  ClashPredictionsOverlay,
  ConfirmTransactionOverlay,
  CongratulationsOverlay,
  ErrorOverlay,
  LoadingOverlay,
  MessageOverlay,
} from "../overlays";
import { ErrorMessages } from "../overlays/ErrorOverlay";
import { getWeb3 } from "../web3";
import { sendMetaTransaction } from "../web3/biconomy";
import {
  approveInfinitelyToken,
  getEstimatedGas,
  getTokenInst,
  infuraWeb3Struct,
  populateAddScoresTx,
  populateSubmitPredictionTx,
  sendTransaction,
} from "../web3/utils";
import { messaging } from "../firebase";
import { getScoresTopics } from "../firebase/utils";

const Tokens = Config.Referee.Tokens;

const JoinClashWizard = ({
  canProceed,
  clash,
  myPredictions,
  submitPrediction,
  setRefreshPredictions,
  selectedFixtureToScore,
  toJoinClash,
  toggleToJoinClash,
  userStruct,
}) => {
  const [balances, setBalances] = useState(null);
  const [allowance, setAllowance] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [estimatedGas, setEstimatedGas] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [predictions, setPredictions] = useState(
    new Array(clash.fixturesIds.length).fill(undefined)
  );
  const [isAllowance, setIsAllowance] = useState(false);
  const [txSubmittingMessageIndex, setTxSubmittingMessageIndex] = useState(-1);
  const [errorOverlayMessage, setErrorOverlayMessage] = useState(null);

  const canPAYG = Config.FEATURE_FLAG_PAYG;
  const { account, wallet } = userStruct;

  useEffect(() => {
    if (clash && account) {
      if (Config.FEATURE_FLAG_ALLOWANCE) {
        if (Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN] !== undefined) {
          let tokenAddress = Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN].address;
          if (account) {
            infuraWeb3Struct.refereeAllowanceVaultInst.methods
              .allowanceOf(tokenAddress, account)
              .call()
              .then((allowance) => {
                setAllowance(allowance);
                if (
                  tokenAddress === clash.tokenAddress &&
                  Number(allowance) >= clash.amount
                ) {
                  setIsAllowance(true);
                }
              });
          }
        }
      }

      const getBalances = async () => {
        //Two positions: 1st for native token's balance and 2nd for the balance of the token used on this specific clash
        const balances = new Array(2);
        balances[0] = Number(
          await infuraWeb3Struct.web3.eth.getBalance(account)
        );
        if (clash.tokenAddress !== NULL_ADDRESS) {
          balances[1] = Number(
            await getTokenInst(
              infuraWeb3Struct.web3,
              Tokens.BY_ADDRESS[clash.tokenAddress]
            )
              .methods.balanceOf(account)
              .call()
          );
        } else {
          //2nd position is also for native token's balance since it's the token used on this specific clash
          balances[1] = balances[0];
        }
        return balances;
      };
      getBalances().then(setBalances);
    }
  }, [clash, account]);

  useEffect(() => {
    if (
      txSubmittingMessageIndex >= 0 &&
      txSubmittingMessageIndex < Config.TX_SUBMITTING_MSGS.length - 1
    ) {
      const intervalID = setInterval(
        () => setTxSubmittingMessageIndex((i) => i + 1),
        Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex].interval
      );
      return () => clearInterval(intervalID);
    }
  }, [txSubmittingMessageIndex]);

  if (!toJoinClash) return null;

  const exit = () => {
    toggleToJoinClash();
    setCurrentStep(0);
    setIsSubmitting(false);
    setPredictions(new Array(clash.fixturesIds.length).fill(undefined));
    setIsAllowance(false);
    setTxSubmittingMessageIndex(-1);
    setIsLoading(false);
    setErrorOverlayMessage(null);
  };

  const proceedPredictions = async () => {
    setCurrentStep((s) => s + 1);
    const { amount, id, tokenAddress } = clash;
    const gasProps = { from: account };
    let tx;
    let isJoiningLeague = !myPredictions;
    if (isJoiningLeague) {
      tx = populateSubmitPredictionTx(
        tokenAddress,
        id,
        new Array(clash.fixturesIds.length).fill({
          homeScore: Config.PREDICTION_NOT_ADDED,
          awayScore: Config.PREDICTION_NOT_ADDED,
        }),
        isAllowance
      );
      if (tokenAddress === NULL_ADDRESS) {
        gasProps["value"] = amount;
      } else {
        if (!isAllowance) {
          setIsLoading(true);
          const { account, wallet } = userStruct;
          await approveInfinitelyToken(
            account,
            tokenAddress,
            amount,
            getWeb3(wallet)
          ).catch((error) => {
            console.error(error);
            setErrorOverlayMessage(ErrorMessages.NO_NATIVE_TOKEN);
          });
          setIsLoading(false);
        }
      }
    } else {
      let matchesIndexes = [];
      predictions.forEach((scores, index) => {
        if (scores !== undefined) {
          matchesIndexes.push(index);
        }
      });

      tx = populateAddScoresTx(
        myPredictions.id,
        matchesIndexes,
        predictions.filter((scores) => scores !== undefined)
      );
    }
    getEstimatedGas(tx, gasProps)
      .then(setEstimatedGas)
      .catch((error) => {
        console.error(error);
        setErrorOverlayMessage(ErrorMessages.DEFAULT);
      });
  };

  const submitPredictions = () => {
    predictions.map((p, i) => {
      if (p === undefined) {
        predictions[i] = {
          homeScore: Config.PREDICTION_NOT_ADDED,
          awayScore: Config.PREDICTION_NOT_ADDED,
        };
      }
      return predictions;
    });

    setIsSubmitting(true);
    setTxSubmittingMessageIndex(0);

    const { amount, fixturesIds, id, title, tokenAddress } = clash;

    let isJoiningLeague = !myPredictions;
    if (isJoiningLeague) {
      submitPrediction(
        id,
        tokenAddress,
        amount,
        fixturesIds,
        predictions,
        title,
        //We support the gas costs of every tx at this moment, therefore the 'true' invariably
        true,
        isAllowance
      )
        .then(() => {
          setTxSubmittingMessageIndex(-1);
          setIsSubmitting(false);
          setCurrentStep((s) => s + 1);
        })
        .catch((error) => {
          console.error(error);
          setErrorOverlayMessage(ErrorMessages.DEFAULT);
        });
    } else {
      const matchesIndexes = [],
        newPredictions = [];
      predictions.forEach((p, i) => {
        if (
          p?.homeScore !== Config.PREDICTION_NOT_ADDED &&
          p?.awayScore !== Config.PREDICTION_NOT_ADDED
        ) {
          newPredictions.push(p);
          matchesIndexes.push(i);
        }
      });
      const tx = populateAddScoresTx(
        myPredictions.id,
        matchesIndexes,
        newPredictions
      );

      //We support the gas costs of every tx at this moment, therefore the 'true' invariably
      const isMetaTx = true;
      let sendMethod;
      if (isMetaTx) {
        sendMethod = sendMetaTransaction(
          tx,
          "Referee_AddScores",
          account,
          Config.Referee.ADDRESS,
          getWeb3(wallet)
        );
      } else {
        sendMethod = sendTransaction(
          tx,
          Config.Referee.ADDRESS,
          account,
          undefined,
          getWeb3(wallet)
        );
      }

      sendMethod
        .then(() => {
          setIsSubmitting(false);
          setCurrentStep((s) => s + 1);
          if (messaging) {
            getScoresTopics(fixturesIds, predictions).forEach((topic) =>
              messaging().subscribeToTopic(topic)
            );
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorOverlayMessage(ErrorMessages.DEFAULT);
        });
    }
  };

  const MakePredictions = () => {
    let trackingScreenName = !myPredictions
      ? "join_league_predictions"
      : "add_scores_predictions";

    return (
      <ClashPredictionsOverlay
        exit={exit}
        fixtures={clash.fixtures}
        isVisible={toJoinClash}
        next={proceedPredictions}
        predictions={predictions}
        setPredictions={setPredictions}
        canPAYG={canPAYG}
        trackingScreenName={trackingScreenName}
        myPredictions={myPredictions}
        selectedFixtureToScore={selectedFixtureToScore}
      />
    );
  };

  const ConfirmTransaction = () => {
    let isJoining = !myPredictions;
    let trackingScreenName = isJoining
      ? "join_league_confirmation"
      : "add_scores_confirmation";

    const { amount, title, tokenAddress, isListed } = clash;

    return (
      <ConfirmTransactionOverlay
        confirmTransaction={submitPredictions}
        estimatedGas={estimatedGas}
        exit={exit}
        isVisible
        prev={() => setCurrentStep((s) => s - 1)}
        ticketPrice={amount}
        token={Tokens.BY_ADDRESS[tokenAddress]}
        title={title}
        trackingScreenName={trackingScreenName}
        predictions={predictions}
        fixtures={clash.fixtures}
        isJoiningOrCreating={isJoining}
        isAllowance={isAllowance}
        isListed={isListed}
      />
    );
  };

  const ClashCreated = () => {
    let trackingScreenName = "add_scores_success";
    let text = "You added new score predictions.";
    if (!myPredictions) {
      trackingScreenName = "join_league_success";
      text = 'You joined the league "' + clash.title + '"';
    }

    setRefreshPredictions(true);
    return (
      <CongratulationsOverlay
        isVisible
        exit={exit}
        text={text}
        trackingScreenName={trackingScreenName}
      />
    );
  };

  const steps = [MakePredictions, ConfirmTransaction, ClashCreated];

  let isJoining = !myPredictions;
  let isLeagueTokenSameAsAllowance =
    Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN].address === clash.tokenAddress;
  let canUseAllowanceToJoin =
    isLeagueTokenSameAsAllowance && Number(allowance) !== 0;
  let canUseWalletBalanceToJoin =
    balances && Number(balances[1]) && Number(balances[1]) !== 0;

  if (Boolean(errorOverlayMessage)) {
    return <ErrorOverlay exit={exit} isVisible text={errorOverlayMessage} />;
  } else if (
    isJoining &&
    !canUseAllowanceToJoin &&
    !canUseWalletBalanceToJoin
  ) {
    return (
      <ErrorOverlay
        exit={exit}
        isVisible
        text={ErrorMessages.INSUFFICIENT_FUNDS}
      />
    );
  } else if (canProceed !== undefined && !canProceed) {
    return (
      <MessageOverlay
        isVisible
        exit={exit}
        imageSource={require("../assets/wallet-yellow.png")}
        trackingScreenName={"must_use_same_wallet_to_score"}
        buttonText="CLOSE"
        title="Change Wallet"
        text="The wallet used to add scores must be the same one used to create the league."
      />
    );
  } else if (isLoading || !balances) {
    return (
      <LoadingOverlay
        exit={exit}
        isVisible
        loadingColor={Config.Color.DFM_PINK}
      />
    );
  } else if (
    currentStep === steps.indexOf(ConfirmTransaction) &&
    myPredictions &&
    predictions.filter((scores) => scores !== undefined).length === 0
  ) {
    return (
      <MessageOverlay
        isVisible
        action={() => setCurrentStep((s) => s - 1)}
        title="SCORE PREDICTIONS NOT ADDED"
        text={"You haven't added any score predictions."}
        imageSource={require("../assets/error-light-purple.png")}
        buttonText="GO BACK"
        trackingScreenName={"trying_to_add_no_scores"}
      />
    );
  } else if (
    currentStep === steps.indexOf(ConfirmTransaction) &&
    (isSubmitting || !estimatedGas)
  ) {
    return (
      <LoadingOverlay
        exit={exit}
        isVisible
        loadingColor={Config.Color.DFM_PINK}
        title="CONFIRM TRANSACTION"
        text={
          isSubmitting
            ? Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex]?.text
            : " "
        }
      />
    );
  }

  return steps[currentStep]();
};

export default JoinClashWizard;
