import React, { useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { gql, useQuery } from "@apollo/client";
import {
  Loading,
  TabList,
  SquadaOneText,
  FollowButton,
  RobotoText,
  LeaguesList,
  WalletInfo,
  Settings,
} from "../components";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { logScreenView } from "../firebase";
import { UsersListOverlay } from "../overlays";
import { fetchUserPredictions } from "../web3/utils";

function LogScreen({ screenName }) {
  React.useEffect(() => {
    logScreenView(screenName);
  }, [screenName]);

  return null;
}

const GET_USER = gql`
  query GetProfileUser($username: [String]) {
    users(username: $username) {
      id
      username
      photo
      email
      followers {
        username
      }
      following {
        username
      }
      wallets {
        publicAddress
        provider
      }
    }
  }
`;

export default function UserScreen(props) {
  const [isFollowersSelected, setIsFollowersSelected] = useState(false);
  const [isFollowingSelected, setIsFollowingSelected] = useState(false);
  const [isTopUpSelected, setIsTopUpSelected] = useState(false);
  const [selected, setSelected] = useState(0);
  const [predictions, setPredictions] = useState(null);
  const [walletsToDisplay, setWalletsToDisplay] = useState(null);
  const { match, userStruct, history } = props;
  const { username } = match.params;

  const { initialLoading, account } = userStruct;
  const isInitialLoading = Boolean(initialLoading);

  const { data, loading } = useQuery(GET_USER, {
    variables: { username: username },
  });

  const logout = () => {
    const { logout } = props;
    history.push("/");
    logout();
  };

  useEffect(() => {
    const user = data?.users[0];
    if (user) {
      const { wallets } = user;

      if (wallets.length > 0) {
        fetchUserPredictions(wallets.map((w) => w.publicAddress)).then(
          setPredictions
        );
      }

      if (account && wallets.length > 0) {
        setWalletsToDisplay(
          wallets
            .filter((w) => w.publicAddress === account)
            .concat(wallets.filter((w) => w.publicAddress !== account))
        );
      }
    }
  }, [account, data]);

  React.useEffect(() => {
    let screenName = `user_${Config.USER_SCREEN_TABS[selected]}`.toLowerCase();
    logScreenView(screenName);
  }, [selected]);

  if ((isInitialLoading && !account) || loading) return <Loading />;

  if (data.users[0] === undefined) {
    history.push("/");
    return null;
  }

  const user = data.users[0];
  const { followers, following } = user;

  const isUserVisitingOwnProfile = userStruct.username === username;

  if (!predictions) return <Loading />;

  const userSocialInfo = [
    {
      title: "LEAGUES",
      value: predictions.length,
    },
    {
      title: "FOLLOWERS",
      value: followers.length,
      onPressAction: () => setIsFollowersSelected(!isFollowersSelected),
    },
    {
      title: "FOLLOWING",
      value: following.length,
      onPressAction: () => setIsFollowingSelected(!isFollowingSelected),
    },
  ];

  const MyProfileContent = () => {
    return (
      <View style={styles.mainContentContainer}>
        <TabList
          tabs={Config.MY_PROFILE_SCREEN_TABS}
          selected={selected}
          setSelected={setSelected}
        />
        <View
          style={styles.tabsContentContainer}
          onMoveShouldSetResponderCapture={
            Platform.OS === "web" ? () => true : undefined
          }
        >
          {
            {
              0: (
                <FlatList
                  data={walletsToDisplay}
                  renderItem={({ item }) => (
                    <WalletInfo userStruct={userStruct} wallet={item} />
                  )}
                  keyExtractor={(item, index) => index.toString()}
                  showsVerticalScrollIndicator={false}
                />
              ),
              1: (
                <Settings
                  logout={logout}
                  username={username}
                  walletAddress={account}
                  isTopUpSelected={isTopUpSelected}
                  setIsTopUpSelected={setIsTopUpSelected}
                />
              ),
            }[selected]
          }
        </View>
      </View>
    );
  };

  const OtherProfileContent = () => {
    return (
      <View style={styles.mainContentContainer}>
        <TabList
          tabs={Config.USER_SCREEN_TABS}
          selected={selected}
          setSelected={setSelected}
        />
        <View
          style={styles.tabsContentContainer}
          onMoveShouldSetResponderCapture={
            Platform.OS === "web" ? () => true : undefined
          }
        >
          {
            {
              0: (
                <LeaguesList
                  username={username}
                  userStruct={userStruct}
                  variant={"on_going"}
                />
              ),
              1: (
                <LeaguesList
                  username={username}
                  userStruct={userStruct}
                  variant={"history"}
                />
              ),
            }[selected]
          }
        </View>
      </View>
    );
  };

  return (
    <>
      <View style={styles.pageContainer}>
        <View style={styles.userHeaderContainer}>
          <View style={styles.userContainer}>
            <SquadaOneText adjustsFontSizeToFit numberOfLines={1} variant="h1">
              {username}
            </SquadaOneText>
            {Boolean(isUserVisitingOwnProfile) && (
              <RobotoText variant={"medium"}>{user.email}</RobotoText>
            )}
          </View>

          {!isUserVisitingOwnProfile && (
            <View style={styles.followButtonContainer}>
              <FollowButton
                loggedInUserStruct={userStruct}
                followers={followers}
                props={props}
                otherUsername={username}
                otherUser={user}
              />
            </View>
          )}
        </View>
        <View style={styles.socialContainer}>
          {userSocialInfo.map(({ title, value, onPressAction }) => (
            <TouchableOpacity
              onPress={onPressAction}
              key={title}
              style={styles.socialInfoContainer}
            >
              <View style={styles.socialInfoHeader}>
                <SquadaOneText
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  variant="profile_counter_titles"
                >
                  {title}
                </SquadaOneText>
              </View>
              <View style={styles.socialInfoContent}>
                <SquadaOneText
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  variant="profile_counters"
                >
                  {value}
                </SquadaOneText>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        {!Boolean(isUserVisitingOwnProfile) && <OtherProfileContent />}
        {Boolean(isUserVisitingOwnProfile) && <MyProfileContent />}
        <UsersListOverlay
          isVisible={isFollowersSelected}
          exit={() => setIsFollowersSelected(false)}
          title={"FOLLOWERS"}
          users={followers}
          history={props.history}
          userStruct={userStruct}
          props={props}
          username={username}
        />
        <UsersListOverlay
          isVisible={isFollowingSelected}
          exit={() => setIsFollowingSelected(false)}
          title={"FOLLOWING"}
          users={following}
          history={props.history}
          userStruct={userStruct}
          props={props}
          username={username}
        />
      </View>
      <LogScreen
        screenName={isUserVisitingOwnProfile ? "my_profile" : "other_profile"}
      />
    </>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    height: "100%",
    width: "100%",
  },
  mainContentContainer: {
    height: "75%",
  },
  tabsContentContainer: {
    width: "95%",
    marginLeft: "2.5%",
    height: "93%",
    paddingBottom: "2.5%",
  },
  userContainer: {
    alignItems: "flex-start",
    width: "70%",
  },
  userHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: "10%",
    width: Dimensions.MAX_WIDTH,
    paddingHorizontal: "2.5%",
    justifyContent: "space-between",
    backgroundColor: Config.Color.LIGHT_GREY,
  },
  followButtonContainer: {
    alignItems: "flex-end",
    width: "30%",
  },
  socialContainer: {
    backgroundColor: Config.Color.LIGHT_GREY,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "15%",
    paddingHorizontal: "2.5%",
  },
  socialInfoContainer: {
    width: "30%",
    height: "80%",
    borderWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    backgroundColor: Config.Color.WHITE,
    flexDirection: "column",
  },
  socialInfoHeader: {
    height: "30%",
    width: "100%",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    alignItems: "center",
    justifyContent: "center",
  },
  socialInfoContent: {
    height: "70%",
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    alignItems: "center",
    justifyContent: "center",
  },
});
