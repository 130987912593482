import React, {useEffect, useState} from 'react';

const Wizard = React.forwardRef(
    (
        {
            steps,
            activeStep = 0,
            currentStep = () => {},
            isFirstStep = () => {},
            isLastStep = () => {},
            onNext = () => {},
            onPrev = () => {},
        },
        ref
    ) => {
        const [activeStepNo, setActiveStepNo] = useState(activeStep)
        ref.current = {
            next: () => {
                if (steps.length - 1 !== activeStepNo) {
                    setActiveStepNo(activeStepNo + 1)
                    currentStep({
                        currentStep: activeStepNo + 1,
                        isFirstStep: activeStepNo + 1 === 0,
                        isLastStep: activeStepNo + 1 === steps.length - 1,
                    })
                    onNext()
                }
            },
            prev: () => {
                if (activeStepNo > 0) {
                    setActiveStepNo(activeStepNo - 1)
                    currentStep({
                        currentStep: activeStepNo - 1,
                        isFirstStep: activeStepNo - 1 === 0,
                        isLastStep: activeStepNo - 1 === steps.length - 1,
                    })
                    onPrev()
                }
            },
            goTo: step => {
                if (steps.length - 1 <= step || step >= 0) {
                    if (activeStepNo > step) {
                        onPrev()
                    } else {
                        onNext()
                    }
                    currentStep({currentStep: step, isFirstStep: step === 0, isLastStep: step !== 0})
                    setActiveStepNo(step)
                }
            },
        }

        useEffect(() => {
            currentStep({
                currentStep: activeStepNo,
                isFirstStep: activeStepNo === 0,
                isLastStep: activeStepNo === steps.length - 1,
            })
        }, [activeStepNo, steps.length, currentStep])

        useEffect(() => {
            isFirstStep(activeStepNo === 0)
            isLastStep(activeStepNo === steps.length - 1)
        }, [activeStepNo, steps.length, isFirstStep, isLastStep])
        return (
            steps[activeStepNo].content
        )
    }
);
export default Wizard;