import React from "react";
import { Picker } from "@react-native-picker/picker";

const Dropdown = ({ items, selectItem, selectedItem, style, textStyle }) => {
  return (
    <Picker
      mode="dropdown"
      style={style}
      itemStyle={textStyle}
      selectedValue={selectedItem}
      onValueChange={selectItem}
    >
      {items.map((item, index) => (
        <Picker.Item key={index} label={item.caption} value={index} />
      ))}
    </Picker>
  );
};

export default Dropdown;
