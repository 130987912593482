import React, { useEffect, useState } from "react";
import { default as TransakSDK } from "@transak/transak-sdk";
import TransakConfig from "./config";
import getConversionPrice from "./getConversionPrice";
import getNetworkCaption from "./getNetworkCaption";
import { logEvent } from "../../firebase";
import { EVENTS } from "../../firebase/events";

//Required field in web context
TransakConfig.hostURL = window.location.origin;

const Transak = React.forwardRef(({ account, setIsTopUpCompleted }, ref) => {
  const [transak, setTransak] = useState(null);

  ref.current = {
    init: () => transak?.init(),
  };

  useEffect(() => {
    if (account) {
      TransakConfig.walletAddress = account;
      const transak = new TransakSDK(TransakConfig);

      // To get all the events
      transak.on(transak.ALL_EVENTS, (data) => {
        logEvent(data.eventName);
        console.log(data);
      });

      // This will trigger when the user closed the widget
      transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
        transak.close();
      });

      transak.on(transak.EVENTS.TRANSAK_ORDER_FAILED, () => {
        logEvent(EVENTS.TOP_UP_WALLET_ERROR);
      });

      // This will trigger when the user marks payment is made.
      transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
        transak.close();
        logEvent(EVENTS.TOP_UP_WALLET_SUCCESS);
        setIsTopUpCompleted(true);
      });

      setTransak(transak);
    }
  }, [account, setIsTopUpCompleted]);

  return null;
});
export default Transak;

const API = {
  getConversionPrice,
  getNetworkCaption,
};

export { API };
