import { Config } from "../../config";

export class BiconomyConfig {
  static baseURL = "https://api.biconomy.io";
  static apiKey = {
    80001: "65x3w-OuA.7c042c61-6562-4efb-a462-39e8598e000d",
    137: "YxiEboZJP.c6626328-9d19-47b5-8bdb-635de7174077",
  }[Config.NETWORK.chainId];
  static methodApiId = {
    80001: {
      Referee_AddScores: "bc87bd90-046d-4b87-bc73-3d7811946b5d",
      Referee_CreateClash: "70903606-40b2-4bf4-8b0a-2e739e5e2e4a",
      Referee_EnterClash: "111a3ad7-e9c0-4317-b007-363f54a309d4",
      RefereeAllowanceVault_CreateClash: "1f898906-7832-45d9-a5a8-a2c2196ce325",
      RefereeAllowanceVault_EnterClash: "77158998-ad1e-47d4-b990-53c3c34b7c69",
    },
    137: {
      Referee_AddScores: "91ee9218-1798-43d6-aaef-cb5acfb9ca6b",
      Referee_CreateClash: "9d997468-b548-4983-9fdf-1c83fd071ccb",
      Referee_EnterClash: "d595ab07-d262-41d8-996b-4302e4a8c65c",
      RefereeAllowanceVault_CreateClash: "4d75ba26-9555-4e41-82d2-3d3d202e042e",
      RefereeAllowanceVault_EnterClash: "76d021a5-3f69-4f1b-9149-d6dbf7e966f6",
      USDC_ExecuteMetaTransaction: "6729e717-d6c8-4127-9e64-1f8853d68e5a",
      USDC_Permit: "f19501ae-fd8e-419c-9742-2a9669412cbc",
    },
  }[Config.NETWORK.chainId];
  static forwarderAddress = {
    80001: "0x69015912AA33720b842dCD6aC059Ed623F28d9f7",
    137: "0xf0511f123164602042ab2bCF02111fA5D3Fe97CD",
  }[Config.NETWORK.chainId];
  static forwarderAbi = [
    {
      inputs: [{ internalType: "address", name: "_owner", type: "address" }],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "bytes32",
          name: "domainSeparator",
          type: "bytes32",
        },
        {
          indexed: false,
          internalType: "bytes",
          name: "domainValue",
          type: "bytes",
        },
      ],
      name: "DomainRegistered",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      inputs: [],
      name: "EIP712_DOMAIN_TYPE",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "REQUEST_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      name: "domains",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            { internalType: "address", name: "to", type: "address" },
            {
              internalType: "address",
              name: "token",
              type: "address",
            },
            { internalType: "uint256", name: "txGas", type: "uint256" },
            {
              internalType: "uint256",
              name: "tokenGasPrice",
              type: "uint256",
            },
            { internalType: "uint256", name: "batchId", type: "uint256" },
            {
              internalType: "uint256",
              name: "batchNonce",
              type: "uint256",
            },
            { internalType: "uint256", name: "deadline", type: "uint256" },
            {
              internalType: "bytes",
              name: "data",
              type: "bytes",
            },
          ],
          internalType: "structERC20ForwardRequestTypes.ERC20ForwardRequest",
          name: "req",
          type: "tuple",
        },
        { internalType: "bytes32", name: "domainSeparator", type: "bytes32" },
        {
          internalType: "bytes",
          name: "sig",
          type: "bytes",
        },
      ],
      name: "executeEIP712",
      outputs: [
        { internalType: "bool", name: "success", type: "bool" },
        {
          internalType: "bytes",
          name: "ret",
          type: "bytes",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            { internalType: "address", name: "to", type: "address" },
            {
              internalType: "address",
              name: "token",
              type: "address",
            },
            { internalType: "uint256", name: "txGas", type: "uint256" },
            {
              internalType: "uint256",
              name: "tokenGasPrice",
              type: "uint256",
            },
            { internalType: "uint256", name: "batchId", type: "uint256" },
            {
              internalType: "uint256",
              name: "batchNonce",
              type: "uint256",
            },
            { internalType: "uint256", name: "deadline", type: "uint256" },
            {
              internalType: "bytes",
              name: "data",
              type: "bytes",
            },
          ],
          internalType: "structERC20ForwardRequestTypes.ERC20ForwardRequest",
          name: "req",
          type: "tuple",
        },
        { internalType: "bytes", name: "sig", type: "bytes" },
      ],
      name: "executePersonalSign",
      outputs: [
        { internalType: "bool", name: "success", type: "bool" },
        {
          internalType: "bytes",
          name: "ret",
          type: "bytes",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        {
          internalType: "uint256",
          name: "batchId",
          type: "uint256",
        },
      ],
      name: "getNonce",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "isOwner",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "name", type: "string" },
        {
          internalType: "string",
          name: "version",
          type: "string",
        },
      ],
      name: "registerDomainSeparator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            { internalType: "address", name: "to", type: "address" },
            {
              internalType: "address",
              name: "token",
              type: "address",
            },
            { internalType: "uint256", name: "txGas", type: "uint256" },
            {
              internalType: "uint256",
              name: "tokenGasPrice",
              type: "uint256",
            },
            { internalType: "uint256", name: "batchId", type: "uint256" },
            {
              internalType: "uint256",
              name: "batchNonce",
              type: "uint256",
            },
            { internalType: "uint256", name: "deadline", type: "uint256" },
            {
              internalType: "bytes",
              name: "data",
              type: "bytes",
            },
          ],
          internalType: "structERC20ForwardRequestTypes.ERC20ForwardRequest",
          name: "req",
          type: "tuple",
        },
        { internalType: "bytes32", name: "domainSeparator", type: "bytes32" },
        {
          internalType: "bytes",
          name: "sig",
          type: "bytes",
        },
      ],
      name: "verifyEIP712",
      outputs: [],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: "address",
              name: "from",
              type: "address",
            },
            { internalType: "address", name: "to", type: "address" },
            {
              internalType: "address",
              name: "token",
              type: "address",
            },
            { internalType: "uint256", name: "txGas", type: "uint256" },
            {
              internalType: "uint256",
              name: "tokenGasPrice",
              type: "uint256",
            },
            { internalType: "uint256", name: "batchId", type: "uint256" },
            {
              internalType: "uint256",
              name: "batchNonce",
              type: "uint256",
            },
            { internalType: "uint256", name: "deadline", type: "uint256" },
            {
              internalType: "bytes",
              name: "data",
              type: "bytes",
            },
          ],
          internalType: "structERC20ForwardRequestTypes.ERC20ForwardRequest",
          name: "req",
          type: "tuple",
        },
        { internalType: "bytes", name: "sig", type: "bytes" },
      ],
      name: "verifyPersonalSign",
      outputs: [],
      stateMutability: "view",
      type: "function",
    },
  ];
  static biconomyForwarderDomainData = {
    name: "Biconomy Forwarder",
    version: "1",
  };
  static domainType = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "verifyingContract", type: "address" },
    { name: "salt", type: "bytes32" },
  ];
  static forwardRequestType = [
    { name: "from", type: "address" },
    { name: "to", type: "address" },
    { name: "token", type: "address" },
    { name: "txGas", type: "uint256" },
    { name: "tokenGasPrice", type: "uint256" },
    { name: "batchId", type: "uint256" },
    { name: "batchNonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
    { name: "data", type: "bytes" },
  ];
}
