import React from "react";
import { StyleSheet } from "react-native";
import { Input } from "react-native-elements";
import { Config } from "../../config";
import { hp } from "../../dimensions";

const TextInput = ({
  inputRef,
  value,
  placeholder,
  onChangeText,
  inputStyle,
  containerStyle,
  keyboardType,
}) => {
  return (
    <Input
      keyboardType={keyboardType}
      ref={inputRef}
      value={value}
      placeholder={placeholder}
      onChangeText={onChangeText}
      style={[styles.input, { outline: "none" }, inputStyle]}
      containerStyle={styles.container}
      inputContainerStyle={[styles.inputContainer, containerStyle]}
      errorStyle={styles.errorStyle}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontFamily: "Roboto-Regular",
    fontSize: hp(2),
  },
  inputContainer: {
    backgroundColor: Config.Color.WHITE,
    height: hp(6),
    paddingHorizontal: "5%",
    borderWidth: 0,
  },
  container: {
    borderWidth: 0,
    paddingHorizontal: 0,
  },
  errorStyle: { margin: 0 },
});

export default TextInput;
