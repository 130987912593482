export default function getConversionPrice(
  cryptoCurrency = "ETH",
) {
  return fetch(
    `https://api.coinbase.com/v2/prices/${cryptoCurrency}-USD/buy`,
    {
      method: "GET",
    }
  )
    .then((r) => r.json())
    .then((json) => json.data.amount)
    .catch((err) => {
      console.log(err);
      return null;
    });
}
