import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { ActionButton, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { Frame } from "./shared";
import RulesList from "../components/RulesList";

//TODO: get a more elegant solution
const rules = [
  () => "The leaderboard is updated every time a match ends.",
  () => "Points are calculated at the end of 90’ or 120’ when applicable.",
  () => (
    <Text>
      <SquadaOneText style={styles.rulesTextHighlighted}>
        3 points
      </SquadaOneText>{" "}
      are awarded when the exact score is correct.
    </Text>
  ),
  () => (
    <Text>
      <SquadaOneText style={styles.rulesTextHighlighted}>
        1 point{" "}
      </SquadaOneText>{" "}
      is awarded when the tendency is correct.
    </Text>
  ),
  () => (
    <Text>
      <SquadaOneText style={styles.rulesTextHighlighted}>
        0 points{" "}
      </SquadaOneText>
      are awarded when nor the score nor the tendency are correct.
    </Text>
  ),
  () =>
    "In the case of all players having the same points at the end of the match, the ticket league entry value will be reverted to each player account.",
  () =>
    "In the case of having more than one winner, the league prize will be distributed equally to the winners.",
];

const RulesOverlay = ({ exit, isVisible }) => {
  return (
    <Frame
      isVisible={isVisible}
      headerText="LEADERBOARD RULES"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <RulesList list={rules} />
        </View>
        <View style={styles.footerView}>
          <ActionButton onPress={exit} title="CLOSE" />
        </View>
      </ScrollView>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  rulesTextHighlighted: {
    fontSize: hp(2.3),
    color: Config.Color.WHITE,
    textDecorationLine: "underline",
  },
});

export default RulesOverlay;
