import React from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { Overlay } from "react-native-elements";
import { SquadaOneText } from "../../components";
import { Config } from "../../config";
import Dimensions, { hp } from "../../dimensions";
import { Modal } from "../../modal";
import CloseButton from "./CloseButton";
import { logScreenView } from "../../firebase";

const Frame = ({
  children,
  isVisible,
  headerText,
  onBackdropPress,
  onPressCloseButton,
  overlayStyle,
  backdropStyle,
  containerStyle,
  closeButtonContainerStyle,
  closeButtonTintColor,
  trackingScreenName,
}) => {
  React.useEffect(() => {
    if (isVisible && trackingScreenName) {
      logScreenView(trackingScreenName);
    }
  }, [isVisible, trackingScreenName]);

  return (
    <Overlay
      ModalComponent={Modal}
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      overlayStyle={[styles.overlay, overlayStyle]}
      backdropStyle={[styles.overlayBackdrop, backdropStyle]}
    >
      <SafeAreaView style={[styles.view, containerStyle]}>
        {(Boolean(onPressCloseButton) || Boolean(headerText)) && (
          <View style={styles.headerView}>
            {Boolean(onPressCloseButton) && (
              <CloseButton
                containerStyle={closeButtonContainerStyle}
                onPress={onPressCloseButton}
                tintColor={closeButtonTintColor}
              />
            )}
            {Boolean(headerText) && (
              <SquadaOneText
                adjustsFontSizeToFit
                numberOfLines={1}
                style={styles.headerText}
              >
                {headerText}
              </SquadaOneText>
            )}
          </View>
        )}
        {children}
      </SafeAreaView>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  overlay: {
    height: Dimensions.MAX_HEIGHT,
    width: Dimensions.MAX_WIDTH,
    backgroundColor: Config.Color.OVERLAY_BACKGROUND,
    borderRadius: 0,
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  overlayBackdrop: {
    backgroundColor: Config.Color.TRANSPARENT,
  },
  view: {
    height: "100%",
    width: "95%",
    alignItems: "center",
  },
  headerView: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "10%",
  },
  headerText: {
    fontSize: hp(3.6),
    color: Config.Color.DFM_YELLOW,
  },
});

export default Frame;
