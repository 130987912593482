import { gql } from "@apollo/client";
import { client } from "../../apollo";

const ADD_DEVICE = gql`
  mutation AddDevice($token: String!, $user: Int!) {
    addDevice(token: $token, user: $user) {
      user {
        username
        email
        wallets {
          provider
          publicAddress
        }
        devices {
          id
          token
        }
      }
    }
  }
`;

export async function addDevice(userId, deviceToken, publicAddress, authToken) {
  return client.mutate({
    mutation: ADD_DEVICE,
    variables: {
      token: deviceToken,
      user: parseInt(userId),
    },
    context: {
      headers: {
        "user-public-address": publicAddress,
        Authentication: "Bearer " + authToken,
      },
    },
  });
}
