import React, { useEffect, useRef, useState } from "react";
import { Config, NULL_ADDRESS } from "../config";
import {
  ClashPredictionsOverlay,
  ConfirmTransactionOverlay,
  CongratulationsOverlay,
  ErrorOverlay,
  LoadingOverlay,
  SelectFixturesOverlay,
  SetClashParamsOverlay,
} from "../overlays";
import { ErrorMessages } from "../overlays/ErrorOverlay";
import { getWeb3 } from "../web3";
import {
  approveInfinitelyToken,
  getEstimatedGas,
  infuraWeb3Struct,
  populateCreateClashTx,
} from "../web3/utils";

const Tokens = Config.Referee.Tokens;

const CreateClashWizard = ({
  competitions,
  createClash,
  fixedFixture,
  toCreateClash,
  toggleToCreateClash,
  userStruct,
  sourceScreen,
}) => {
  const [amount, setAmount] = useState(0);
  const [balances, setBalances] = useState(null);
  const [allowance, setAllowance] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [displayInsufficientFundsOverlay, setDisplayInsufficientFundsOverlay] =
    useState(false);
  const [estimatedGas, setEstimatedGas] = useState(null);
  const [fixtures, setFixtures] = useState(fixedFixture ? [fixedFixture] : []);
  const [predictions, setPredictions] = useState(null);
  const [title, setTitle] = useState("");
  const titleInput = useRef(null);
  const [tokenIndex, setTokenIndex] = useState(1); // Changed to 1 to make USDC default
  const [isListed, setIsListed] = useState(true);
  const [isAllowance, setIsAllowance] = useState(false);
  const [tokenAddress, setTokenAddress] = useState(
    Tokens.ALL[tokenIndex].address
  );
  const [isLoading, setIsLoading] = useState(false);
  const [txSubmittingMessageIndex, setTxSubmittingMessageIndex] = useState(-1);
  const [errorOverlayMessage, setErrorOverlayMessage] = useState(null);

  const canPAYG = Config.FEATURE_FLAG_PAYG;

  useEffect(() => {
    if (Config.FEATURE_FLAG_ALLOWANCE) {
      if (Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN] !== undefined) {
        let tokenAddress = Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN].address;
        if (userStruct.account) {
          infuraWeb3Struct.refereeAllowanceVaultInst.methods
            .allowanceOf(tokenAddress, userStruct.account)
            .call()
            .then((allowance) => {
              setAllowance(allowance);
            });
        }
      }
    }

    const fetchBalances = async (nativeTokenBalance) => {
      const promises = infuraWeb3Struct.tokensInsts.map((token) =>
        token.methods.balanceOf(userStruct.account).call()
      );
      return [nativeTokenBalance].concat(await Promise.all(promises));
    };
    if (userStruct.account) {
      infuraWeb3Struct.web3.eth
        .getBalance(userStruct.account)
        .then(fetchBalances)
        .then((balances) => {
          setBalances(balances);
        });
    }
  }, [userStruct.account, toggleToCreateClash]);

  useEffect(() => {
    if (
      txSubmittingMessageIndex >= 0 &&
      txSubmittingMessageIndex < Config.TX_SUBMITTING_MSGS.length - 1
    ) {
      const intervalID = setInterval(
        () => setTxSubmittingMessageIndex((i) => i + 1),
        Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex].interval
      );
      return () => clearInterval(intervalID);
    }
  }, [txSubmittingMessageIndex]);

  if (!toCreateClash) return null;

  const exit = () => {
    toggleToCreateClash();
    setAmount(0);
    setCurrentStep(0);
    setDisplayInsufficientFundsOverlay(false);
    setEstimatedGas(null);
    setFixtures(fixedFixture ? [fixedFixture] : []);
    setIsLoading(false);
    setPredictions(null);
    setTitle("");
    setIsListed(true);
    if (titleInput.current) titleInput.current.clear();
    setTokenIndex(1);
    setIsAllowance(false);
    setTxSubmittingMessageIndex(-1);
    setErrorOverlayMessage(null);
  };

  const submitFixtures = async (fixtures) => {
    setFixtures(fixtures);
    if (!predictions) {
      setPredictions(new Array(fixtures.length).fill(undefined));
    }
    const tx = populateCreateClashTx(
      tokenAddress,
      title,
      String(amount),
      isListed,
      fixtures.map((f) => f.id),
      new Array(fixtures.length).fill({
        homeScore: Config.PREDICTION_NOT_ADDED,
        awayScore: Config.PREDICTION_NOT_ADDED,
      }),
      isAllowance
    );
    const gasProps = { from: userStruct.account };
    if (tokenAddress === NULL_ADDRESS) {
      gasProps["value"] = amount;
    } else {
      if (!isAllowance) {
        setIsLoading(true);
        const { account, wallet } = userStruct;
        await approveInfinitelyToken(
          account,
          tokenAddress,
          amount,
          getWeb3(wallet)
        ).catch((error) => {
          console.error(error);
          setErrorOverlayMessage(ErrorMessages.NO_NATIVE_TOKEN);
        });
        setIsLoading(false);
      }
    }
    getEstimatedGas(tx, gasProps)
      .then(setEstimatedGas)
      .catch((error) => {
        console.error(error);
        setErrorOverlayMessage(ErrorMessages.DEFAULT);
      });
    setCurrentStep((s) => s + 1);
  };

  const callCreateClash = () => {
    predictions.map((p, i) => {
      if (p === undefined) {
        predictions[i] = {
          homeScore: Config.PREDICTION_NOT_ADDED,
          awayScore: Config.PREDICTION_NOT_ADDED,
        };
      }
      return predictions;
    });

    setTxSubmittingMessageIndex(0);
    setIsLoading(true);
    createClash(
      title,
      tokenAddress,
      String(amount),
      fixtures.map((f) => f.id),
      predictions,
      sourceScreen,
      isListed,
      //We support the gas costs of every tx at this moment, therefore the 'true' invariably
      true,
      isAllowance
    )
      .then(() => {
        setTxSubmittingMessageIndex(-1);
        setIsLoading(false);
        setCurrentStep((s) => s + 1);
      })
      .catch((error) => {
        console.error(error);
        setErrorOverlayMessage(ErrorMessages.DEFAULT);
      });
  };

  const SetClashParams = () => {
    return (
      <SetClashParamsOverlay
        amount={amount}
        balances={balances}
        exit={exit}
        isVisible={toCreateClash}
        setAmount={setAmount}
        setTitle={setTitle}
        setTokenIndex={setTokenIndex}
        submit={() => setCurrentStep((s) => s + 1)}
        title={title}
        titleInput={titleInput}
        tokenIndex={tokenIndex}
        userStruct={userStruct}
        showMessage={() => setDisplayInsufficientFundsOverlay(true)}
        trackingScreenName="new_league_parameters"
        isListed={isListed}
        setIsListed={setIsListed}
        isAllowance={isAllowance}
        setIsAllowance={setIsAllowance}
        allowance={allowance}
        setTokenAddress={setTokenAddress}
      />
    );
  };

  const SelectFixtures = () => {
    return (
      <SelectFixturesOverlay
        competitions={competitions}
        exit={exit}
        fixedFixture={fixedFixture}
        initialSelectedFixtures={fixtures}
        isVisible
        next={submitFixtures}
        prev={(fixtures) => {
          setFixtures(fixtures);
          setCurrentStep((s) => s - 1);
        }}
        trackingScreenName="new_league_fixtures"
      />
    );
  };

  const MakePredictions = () => {
    return (
      <ClashPredictionsOverlay
        exit={exit}
        fixtures={fixtures}
        isVisible
        predictions={predictions}
        next={() => setCurrentStep((s) => s + 1)}
        prev={() => setCurrentStep((s) => s - 1)}
        setPredictions={setPredictions}
        canPAYG={canPAYG}
        trackingScreenName="new_league_predictions"
        isAddingPrediction={false}
      />
    );
  };

  const ConfirmTransaction = () => {
    return (
      <ConfirmTransactionOverlay
        confirmTransaction={callCreateClash}
        estimatedGas={estimatedGas}
        exit={exit}
        isVisible
        prev={() => setCurrentStep((s) => s - 1)}
        ticketPrice={amount}
        token={Tokens.BY_ADDRESS[tokenAddress]}
        title={title}
        trackingScreenName="new_league_confirmation"
        isListed={isListed}
        predictions={predictions}
        fixtures={fixtures}
        isJoiningOrCreating={true}
        isAllowance={isAllowance}
      />
    );
  };

  const ClashCreated = () => {
    return (
      <CongratulationsOverlay
        isVisible
        exit={exit}
        text="New league created!"
        trackingScreenName={"new_league_created"}
      />
    );
  };

  const steps = [
    SetClashParams,
    SelectFixtures,
    MakePredictions,
    ConfirmTransaction,
    ClashCreated,
  ];

  let canUseAllowanceToCreate = Number(allowance) !== 0;
  let canUseWalletBalanceToCreate =
    balances && Number(balances[1]) && Number(balances[1]) !== 0;

  if (Boolean(errorOverlayMessage)) {
    return <ErrorOverlay exit={exit} isVisible text={errorOverlayMessage} />;
  } else if (
    displayInsufficientFundsOverlay ||
    (!canUseAllowanceToCreate && !canUseWalletBalanceToCreate)
  ) {
    return (
      <ErrorOverlay
        exit={exit}
        isVisible
        text={ErrorMessages.INSUFFICIENT_FUNDS}
      />
    );
  } else if (isLoading || !balances) {
    const inConfirmation = currentStep === steps.indexOf(ConfirmTransaction);
    return (
      <LoadingOverlay
        exit={exit}
        isVisible
        loadingColor={Config.Color.DFM_PINK}
        title={inConfirmation && "CONFIRM TRANSACTION"}
        text={
          inConfirmation
            ? Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex]?.text
            : " "
        }
      />
    );
  }
  return steps[currentStep]();
};

export default CreateClashWizard;
