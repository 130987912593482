import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Loading, SquadaOneText, TransferButton } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { infuraWeb3Struct, parseToken } from "../web3/utils";

const { web3, tokensInsts } = infuraWeb3Struct;

const Balance = ({ fromActiveWallet, index, userStruct, walletAddress }) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    if (walletAddress) {
      if (index === 0) {
        web3.eth.getBalance(walletAddress).then(setBalance);
      } else {
        tokensInsts[index - 1].methods
          .balanceOf(walletAddress)
          .call()
          .then(setBalance);
      }
    }
  }, [index, walletAddress]);

  if (balance === null) return <Loading height={15} width={15} />;

  const token = Config.Referee.Tokens.ALL[index];

  return (
    <View style={styles.balanceContainer}>
      <SquadaOneText style={styles.balance}>
        {parseToken(balance, token)}{" "}
      </SquadaOneText>
      {Boolean(fromActiveWallet) && Boolean(balance > 0) && (
        <TransferButton
          balance={balance}
          tokenAddress={token.address}
          userStruct={userStruct}
        />
      )}
    </View>
  );
};

export default Balance;

const styles = StyleSheet.create({
  balanceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  balance: {
    color: Config.Color.DFM_PINK,
    fontSize: hp(2.5),
  },
});
