const EVENTS = {
  // Recommended events
  SCREEN_VIEW: "screen_view",
  LOGIN: "login",
  JOIN_GROUP: "join_group",

  // Custom Events
  CREATE_ACCOUNT: "new_account_created",

  LOGOUT: "logout",

  ADDITIONAL_WALLET_ADDED_SUCCESS: "additional_wallet_added_success",
  ADDITIONAL_WALLET_ADDED_ERROR: "additional_wallet_adding_error",

  ADDITIONAL_DEVICE_ADDED_SUCCESS: "additional_device_added_success",
  ADDITIONAL_DEVICE_ADDED_ERROR: "additional_device_adding_error",

  SET_USERNAME_START: "set_username_start",
  SET_USERNAME_SUCCESS: "set_username_success",
  SET_USERNAME_ERROR: "set_username_error",

  CREATE_LEAGUE_START: "create_league_start",
  CREATE_LEAGUE_SUCCESS: "create_league_sucess",
  CREATE_LEAGUE_ERROR: "create_league_error",

  JOIN_LEAGUE_START: "join_league_start",
  JOIN_LEAGUE_SUCCESS: "join_league_success",
  JOIN_LEAGUE_ERROR: "join_league_error",

  TOP_UP_WALLET_START: "top_up_wallet_start",
  TOP_UP_WALLET_SUCCESS: "top_up_wallet_success",
  TOP_UP_WALLET_ERROR: "top_up_wallet_error",

  FOLLOW: "follow",
  UNFOLLOW: "unfollow",
};

export { EVENTS };
