import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { SquadaOneText } from "./index";
import { parseToken } from "../web3/utils";

/*
LEAGUE STATUS
- UPCOMING
- ON GOING
- PENDING RESULT
- WON
- LOST
- REVERTED
*/

const LeagueStatus = ({
  hasLeagueFinished,
  hasLeagueStarted,
  myPrediction,
  showPrize,
  token,
}) => {
  const { Lost, Reverted } = Config.Referee.Events;
  const defaultStyles = {};
  let iconSource;
  let hasIcon = false;
  let status;

  // console.log("HEY STATUS");
  // console.log("myPrediction");
  // console.log(myPrediction);

  if (hasLeagueFinished) {
    status = "PENDING\nRESULT";
    defaultStyles.backgroundColor = Config.Color.GREY;
    //Prediction status is defined by the following: 0 => Undefined; -1 => Lost; -2 => Reverted; >0 => Prized
    if (myPrediction && !!myPrediction.status) {
      if (myPrediction.status === Lost.CODE) {
        hasIcon = true;
        status = Lost.EVENT;
        iconSource = require("../assets/thumbs-down-dark-purple.png");
        defaultStyles.backgroundColor = Config.Color.DFM_PINK;
      } else if (myPrediction.status === Reverted.CODE) {
        hasIcon = true;
        status = Reverted.EVENT;
        iconSource = require("../assets/hand-shake-dark-purple.png");
        defaultStyles.backgroundColor = Config.Color.DFM_LIGHT_BLUE;
      } else {
        hasIcon = true;
        iconSource = require("../assets/thumbs-up-dark-purple.png");
        defaultStyles.backgroundColor = Config.Color.DFM_GREEN;
        status = "WON";
        if (showPrize) {
          status =
            status +
            "\n" +
            parseToken(myPrediction.status.toString(), token) +
            " " +
            token.caption;
        }
      }
    } else {
      hasIcon = false;
      defaultStyles.backgroundColor = Config.Color.WHITE;
      status = "ENDED";
    }
  } else if (!hasLeagueStarted) {
    status = "UP\nCOMING";
    defaultStyles.backgroundColor = Config.Color.LIGHT_GREY;
  } else if (hasLeagueStarted && !hasLeagueFinished) {
    status = "ON\nGOING";
    defaultStyles.backgroundColor = Config.Color.DFM_YELLOW;
  }

  return (
    <View style={[styles.container, defaultStyles]}>
      {hasIcon && <Image source={iconSource} style={styles.icon} />}
      <SquadaOneText style={styles.text} adjustsFontSizeToFit numberOfLines={2}>
        {status}
      </SquadaOneText>
    </View>
  );
};

export default LeagueStatus;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
  },
  icon: {
    height: 0.3 * Dimensions.CARD_HEIGHT,
    width: 0.3 * Dimensions.CARD_HEIGHT,
    resizeMode: "contain",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
  },
  text: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: 0.17 * Dimensions.CARD_HEIGHT,
    textAlign: "center",
  },
});
