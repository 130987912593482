import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  FlatList,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Loading, SquadaOneText, FixtureCard } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { adjustDates } from "../formatting";
import { sortFixtures } from "../sorting";

const CompetitionScreen = ({ match, history }) => {
  const [competition, setCompetition] = useState(null);
  const [fixtures, setFixtures] = useState([]);

  useEffect(() => {
    const id = match.params.id;
    const fetchCompetition = () => {
      fetch(Config.COACH_URL + `/competition/${id}`)
        .then((res) => res.json())
        .then((c) => {
          setCompetition({
            id: id,
            name: c.caption,
            imageURL: c.logo_url,
            url: Config.FavoritesCategories.Competitions.URL + "/" + id,
          });
          fetch(Config.COACH_URL + `/competition/${id}/matches`)
            .then((res) => res.json())
            .then((fs) =>
              setFixtures(
                fs
                  .filter(
                    (f) =>
                      !Config.Status.Blacklist.some((s) =>
                        s.CODE.includes(f.status)
                      )
                  )
                  .map(adjustDates)
                  .sort(sortFixtures)
                  .map((f) => {
                    f["competition"] = c;
                    return f;
                  })
              )
            );
        });
    };
    fetchCompetition();
    const intervalId = setInterval(fetchCompetition, 20000);
    return () => clearInterval(intervalId);
  }, [match.params.id]);

  if (!competition) return <Loading />;
  return (
    <View style={styles.canvas}>
      <View style={styles.competitionContainer}>
        <Image
          style={styles.logo}
          source={{
            uri: competition.imageURL,
          }}
        />
        <SquadaOneText variant={"h2"} style={styles.name}>
          {competition.name.toUpperCase()}
        </SquadaOneText>
      </View>
      <View
        style={styles.fixturesContainer}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        <FlatList
          style={styles.matchesList}
          showsVerticalScrollIndicator={false}
          data={fixtures.filter((fixture) =>
            Config.Status.Unfinished.some((s) =>
              s.CODE.includes(fixture.status)
            )
          )}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => history.push("/match/" + item.id)}>
              <FixtureCard
                variant={"competition_page"}
                fixture={item}
                clashesNumber={"JOIN"}
                statusContainerBackground={{
                  backgroundColor: "rgba(55,2,68,0.6)",
                }}
                dynamicContainer={
                  <View style={styles.leaguesContainer}>
                    <SquadaOneText
                      adjustsFontSizeToFit
                      numberOfLines={2}
                      variant={"home_leagues_counter"}
                      style={{ textAlign: "center" }}
                    >
                      {"Join\nLEAGUES"}
                    </SquadaOneText>
                  </View>
                }
              />
            </TouchableOpacity>
          )}
          keyExtractor={(item) => item.id}
        />
      </View>
    </View>
  );
};
export default CompetitionScreen;

const styles = StyleSheet.create({
  matchesList: {
    paddingVertical: 5,
  },
  canvas: {
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  competitionContainer: {
    height: "25%",
    width: "100%",
    borderTopWidth: 2,
    borderTopColor: Config.Color.WHITE,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: hp(3),
  },
  logo: {
    height: "60%",
    width: "100%",
    resizeMode: "contain",
  },
  name: {
    color: Config.Color.WHITE,
    paddingTop: 5,
  },
  fixturesContainer: {
    height: "75%",
    width: "100%",
    backgroundColor: Config.Color.LIGHT_GREY,
  },
  leaguesContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    borderLeftWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    justifyContent: "center",
  },
});
