import React from "react";
import { View } from "react-native";
import ReactLoading from "react-loading";
import { Config } from "../config";

const Loading = ({
  color = Config.Color.DFM_DARK_PURPLE,
  containerStyle,
  height = 35,
  type = "spin",
  width = 35,
}) => {
  return (
    <View
      style={[
        { flex: 1, alignItems: "center", justifyContent: "center" },
        containerStyle,
      ]}
    >
      <ReactLoading color={color} height={height} type={type} width={width} />
    </View>
  );
};

export default Loading;
