import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Platform,
  ScrollView,
  Share,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { useToast } from "react-native-toast-notifications";
import {
  JoinButton,
  LeaderboardItem,
  Loading,
  TabList,
  SquadaOneText,
  JoinClashWizard,
  RobotoText,
  LeagueCard,
  FixtureCard,
} from "../components";
import { checkLoginAndThen } from "../components/LoginSwitch";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { logScreenView, logEvent } from "../firebase";
import { EVENTS } from "../firebase/events";
import { buildLink, composeShareData } from "../firebase/utils";
import { RulesOverlay } from "../overlays";
import { Redirect } from "../router";
import { sortSagaLeaderboard } from "../sorting";
import {
  infuraWeb3Struct,
  parseClash,
  parseToken,
  shortenAddress,
} from "../web3/utils";
import ScoreDisplay from "../components/fixture-components/ScoreDisplay";
import LeagueMatchOverlay from "../overlays/LeagueMatchOverlay";
import ScoreNowButton from "../components/ScoreNowButton";

const Tokens = Config.Referee.Tokens;

const ClashScreen = ({
  history,
  logout,
  match,
  submitPrediction,
  switchLoginState,
  users,
  userStruct,
}) => {
  const [isLeagueMatchOverlaySelected, setIsLeagueMatchOverlaySelected] =
    useState(false);
  const [matchIdSelected, setMatchIdSelected] = useState(null);
  const [isRulesOverlaySelected, setIsRulesOverlaySelected] = useState(false);
  const [clash, setClash] = useState(null);
  const [toJoinClash, setToJoinClash] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [refreshPredictions, setRefreshPredictions] = useState(false);
  const [selectedFixtureToScore, setSelectedFixtureToScore] = useState(0);
  const { account, initialLoading } = userStruct;

  const getClash = useCallback(() => {
    try {
      infuraWeb3Struct.refereeInst.methods
        .getClash(match.params.id)
        .call()
        .then(async (clash) => {
          parseClash(clash, true).then((parsedClash) => {
            if (!predictions) {
              setPredictions(
                new Array(parsedClash.fixturesIds.length).fill(undefined)
              );
            }
            setClash(parsedClash);
            setRefreshPredictions(false);
          });
        });
    } catch (error) {
      setHasFailed(true);
    }
  }, [match.params.id, predictions, setHasFailed]);

  useEffect(() => {
    getClash();
    const intervalId = setInterval(getClash, 20000);
    return () => clearInterval(intervalId);
  }, [getClash, refreshPredictions]);

  const toggleToJoinClash = () => {
    if (toJoinClash) {
      setToJoinClash(false);
    } else {
      checkLoginAndThen(
        () => {
          setToJoinClash(true);
          logEvent(EVENTS.JOIN_LEAGUE_START, {
            from: "league",
            league_id: match.params.id,
          });
        },
        switchLoginState,
        userStruct,
        logout
      );
    }
  };

  const pressShareButton = async () => {
    const { amount, id, title, tokenAddress } = clash;
    const token = Tokens.BY_ADDRESS[tokenAddress];
    const { path, text } = composeShareData(
      parseToken(amount, token),
      token.caption,
      id,
      title
    );
    buildLink(path).then(async (message) => {
      await Share.share({
        title: text,
        message: message,
      });
    });
  };

  const toast = useToast();

  const pressCopyLink = () => {
    const { amount, id, title, tokenAddress } = clash;
    const token = Tokens.BY_ADDRESS[tokenAddress];
    Clipboard.setString(
      window.location.origin +
        composeShareData(parseToken(amount, token), token.caption, id, title)
          .path
    );
    toast.show("Copied to clipboard");
  };

  React.useEffect(() => {
    let screenName =
      `league_${Config.CLASH_SCREEN_TABS[selectedTab]}`.toLowerCase();
    logScreenView(screenName);
  }, [selectedTab]);

  if (hasFailed) return <Redirect to="/" />;
  if (!clash || initialLoading) return <Loading />;

  let canProceed;
  let myPrediction;
  if (userStruct.wallets && account && clash) {
    const wallets = [account].concat(
      userStruct.wallets.filter((w) => w !== account)
    );
    const wallet = wallets.find((w) => clash.predictions.get(w));
    canProceed = wallet === undefined || account === wallet;
    myPrediction = clash.predictions.get(wallet);
    myPrediction !== undefined &&
      myPrediction["results"].forEach((p, i) => {
        if (
          p !== undefined &&
          Number(p["homeScore"]) === Config.PREDICTION_NOT_ADDED &&
          Number(p["awayScore"]) === Config.PREDICTION_NOT_ADDED
        ) {
          myPrediction["results"][i] = undefined;
        }
      });
  }

  const predictionsArray = [...clash.predictions.values()];
  const predictionsByMatch = new Array(clash.fixturesIds.length);

  for (let i = 0; i < clash.fixturesIds.length; i++) {
    predictionsByMatch[i] = new Array(predictionsArray.length);
    for (let j = 0; j < predictionsArray.length; j++) {
      predictionsByMatch[i][j] = predictionsArray[j].results[i];
    }
  }

  const playersUsernames = predictionsArray.map((prediction) => {
    const user = users && users.find((u) => u.user === prediction.user);
    return user ? user.username : shortenAddress(prediction.user);
  });

  return (
    <View style={styles.pageContainer}>
      <View style={styles.pageHeaderContainer}>
        <View style={styles.titleActionsContainer}>
          <View style={styles.titleContainer}>
            {!Boolean(clash.isListed) && (
              <Image
                style={styles.listedIcon}
                source={require("../assets/lock-light-purple.png")}
              />
            )}
            <SquadaOneText adjustsFontSizeToFit numberOfLines={1} variant="h1">
              {clash.title.toUpperCase()}
            </SquadaOneText>
          </View>
          <View style={styles.actionsContainer}>
            <TouchableOpacity
              style={styles.copyShareContainer}
              onPress={Platform.OS === "web" ? pressCopyLink : pressShareButton}
            >
              <Image
                style={styles.copyShareImage}
                source={
                  Platform.OS === "web"
                    ? require("../assets/copy.png")
                    : require("../assets/share.png")
                }
              />
            </TouchableOpacity>
            <JoinButton
              league={clash}
              hasJoined={myPrediction}
              isLoading={(myPrediction && !myPrediction.date) || initialLoading}
              join={toggleToJoinClash}
            />
          </View>
        </View>
        <View style={styles.detailsContainer}>
          <LeagueCard
            userStruct={userStruct}
            account={userStruct.account}
            clash={clash}
            prediction={clash.pendingPrediction}
            showYourScore={false}
            showTitle={false}
            showPrize={true}
          />
        </View>
      </View>
      <TabList
        tabs={Config.CLASH_SCREEN_TABS}
        selected={selectedTab}
        setSelected={setSelectedTab}
      />
      <View
        style={{ height: "70%", width: "100%" }}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        {
          {
            1: (
              <ScrollView showsVerticalScrollIndicator={false}>
                <RulesOverlay
                  isVisible={isRulesOverlaySelected}
                  exit={() => setIsRulesOverlaySelected(false)}
                />
                <TouchableOpacity
                  style={styles.rulesContainer}
                  onPress={() =>
                    setIsRulesOverlaySelected(!isRulesOverlaySelected)
                  }
                >
                  <Image
                    style={styles.rulesIcon}
                    source={require("../assets/rules-dark-purple.png")}
                  />
                  <RobotoText style={styles.rulesCtaText}>
                    Check the Leaderboard Rules here.
                  </RobotoText>
                </TouchableOpacity>
                <View>
                  <FlatList
                    data={[...clash.predictions.values()].sort(
                      sortSagaLeaderboard
                    )}
                    renderItem={({ item, index }) => {
                      const user =
                        users && users.find((u) => u.user === item.user);
                      return (
                        <LeaderboardItem
                          name={
                            user ? user.username : shortenAddress(item.user)
                          }
                          namePress={
                            user &&
                            (() => history.push("/user/" + user.username))
                          }
                          position={index + 1}
                          value={item.points + " pts"}
                        />
                      );
                    }}
                    keyExtractor={(item, index) => index.toString()}
                  />
                </View>
              </ScrollView>
            ),
            0: (
              <FlatList
                showsVerticalScrollIndicator={false}
                data={clash.fixtures}
                extraData={userStruct}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setMatchIdSelected(index);
                        setIsLeagueMatchOverlaySelected(
                          !isLeagueMatchOverlaySelected
                        );
                      }}
                    >
                      <FixtureCard
                        variant={"league_page"}
                        fixture={item}
                        key={item.id}
                        statusContainerBackground={{
                          backgroundColor: "rgba(55,2,68,0.6)",
                        }}
                        dynamicContainer={
                          <ScoreDisplay
                            fixtureId={item.id}
                            prediction={
                              myPrediction && myPrediction.results[index]
                            }
                            isAuthUser={true}
                            hasJoined={Boolean(myPrediction)}
                            showTitle
                            scoreNowComponent={
                              <ScoreNowButton
                                action={() => {
                                  setSelectedFixtureToScore(index);
                                  toggleToJoinClash();
                                }}
                              />
                            }
                          />
                        }
                      />
                    </TouchableOpacity>
                  );
                }}
                keyExtractor={(item, index) => index.toString()}
              />
            ),
          }[selectedTab]
        }
      </View>
      <LeagueMatchOverlay
        isVisible={isLeagueMatchOverlaySelected}
        exit={() => {
          setMatchIdSelected(null);
          setIsLeagueMatchOverlaySelected(false);
        }}
        fixture={clash.fixtures[matchIdSelected]}
        playersUsernames={playersUsernames}
        matchPredictions={predictionsByMatch[matchIdSelected]}
        loggedInUsername={userStruct.username}
        hasJoined={myPrediction}
        history={history}
        scoreNowComponent={<ScoreNowButton action={toggleToJoinClash} />}
      />
      <JoinClashWizard
        canProceed={canProceed}
        clash={clash}
        myPredictions={myPrediction}
        submitPrediction={submitPrediction}
        setRefreshPredictions={setRefreshPredictions}
        selectedFixtureToScore={selectedFixtureToScore}
        toJoinClash={toJoinClash}
        toggleToJoinClash={toggleToJoinClash}
        userStruct={userStruct}
      />
    </View>
  );
};
export default ClashScreen;

const styles = StyleSheet.create({
  pageContainer: {
    height: "100%",
    width: Dimensions.MAX_WIDTH,
    backgroundColor: Config.Color.WHITE,
  },
  pageHeaderContainer: {
    height: "23%",
    width: "100%",
    flexDirection: "column",
    backgroundColor: Config.Color.LIGHT_GREY,
  },
  titleActionsContainer: {
    width: "95%",
    marginLeft: "2.5%",
    height: "35%",
    paddingVertical: "2%",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "flex-start",
  },
  titleContainer: {
    flexDirection: "row",
    width: "65%",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
  },
  listedIcon: {
    height: hp(2.5),
    width: hp(2.5),
    resizeMode: "contain",
    marginRight: 5,
  },
  actionsContainer: {
    flexDirection: "row",
    width: "35%",
    justifyContent: "flex-end",
  },
  copyShareContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: "5%",
    paddingVertical: "5%",
    marginRight: "3%",
    backgroundColor: Config.Color.DFM_GREEN,
  },
  copyShareImage: {
    height: hp(2.5),
    width: hp(2.5),
    resizeMode: "contain",
    tintColor: Config.Color.DFM_DARK_PURPLE,
    marginLeft: 2,
  },
  detailsContainer: {
    width: "95%",
    marginLeft: "2.5%",
    height: "65%",
    justifyContent: "center",
  },
  rulesContainer: {
    flexDirection: "row",
    marginVertical: 5,
    paddingVertical: 7,
    width: "95%",
    marginLeft: "2.5%",
    alignItems: "center",
  },
  rulesIcon: {
    height: hp(3.5),
    width: hp(3.5),
    resizeMode: "contain",
    tintColor: Config.Color.DFM_DARK_PURPLE,
  },
  rulesCtaText: {
    fontSize: hp(1.6),
  },
});
