import React from "react";
import { Image, View, StyleSheet } from "react-native";
import { ActionButton, RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { Frame } from "./shared";

const MAX_HEIGHT = 0.45 * Dimensions.MAX_HEIGHT;
const MAX_WIDTH = 0.9 * Dimensions.MAX_WIDTH;
const BUTTON_WIDTH = 0.8 * Dimensions.MAX_WIDTH;

const MessageOverlay = ({
  buttonText = "CLOSE",
  action,
  exit,
  imageSource,
  imageStyle = {},
  isVisible,
  text = " ",
  title = " ",
  trackingScreenName,
}) => {
  return (
    <Frame
      isVisible={isVisible}
      headerText=" "
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={trackingScreenName}
    >
      <View style={styles.bodyView}>
        <Image source={imageSource} style={[styles.image, imageStyle]} />
        <SquadaOneText
          adjustsFontSizeToFit
          numberOfLines={1}
          style={styles.titleText}
        >
          {title.toUpperCase()}
        </SquadaOneText>
        <RobotoText
          adjustsFontSizeToFit
          variant="regular"
          style={styles.descriptionText}
        >
          {text}
        </RobotoText>
      </View>
      <View style={styles.footerView}>
        <ActionButton
          containerStyle={styles.actionButtonContainerStyle}
          onPress={() => {
            action ? action() : exit();
          }}
          title={buttonText.toUpperCase()}
        />
      </View>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  image: {
    height: 0.5 * MAX_HEIGHT,
    width: 0.85 * MAX_WIDTH,
    resizeMode: "contain",
    tintColor: Config.Color.DFM_LIGHT_PURPLE,
    marginBottom: "5%",
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    fontSize: 0.09 * MAX_HEIGHT,
    lineHeight: 0.09 * MAX_HEIGHT,
    color: Config.Color.DFM_YELLOW,
    marginBottom: "2.5%",
  },
  descriptionText: {
    fontSize: 0.06 * MAX_HEIGHT,
    lineHeight: 0.09 * MAX_HEIGHT,
    color: Config.Color.WHITE,
    marginBottom: 0.07 * MAX_HEIGHT,
    textAlign: "center",
    overflow: "visible",
  },
  actionButtonContainerStyle: {
    width: BUTTON_WIDTH,
  },
});

export default MessageOverlay;
