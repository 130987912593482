import DAI_ABI from "./dai-abi.json";
import USDC_ABI from "./usdc-abi.json";
import USDT_ABI from "./usdt-abi.json";
import AVAX_LOGO from "./avax-logo.png";
import DAI_LOGO from "./dai-dark-purple.png";
import ETH_LOGO from "./eth-logo.png";
import MATIC_LOGO from "./matic-dark-purple.png";
import USDC_LOGO from "./usdc-dark-purple.png";
import USDT_LOGO from "./usdt-logo.png";

export const TokensABIs = { DAI: DAI_ABI, USDC: USDC_ABI, USDT: USDT_ABI };
export const TokensLogos = {
  AVAX: AVAX_LOGO,
  DAI: DAI_LOGO,
  ETH: ETH_LOGO,
  MATIC: MATIC_LOGO,
  USDC: USDC_LOGO,
  USDT: USDT_LOGO,
};
