import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../config";
import { SquadaOneText } from "./index";

/*
 * STATS COMPONENT
 * Brief description: 3 boxes next to each other with Title and Value displayed.
 * Displayed on the profile page and league match stats
 */

const Stats = ({ stats }) => {
  return (
    <View style={styles.mainContainer}>
      {stats.map(({ title, value }) => (
        <View
          key={title}
          style={{
            width: "30%",
            height: "100%",
            borderWidth: 2,
            borderColor: Config.Color.DFM_PINK,
            backgroundColor: Config.Color.WHITE,
            flexDirection: "column",
          }}
        >
          <View
            style={{
              height: "30%",
              width: "100%",
              backgroundColor: Config.Color.DFM_DARK_PURPLE,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SquadaOneText
              adjustsFontSizeToFit
              numberOfLines={2}
              variant="profile_counter_titles"
            >
              {title.toUpperCase()}
            </SquadaOneText>
          </View>
          <View
            style={{
              height: "70%",
              width: "100%",
              backgroundColor: Config.Color.WHITE,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SquadaOneText
              adjustsFontSizeToFit
              numberOfLines={1}
              variant="profile_counters"
            >
              {value}
            </SquadaOneText>
          </View>
        </View>
      ))}
    </View>
  );
};

export default Stats;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
