import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAppWindowWidth } from "./dimensions";
import { logScreenView } from "./firebase";

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const getScreenNameFromPathName = (pathName) => {
  const tokenizedUrl = pathName.split("/");

  switch (tokenizedUrl[1]) {
    case "":
      return "matches";

    case "leagues":
      return "leagues";

    default:
      return undefined;
  }
};

function useScreenTracking() {
  const location = useLocation();

  useEffect(() => {
    const screenName = getScreenNameFromPathName(location.pathname);

    if (screenName) {
      logScreenView(screenName);
    }
  }, [location]);
}

const SWIPE_GESTURES = {
  SWIPE_LEFT: "SWIPE_LEFT",
  SWIPE_RIGHT: "SWIPE_RIGHT",
  SWIPE_UP: "SWIPE_UP",
  SWIPE_DOWN: "SWIPE_DOWN",
};

function useSwipe(onSwipe, rangeOffset = 0.25) {
  let firstTouch = { x: 0, y: 0 };

  function onTouchStart(e) {
    firstTouch.x = e.nativeEvent.pageX;
    firstTouch.y = e.nativeEvent.pageY;
  }

  function onTouchEnd(e) {
    const positionX = e.nativeEvent.pageX;
    const range = getAppWindowWidth() * rangeOffset;

    if (positionX - firstTouch.x > range) {
      onSwipe(SWIPE_GESTURES.SWIPE_RIGHT);
    } else if (firstTouch.x - positionX > range) {
      onSwipe(SWIPE_GESTURES.SWIPE_LEFT);
    }
  }

  return { onTouchStart, onTouchEnd };
}

export { useDebounce, useScreenTracking, useSwipe, SWIPE_GESTURES };
