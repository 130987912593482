import React, { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";
import { RobotoText, SquadaOneText } from "./index";

const LeaderboardItem = ({ name, namePress, position, value }) => {
  const [itemHover, setItemHover] = useState(false);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.itemContainer}>
        <View style={styles.positionContainer}>
          <SquadaOneText
            variant={"leaderboard_position"}
            adjustsFontSizeToFit
            numberOfLines={1}
          >
            #{position}
          </SquadaOneText>
        </View>
        <View style={styles.playerContainer}>
          <Image
            style={styles.userIcon}
            source={require("../assets/user.png")}
          />
          <RobotoText
            style={{
              ...(itemHover && {
                textDecoration: "underline",
                cursor: "pointer",
              }),
            }}
            variant={"leaderboard_player"}
            adjustsFontSizeToFit
            numberOfLines={1}
            onMouseEnter={() => {
              namePress && setItemHover(true);
            }}
            onMouseLeave={() => {
              namePress && setItemHover(false);
            }}
            onPress={namePress}
          >
            {name}
          </RobotoText>
        </View>
        <View style={styles.pointsContainer}>
          <SquadaOneText
            variant={"leaderboard_points"}
            adjustsFontSizeToFit
            numberOfLines={1}
          >
            {value}
          </SquadaOneText>
        </View>
      </View>
    </View>
  );
};

export default LeaderboardItem;

const styles = StyleSheet.create({
  mainContainer: {
    height: hp(7),
    width: "100%",
    flexDirection: "row",
    marginVertical: 2,
    backgroundColor: Config.Color.WHITE,
  },

  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Config.Color.WHITE,
    width: "95%",
    marginLeft: "2.5%",
    height: "100%",
  },

  positionContainer: {
    borderWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    width: "20%",
    backgroundColor: Config.Color.WHITE,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  playerContainer: {
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderTopColor: Config.Color.DFM_DARK_PURPLE,
    borderBottomColor: Config.Color.DFM_DARK_PURPLE,
    width: "60%",
    backgroundColor: Config.Color.WHITE,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    flexDirection: "row",
  },

  userIcon: {
    height: hp(3.5),
    width: hp(3.5),
    marginHorizontal: 10,
    resizeMode: "contain",
  },

  pointsContainer: {
    width: "20%",
    height: "100%",
    backgroundColor: Config.Color.DFM_YELLOW,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});
