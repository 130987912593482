import React from "react";
import { FlatList, Image, StyleSheet, View } from "react-native";
import { Overlay } from "react-native-elements";
import { OswaldText, OverlayHeader } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { Modal } from "../modal";

const ClashUsersOverlay = ({ clashUsers, cleanClashUsers }) => {
  return (
    <Overlay
      ModalComponent={Modal}
      isVisible={!!clashUsers}
      overlayStyle={{
        maxHeight: MAX_HEIGHT,
        width: 0.95 * Dimensions.MAX_WIDTH,
        padding: "0%",
        // borderRadius: 10,
        backgroundColor: Config.Color.LIGHT_GREY,
      }}
      onBackdropPress={cleanClashUsers}
    >
      <View style={{ width: "100%" }}>
        <OverlayHeader title="PLAYERS CLASHING" exit={cleanClashUsers} />
        <View
          style={{
            maxHeight: MAX_HEIGHT - 2 * Dimensions.OVERLAY_HEADER_HEIGHT,
            width: "100%",
          }}
        >
          <FlatList
            data={clashUsers}
            renderItem={({ item }) => (
              <View
                style={{
                  height: ROW_HEIGHT,
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  marginVertical: 2,
                  backgroundColor: Config.Color.WHITE,
                }}
              >
                <Image
                  style={styles.icon}
                  source={require("../assets/user.png")}
                />
                <View>
                  <OswaldText
                    style={styles.text1}
                    adjustsFontSizeToFit
                    numberOfLines={1}
                  >
                    {item.username}
                  </OswaldText>
                  <OswaldText
                    style={styles.text2}
                    adjustsFontSizeToFit
                    numberOfLines={1}
                  >
                    Pick: {item.result}
                  </OswaldText>
                </View>
              </View>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
        <View style={{ height: Dimensions.OVERLAY_HEADER_HEIGHT }} />
      </View>
    </Overlay>
  );
};

export default ClashUsersOverlay;

const MAX_HEIGHT = hp(75);
const ROW_HEIGHT = hp(8);
const ICON_SIZE = ROW_HEIGHT * 0.7;
const HORIZONTAL_MARGIN = 0.04 * Dimensions.MAX_WIDTH;

const styles = StyleSheet.create({
  text1: {
    fontSize: hp(2.2),
    color: Config.Color.CY_PURPLE,
  },
  text2: {
    fontSize: hp(2),
    fontFamily: "Oswald-Light",
    color: Config.Color.GREY,
  },
  icon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    marginHorizontal: HORIZONTAL_MARGIN,
    resizeMode: "contain",
    tintColor: Config.Color.CY_PURPLE,
  },
});
