import React, { useRef, useState } from "react";
import {
  FlatList,
  Image,
  Platform,
  Share,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import Toast from "react-native-toast-notifications";
import Clipboard from "@react-native-clipboard/clipboard";
import { ActionButton, Loading, OswaldText, RubikText } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { buildLink, publishNotification } from "../firebase/utils";
import { Frame } from "./shared";

const SendInviteOverlay = ({ exit, inviteObj, users }) => {
  const [selected, setSelected] = useState(new Array(users ? users.length : 0));

  const exitInvite = () => {
    setSelected(new Array(users ? users.length : 0));
    exit();
  };

  const handleSelect = (index) => {
    let _selected = [...selected];
    _selected[index] = !_selected[index];
    setSelected(_selected);
  };

  const pressSendInvite = async () => {
    let { data, hasPromise } = inviteObj;
    exitInvite();
    if (hasPromise) {
      data = await data;
    }
    const { notification, notifData } = data;
    for (const u of users.filter((u, index) => selected[index])) {
      await publishNotification("u" + u.user, notification, notifData);
    }
  };

  const pressShareButton = async () => {
    const { path, text } = inviteObj.data.shareData;
    buildLink(path).then(async (message) => {
      await Share.share({
        title: text,
        message: message,
      });
    });
  };

  const toastRef = useRef();

  return (
    <Frame
      isVisible={!!inviteObj}
      headerText="INVITE USERS"
      onBackdropPress={exitInvite}
      onPressCloseButton={exitInvite}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
    >
      <View style={styles.bodyView}>
        {!users && <Loading />}
        {users && (
          <>
            <View
              style={styles.instructionsCopyView}
              onMoveShouldSetResponderCapture={
                Platform.OS === "web" ? () => true : undefined
              }
            >
              <RubikText
                adjustsFontSizeToFit
                numberOfLines={1}
                variant="regular"
                style={styles.instructionsCopyText}
              >
                Make sure to invite everyone in before the league starts
              </RubikText>
              <View style={styles.shareView}>
                {inviteObj && !inviteObj.hasPromise && Platform.OS !== "web" && (
                  <TouchableOpacity onPress={pressShareButton}>
                    <Image
                      style={styles.shareImageMobile}
                      source={require("../assets/share.png")}
                    />
                  </TouchableOpacity>
                )}
                {inviteObj && !inviteObj.hasPromise && Platform.OS === "web" && (
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onPress={() => {
                      Clipboard.setString(
                        window.location.origin + inviteObj.data.shareData.path
                      );
                      toastRef.current.show("Copied to clipboard");
                    }}
                  >
                    <OswaldText
                      style={styles.shareText}
                      adjustsFontSizeToFit
                      numberOfLines={1}
                    >
                      Copy Link
                    </OswaldText>
                    <Image
                      style={styles.shareImageWeb}
                      source={require("../assets/copy.png")}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
            <FlatList
              data={users}
              extraData={selected}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleSelect(index)}
                  style={styles.userTouchable}
                >
                  <Icon
                    name={selected[index] ? "circle" : "circle-o"}
                    type="font-awesome"
                    size={25}
                    color={
                      selected[index]
                        ? Config.Color.DFM_DARK_PURPLE
                        : Config.Color.DFM_DARK_PURPLE
                    }
                    containerStyle={{ marginRight: "10%" }}
                  />
                  <View style={styles.userDataView}>
                    <Image
                      style={styles.icon}
                      source={require("../assets/user.png")}
                    />
                    <RubikText
                      variant="medium"
                      style={styles.text1}
                      adjustsFontSizeToFit
                      numberOfLines={1}
                    >
                      {item.username}
                    </RubikText>
                  </View>
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => index.toString()}
              showsVerticalScrollIndicator={false}
            />
          </>
        )}
      </View>
      <View style={styles.footerView}>
        <ActionButton
          onPress={exitInvite}
          title="SKIP STEP"
          variant="secondary"
        />
        <ActionButton
          onPress={pressSendInvite}
          title="SEND INVITE"
          disabled={!selected.reduce((x, acc) => acc || x, false)}
        />
      </View>
      <Toast ref={toastRef} />
    </Frame>
  );
};

export default SendInviteOverlay;

const SUB_HEADER_HEIGHT = hp(4);
const ROW_HEIGHT = hp(6);
const ICON_SIZE = ROW_HEIGHT - 10;
const HORIZONTAL_MARGIN = 0.04 * Dimensions.MAX_WIDTH;

const styles = StyleSheet.create({
  text1: {
    fontSize: hp(2.2),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  text2: {
    fontSize: hp(2),
    fontFamily: "Oswald-Light",
    color: Config.Color.GREY,
  },
  icon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    marginRight: HORIZONTAL_MARGIN,
    resizeMode: "contain",
  },
  shareView: {
    flexDirection: "row",
  },
  shareImageMobile: {
    height: 0.75 * SUB_HEADER_HEIGHT,
    width: 0.75 * SUB_HEADER_HEIGHT,
    resizeMode: "contain",
    tintColor: Config.Color.DFM_DARK_PURPLE,
  },
  shareImageWeb: {
    height: 0.45 * SUB_HEADER_HEIGHT,
    width: 0.45 * SUB_HEADER_HEIGHT,
    resizeMode: "contain",
    tintColor: Config.Color.DFM_DARK_PURPLE,
  },
  shareText: {
    fontSize: 0.35 * SUB_HEADER_HEIGHT,
    color: Config.Color.DFM_DARK_PURPLE,
    marginRight: 4,
  },

  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: { justifyContent: "space-between", flex: 1 },
  instructionsCopyView: { marginBottom: hp(4) },
  instructionsCopyText: {
    color: Config.Color.WHITE,
    fontSize: hp(1.5),
  },
  userTouchable: {
    height: ROW_HEIGHT,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "5%",
    backgroundColor: Config.Color.WHITE,
    marginBottom: hp(2),
  },
  userDataView: {
    flexDirection: "row",
    alignItems: "center",
  },
  footerView: {
    height: hp(15),
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});
