import React, { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { ActionButton, Wizard } from "../components";
import Header from "../components/fixture-components/Header";
import { Config } from "../config";
import { hp } from "../dimensions";
import { SWIPE_GESTURES, useSwipe } from "../hooks";
import { Frame } from "./shared";
import { PlayAsYouGoRulesOverlay } from "./index";
import ScoreInput from "../components/fixture-components/ScoreInput";
import ScoreInputReadOnly from "../components/fixture-components/ScoreInputReadOnly";
import Badge from "../components/Badge";

const MAX_STEP_INDICATORS = 7;

// validates if a prediction is compliant to be submitted
function isPredictionValid(prediction) {
  return (
    prediction &&
    Number.isInteger(prediction.homeScore) &&
    prediction.homeScore < 10 &&
    Number.isInteger(prediction.awayScore) &&
    prediction.awayScore < 10
  );
}

// The user does not need to add a prediction
// However, if the user adds one, it needs to be properly validated.
function canProceed(isPAYG, p) {
  if (isPAYG) {
    return p === undefined ? true : isPredictionValid(p);
  } else {
    return isPredictionValid(p);
  }
}

const ClashPredictionsOverlay = ({
  exit,
  fixtures,
  isVisible,
  predictions,
  prev,
  setPredictions,
  next,
  trackingScreenName,
  canPAYG,
  myPredictions,
  selectedFixtureToScore,
}) => {
  const { Upcoming } = Config.Status;
  const [isRulesOverlaySelected, setIsRulesOverlaySelected] = useState(false);
  const [currentFixture, setCurrentFixture] = useState(0);
  const [currentStepStart, setCurrentStepStart] = useState(0);
  const [isFirstFixture, isFirstStep] = useState(0);
  const [isLastFixture, isLastStep] = useState(false);
  const [hasUsedCurrFixture, setHasUsedCurrFixture] = useState(false);

  const wizard = React.createRef();
  const homePredictionInput = useRef(null);
  const awayPredictionInput = useRef(null);
  const currentStep = ({ currentStep }) => setCurrentFixture(currentStep);

  useEffect(() => {
    if (currentFixture === currentStepStart && currentFixture > 0) {
      setCurrentStepStart(currentStepStart - 1);
    } else if (
      currentFixture === currentStepStart + MAX_STEP_INDICATORS - 1 &&
      currentFixture < fixtures.length - 1
    ) {
      setCurrentStepStart(currentStepStart + 1);
    }
  }, [currentFixture, currentStepStart, fixtures.length]);

  useEffect(() => {
    if (wizard.current && !hasUsedCurrFixture) {
      wizard.current.goTo(selectedFixtureToScore);
      setHasUsedCurrFixture(true);
    }
  }, [
    wizard,
    hasUsedCurrFixture,
    selectedFixtureToScore,
    setHasUsedCurrFixture,
  ]);

  // This have changed since the first implementation. Head to the git history if you need.
  const handleBetValueInput = (val, identifier, otherIdentifier) => {
    let sanitizedVal = Number.parseInt(val);
    const isValueValid = Number.isInteger(sanitizedVal);

    let _predictions = [...predictions];

    // If the input is valid, it will be assigned to the given identifier (homeScore/awayScore)
    // Else if the input is not valid, the given identifier (homeScore/awayScore) will not be considered, only the other one if it is set (awayScore/homeScore).
    // In the end, if there is no prediction for the given fixture, the prediction object will be set to undefined
    if (isValueValid) {
      _predictions[currentFixture] = {
        ..._predictions[currentFixture],
        [`${identifier}`]: sanitizedVal,
      };
    } else if (
      _predictions[currentFixture] !== undefined &&
      _predictions[currentFixture][`${otherIdentifier}`] !== undefined
    ) {
      _predictions[currentFixture] = {
        [`${otherIdentifier}`]:
          _predictions[currentFixture][`${otherIdentifier}`],
      };
    } else {
      _predictions[currentFixture] = undefined;
    }

    setPredictions(_predictions);

    //Implemented behaviour:
    // 1) After adding homeScore, focus moves to awayScore
    // 2) After adding awayScore, focus moves to homeScore of next fixture
    // 3) We implemented the happy path. Nobody cares if the user is stupid enough to add the awayScore first and then moves on

    if (isValueValid) {
      if (identifier === "homeScore") {
        awayPredictionInput?.current?.focus &&
          awayPredictionInput.current.focus();
      }

      if (identifier === "awayScore") {
        !isLastFixture && wizard?.current?.next && wizard.current.next();
      }
    }
  };

  const onSwipe = React.useCallback(
    (gesture) => {
      switch (gesture) {
        case SWIPE_GESTURES.SWIPE_LEFT:
          !isLastFixture && wizard?.current?.next && wizard.current.next();
          break;
        case SWIPE_GESTURES.SWIPE_RIGHT:
          !isFirstFixture && wizard?.current?.prev && wizard.current.prev();
          break;
        default:
      }
    },
    [wizard, isFirstFixture, isLastFixture]
  );

  const { onTouchStart, onTouchEnd } = useSwipe(onSwipe);

  return (
    <Frame
      isVisible={isVisible}
      headerText="SCORE PREDICTIONS"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={trackingScreenName}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <Wizard
            ref={wizard}
            steps={fixtures.map((f) => {
              let inputComponent;
              let canScore = Upcoming.some((s) => s.CODE.includes(f.status));

              if (
                (myPredictions !== undefined &&
                  myPredictions["results"][currentFixture] !== undefined) ||
                !canScore
              ) {
                let scores = !canScore
                  ? undefined
                  : myPredictions["results"][currentFixture];

                inputComponent = <ScoreInputReadOnly scores={scores} />;
              } else {
                inputComponent = (
                  <ScoreInput
                    homePredictionInput={homePredictionInput}
                    awayPredictionInput={awayPredictionInput}
                    predictions={predictions}
                    currentFixture={currentFixture}
                    handleBetValueInput={handleBetValueInput}
                  />
                );
              }

              return {
                content: (
                  <View
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    style={styles.fixtureCardView}
                  >
                    <Header
                      fixtureId={f.id}
                      inputComponent={inputComponent}
                      disableComponents={true}
                      variant={"score_predictions"}
                    />
                  </View>
                ),
              };
            })}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            currentStep={currentStep}
          />
          <View style={styles.fixtureSelectionView}>
            <Icon
              name="chevron-left"
              type="font-awesome"
              color={Config.Color.DFM_DARK_PURPLE}
              size={20}
              disabled={isFirstFixture}
              iconStyle={isFirstFixture && { display: "none" }}
              containerStyle={{ width: 30 }}
              onPress={() => wizard.current.prev()}
            />
            <View style={styles.stepIndicatorView}>
              {fixtures
                .slice(currentStepStart, currentStepStart + MAX_STEP_INDICATORS)
                .map((val, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={{
                        height: 10,
                        width: 10,
                        marginHorizontal: 5,
                        borderRadius: 5,
                        backgroundColor:
                          index + currentStepStart === currentFixture
                            ? Config.Color.DFM_YELLOW
                            : Config.Color.DFM_DARK_PURPLE,
                      }}
                      onPress={() =>
                        wizard.current.goTo(index + currentStepStart)
                      }
                    />
                  );
                })}
            </View>
            <Icon
              name="chevron-right"
              type="font-awesome"
              color={Config.Color.DFM_DARK_PURPLE}
              size={20}
              disabled={isLastFixture}
              iconStyle={isLastFixture && { display: "none" }}
              containerStyle={{ width: 30 }}
              onPress={() => wizard.current.next()}
            />
          </View>
          {canPAYG && (
            <>
              <View style={styles.playAsYouGoContainer}>
                <Badge
                  text={"NEW!"}
                  containerStyle={{
                    width: "12%",
                    justifyContent: "center",
                    paddingRight: 5,
                  }}
                />
                <Text
                  adjustsFontSizeToFit
                  numberOfLines={2}
                  style={{
                    color: Config.Color.DFM_DARK_PURPLE,
                    width: "88%",
                    fontSize: hp(2),
                  }}
                >
                  You can now add your score predictions anytime before the game
                  starts.{" "}
                  <TouchableOpacity
                    onPress={() => setIsRulesOverlaySelected(true)}
                  >
                    <Text style={styles.link}>Check the rules here.</Text>
                  </TouchableOpacity>
                </Text>
              </View>
              <PlayAsYouGoRulesOverlay
                isVisible={isRulesOverlaySelected}
                exit={() => setIsRulesOverlaySelected(false)}
              />
            </>
          )}
        </View>
        <View style={styles.footerView}>
          {prev && (
            <ActionButton onPress={prev} title="PREVIOUS" variant="secondary" />
          )}
          <ActionButton
            onPress={next}
            title={"CONFIRM"}
            disabled={predictions.some((p) => !canProceed(canPAYG, p))}
          />
        </View>
      </ScrollView>
    </Frame>
  );
};
export default ClashPredictionsOverlay;

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    flex: 1,
  },
  frameView: {
    width: "95%",
    alignItems: undefined,
  },
  bodyView: {
    flex: 1,
    paddingVertical: "2%",
  },
  fixtureCardView: {
    height: hp(23),
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    borderBottomColor: Config.Color.DFM_DARK_PURPLE,
    borderBottomWidth: 1,
  },
  fixtureSelectionView: {
    height: hp(6),
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: "5%",
  },
  fixturePredictionView: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingVertical: 15,
    backgroundColor: Config.Color.DFM_PINK,
  },
  stepIndicatorView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  footerView: {
    height: hp(15),
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  link: {
    color: Config.Color.DFM_DARK_PURPLE,
    textDecorationLine: "underline",
    fontFamily: "Roboto-Medium",
  },
  playAsYouGoContainer: {
    backgroundColor: Config.Color.DFM_YELLOW,
    borderRadius: 5,
    marginTop: "5%",
    width: "100%",
    paddingVertical: "2%",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
});
