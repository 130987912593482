import React from "react";
import { StyleSheet, View } from "react-native";
import { RobotoText } from "../../components";
import { Config } from "../../config";
import { hp } from "../../dimensions";

const Label = ({ isRequired, style, ...props }) => (
  <View style={styles.container}>
    <RobotoText
      style={[styles.text, style]}
      adjustsFontSizeToFit
      numberOfLines={1}
      variant="medium"
      {...props}
    />
    {isRequired && <RobotoText style={styles.requiredStyle}>*</RobotoText>}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: hp(0.8),
  },
  requiredStyle: {
    color: Config.Color.WHITE,
    fontSize: hp(1.8),
    lineHeight: hp(1.8),
  },
  text: {
    color: Config.Color.WHITE,
    fontSize: hp(2.1),
    lineHeight: hp(3.2),
  },
});

export default Label;
