import React from "react";
import { StyleSheet, View } from "react-native";
import { RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";

const RulesList = ({ list }) => {
  return (
    <View>
      {list.map((item, index) => (
        <View key={index} style={styles.ruleContainer}>
          <SquadaOneText style={styles.ruleNumber}>#{index + 1}</SquadaOneText>
          <RobotoText
            adjustsFontSizeToFit
            numberOfLines={3}
            style={styles.rulesText}
          >
            {item()}
          </RobotoText>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  ruleContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: "2%",
    marginBottom: "1.5%",
  },
  rulesTextHighlighted: {
    fontSize: hp(2),
    color: Config.Color.DFM_PINK,
  },
  rulesText: {
    fontSize: hp(2),
    color: Config.Color.WHITE,
  },
  ruleNumber: {
    fontSize: hp(3),
    color: Config.Color.DFM_PINK,
    marginRight: 15,
  },
});

export default RulesList;
