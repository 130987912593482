import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { Link, withRouter } from "../router";
import { checkLoginAndThen } from "./LoginSwitch";

const NavBar = ({ history, location, switchLoginState, userStruct }) => {
  const account = userStruct.account;
  return (
    <View style={styles.navBar}>
      <Link to="/">
        <Image
          style={styles.icon}
          source={
            location.pathname === "/"
              ? require("../assets/home-yellow.png")
              : require("../assets/home-white.png")
          }
        />
      </Link>
      <Link to="/leagues">
        <Image
          style={styles.icon}
          source={
            location.pathname === "/leagues"
              ? require("../assets/trophy-yellow.png")
              : require("../assets/trophy-white.png")
          }
        />
      </Link>
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() =>
          checkLoginAndThen(
            (_userStruct) => history.push("/user/" + _userStruct.username),
            switchLoginState,
            userStruct
          )
        }
      >
        <Image
          style={styles.icon}
          source={
            account && location.pathname === "/user/" + userStruct.username
              ? require("../assets/user-yellow.png")
              : require("../assets/user-white.png")
          }
        />
      </TouchableOpacity>
    </View>
  );
};

export default withRouter(NavBar);

const styles = StyleSheet.create({
  navBar: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: Dimensions.BAR_HEIGHT,
    width: "100%",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  iconContainer: {
    textDecorationLine: "none",
  },
  icon: {
    resizeMode: "contain",
    height: 0.65 * Dimensions.BAR_HEIGHT,
    width: 0.65 * Dimensions.BAR_HEIGHT,
  },
});
