import { AppRegistry } from "react-native";
import App from "./App";
import { name as appName } from "./app.json";
import "./icons"; // for append icons css
import Sentry from "./sentry";

AppRegistry.registerComponent(appName, () => Sentry.wrap(() => App));
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById("root"),
});
