import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {ENVIRONMENT, SENTRY_DSN} from '@env';

Sentry.init({
    environment:ENVIRONMENT.toLowerCase(),
    dsn:SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
});

export default Sentry;
