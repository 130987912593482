import React from "react";
import { View, Image } from "react-native";
import RobotoText from "../RobotoText";

const Team = ({ team, variant }) => {
  let teamLogo = team.logo ? team.logo : team.logo_url;

  const defaultStyle = {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  const imageContainerDefaultStyle = {
    height: "50%",
    width: "50%",
    padding: 5,
  };

  const imageDefaultStyle = {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  };

  const textContainerDefaultStyle = {
    alignContent: "center",
  };

  const textDefaultStyle = {
    textAlign: "left",
  };

  switch (variant) {
    case "icon_right":
      defaultStyle.flexDirection = "row-reverse";
      textDefaultStyle.textAlign = "right";
      textContainerDefaultStyle.flex = 3;
      imageContainerDefaultStyle.flex = 1;
      break;
    case "centered":
      defaultStyle.flexDirection = "column";
      defaultStyle.height = "50%";
      textDefaultStyle.textAlign = "center";
      break;
    default:
      textContainerDefaultStyle.flex = 3;
      imageContainerDefaultStyle.flex = 1;
      break;
  }

  return (
    <View style={defaultStyle}>
      <View style={imageContainerDefaultStyle}>
        <Image style={imageDefaultStyle} source={{ uri: teamLogo }} />
      </View>
      <View style={textContainerDefaultStyle}>
        <RobotoText
          adjustsFontSizeToFit
          numberOfLines={2}
          style={textDefaultStyle}
          variant="match_team"
        >
          {team.name}
        </RobotoText>
      </View>
    </View>
  );
};
export default Team;
