import React from "react";
import { Text } from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";

const RubikText = ({ variant, style, ...otherProps }) => {
  let defaultStyles = {};

  switch (variant) {
    case "h1":
      defaultStyles.fontFamily = "Rubik-Bold";
      defaultStyles.fontSize = hp(3.4);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "h2":
      defaultStyles.fontFamily = "Rubik-Medium";
      defaultStyles.fontSize = hp(2.8);
      defaultStyles.color = Config.Color.DFM_LIGHT_PURPLE;
      break;
    case "h3":
      defaultStyles.fontFamily = "Rubik-Bold";
      defaultStyles.fontSize = hp(2.2);
      defaultStyles.color = Config.Color.WHITE;
      break;
    case "bold":
      defaultStyles.fontFamily = "Rubik-Bold";
      break;
    case "medium":
      defaultStyles.fontFamily = "Rubik-Medium";
      break;
    case "hyperlink":
      defaultStyles.fontFamily = "Rubik-Regular";
      defaultStyles.textDecorationLine = "underline";
      defaultStyles.color = Config.Color.DFM_PINK;
      defaultStyles.fontSize = hp(2);
      defaultStyles.textAlignVertical = "center";
      break;
    case "small_message":
      defaultStyles.fontFamily = "Rubik-Regular";
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(1.9);
      defaultStyles.textAlignVertical = "center";
      break;
    case "powered":
      defaultStyles.fontFamily = "Rubik-Regular";
      defaultStyles.color = Config.Color.WHITE;
      defaultStyles.fontSize = hp(1.9);
      defaultStyles.textAlignVertical = "center";
      break;
    case "regular":
    default:
      defaultStyles.fontFamily = "Rubik-Regular";
      break;
  }

  return <Text {...otherProps} style={[defaultStyles, style]} />;
};

export default RubikText;
