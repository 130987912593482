import React from "react";
import { StyleSheet, View, Image, Platform } from "react-native";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { StatusScore, Team } from "./match-components";
import { getMatchStatus } from "./match-components/Details";

const FixtureCard = ({
  fixture,
  variant,
  statusContainerBackground,
  dynamicContainer,
}) => {
  let details = getMatchStatus(fixture);
  let competitionLogo = fixture.competition.logo_url
    ? fixture.competition.logo_url
    : fixture.competition.league.logoUrl;

  return (
    <View style={styles.fixtureContainer}>
      {Platform.OS !== "ios" && (
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            source={{
              uri: competitionLogo,
            }}
          />
        </View>
      )}
      <View style={styles.teamContainer}>
        <Team
          team={fixture.home_team ? fixture.home_team : fixture.homeTeam}
          fixtureHeight={Dimensions.FIXTURE_HEIGHT}
          variant={"icon_right"}
        />
      </View>
      <View style={[styles.statusContainer, statusContainerBackground]}>
        <StatusScore statusDetails={details} variant={variant} />
      </View>
      <View style={styles.teamContainer}>
        <Team
          team={fixture.away_team ? fixture.away_team : fixture.awayTeam}
          fixtureHeight={Dimensions.FIXTURE_HEIGHT}
        />
      </View>
      <View style={styles.finalContainer}>{dynamicContainer}</View>
    </View>
  );
};
export default FixtureCard;

const styles = StyleSheet.create({
  fixtureContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: Dimensions.FIXTURE_HEIGHT,
    marginHorizontal: "2.5%",
    marginVertical: 2,
    borderWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
  },
  logoContainer: {
    backgroundColor: Config.Color.WHITE,
    width: "15%",
    height: "100%",
    borderRightWidth: 2,
    borderRightColor: Config.Color.DFM_DARK_PURPLE,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "60%",
    width: "100%",
    resizeMode: "contain",
  },
  teamContainer: {
    backgroundColor: Config.Color.WHITE,
    width: "26%",
    height: "100%",
    justifyContent: "center",
    flexGrow: 1,
  },
  statusContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "18%",
    height: "100%",
    flexDirection: "column",
  },
  finalContainer: {
    width: "15%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
});
