import { setAuthToken } from '../auth';

export async function loginInGate(did) {
  return await fetch("https://gate.clashya.com/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + did,
    },
  })
  .then((res) => res.json())
  .then((res) => setAuthToken(res.token))
  .catch(console.error);
}

export async function refreshLoginInGate(jwt) {
  return await fetch("https://gate.clashya.com/auth/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
  })
  .then((res) => res.json())
  .then((res) => setAuthToken(res.token))
  .catch(console.error);
}

export async function logoutFromGate(jwt) {
  return await fetch("https://gate.clashya.com/auth/logout", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
  }).catch(console.error);
}
