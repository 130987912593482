import React, { useState } from "react";
import {
  ActivityIndicator,
  View,
  StyleSheet,
  Image,
  ScrollView,
} from "react-native";
import { gql, useMutation } from "@apollo/client";
import { ActionButton, RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { isAlphanumeric } from "../formatting";
import { Frame, Label, TextInput } from "./shared";
import { logEvent } from "../firebase";
import { BranchEvent } from "../branch";
import { EVENTS } from "../firebase/events";
import { addWallet } from "../web3/wallets";

const CREATE_USER = gql`
  mutation CreateUser($username: String!, $email: String!, $photo: String) {
    createUser(username: $username, email: $email, photo: $photo) {
      id
      username
      email
      followers {
        username
      }
      following {
        username
      }
      photo
    }
  }
`;
export default function SetUsernameOverlay(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUsername] = useState("");
  const { displaySetUsername, fetchDataAfterRegistration, userStruct } = props;
  const { account, token, email, wallet } = userStruct;

  const [createUser] = useMutation(CREATE_USER);

  const submitUsername = () => {
    setIsLoading(true);
    logEvent(EVENTS.SET_USERNAME_START);
    createUser({
      variables: {
        username: username,
        email: email ?? "",
      },
      context: {
        headexrs: {
          "user-public-address": account,
          Authentication: "Bearer " + token,
        },
      },
    })
      .then((response) => {
        addWallet(response.data.createUser.id, account, wallet).then(() => {
          logEvent(EVENTS.SET_USERNAME_SUCCESS);
          if (BranchEvent) {
            const event = new BranchEvent(BranchEvent.CompleteRegistration);
            event.logEvent().catch(console.error);
          }
          fetchDataAfterRegistration(username, email, account).then(() => {
            setIsLoading(false);
            setUsername("");
          });
        });
      })
      .catch((error) => {
        logEvent(EVENTS.SET_USERNAME_ERROR);
        const { graphQLErrors } = error;
        setIsLoading(false);
        setUsername("");
        setErrorMessage(
          graphQLErrors[0]?.extensions?.validation?.username ??
            "Something went wrong. Try again."
        );
      });
  };

  return (
    <Frame
      isVisible={!!displaySetUsername}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName="set_username"
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {!Boolean(isLoading) && (
          <View style={styles.bodyView}>
            <View style={styles.welcomeContainer}>
              <SquadaOneText style={styles.welcomeText}>
                WELCOME TO
              </SquadaOneText>
              <Image
                source={require("../assets/defyme-name-without-io.png")}
                style={styles.logo}
              />
            </View>
            <View style={styles.formContainer}>
              <Label isRequired style={styles.labelText}>
                SET YOUR USERNAME
              </Label>
              <TextInput value={username} onChangeText={setUsername} />
              {Boolean(errorMessage) && (
                <RobotoText style={styles.errorMessage}>
                  {errorMessage}
                </RobotoText>
              )}
            </View>
          </View>
        )}
        {Boolean(isLoading) && (
          <View style={styles.bodyView}>
            <View style={styles.submittingContainer}>
              <RobotoText style={styles.submittingMessage}>
                Setting your username.
              </RobotoText>
              <ActivityIndicator size="large" color={Config.Color.DFM_GREEN} />
            </View>
          </View>
        )}

        <View style={styles.footerView}>
          <ActionButton
            title="NEXT"
            onPress={submitUsername}
            disabled={!username || !isAlphanumeric(username) || isLoading}
          />
        </View>
      </ScrollView>
    </Frame>
  );
}

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    flex: 1,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
    width: "100%",
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: hp(5),
  },
  welcomeText: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(4),
  },
  logo: {
    height: hp(10),
    width: hp(30),
    resizeMode: "contain",
  },
  formContainer: {
    width: "100%",
    flexDirection: "column",
  },
  labelText: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Light",
  },
  submittingMessage: {
    fontSize: hp(3),
    marginBottom: "7%",
    color: Config.Color.WHITE,
  },
  submittingContainer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    marginTop: 5,
    color: Config.Color.DFM_PINK,
    fontSize: hp(2),
    fontFamily: "Roboto-Regular",
  },
});
