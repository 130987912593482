import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import RobotoText from "./RobotoText";

const ToggleButton = ({
  options,
  values,
  selected,
  setSelected,
  descriptions,
}) => {
  const buttonWidth = Dimensions.MAX_WIDTH / options.length;
  let showDescription = descriptions !== undefined;

  return (
    <View style={styles.buttonsContainer}>
      {options.map((value, index) => (
        <TouchableOpacity
          style={
            selected === values[value]
              ? [styles.button, { width: buttonWidth }, styles.buttonSelected]
              : [styles.button, { width: buttonWidth }]
          }
          onPress={() => setSelected(values[value])}
          key={index}
        >
          <RobotoText
            style={
              selected === values[value]
                ? [styles.buttonTitleSelected]
                : [styles.buttonTitle]
            }
          >
            {value}
          </RobotoText>
          {showDescription && (
            <RobotoText
              adjustsFontSizeToFit
              numberOfLines={1}
              style={styles.description}
            >
              {descriptions[value]}
            </RobotoText>
          )}
        </TouchableOpacity>
      ))}
    </View>
  );
};
export default ToggleButton;

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    backgroundColor: Config.Color.WHITE,
    width: "100%",
    height: hp(6),
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSelected: {
    backgroundColor: Config.Color.YELLOW,
  },
  buttonTitle: {
    fontFamily: "Roboto-Regular",
    fontSize: hp(1.5),
  },
  buttonTitleSelected: {
    fontSize: hp(1.5),
    fontFamily: "Roboto-Bold",
  },
  buttonSubtitle: {
    fontSize: hp(1.5),
  },
  description: {
    fontSize: hp(1.5),
  },
});
