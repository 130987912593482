import React, { useState } from "react";
import {
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { RobotoText, SquadaOneText } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";
import IntroOverlay from "../overlays/IntroOverlay";

const BannerHomePage = () => {
  const [isIntroSelected, setIsIntroSelected] = useState(false);

  return (
    <>
      <TouchableOpacity
        style={styles.touchableOpacity}
        onPress={() => setIsIntroSelected(true)}
      >
        <View style={styles.container}>
          <ImageBackground
            source={require("../assets/banner-background.png")}
            style={styles.imageBackground}
          >
            <View style={styles.content}>
              <SquadaOneText
                adjustsFontSizeToFit
                numberOfLines={1}
                style={styles.offerValue}
              >
                BEAT YOUR FRIENDS SCORE PREDICTIONS
              </SquadaOneText>
              <View style={styles.taglineContainer}>
                <RobotoText
                  adjustsFontSizeToFit
                  numberOfLines={2}
                  style={styles.tagline}
                  variant={"bold"}
                >
                  The player with the most correct scores takes all the tokens
                </RobotoText>
              </View>
            </View>
          </ImageBackground>
        </View>
      </TouchableOpacity>
      <IntroOverlay
        isVisible={isIntroSelected}
        endStep={() => setIsIntroSelected(false)}
        buttonText={"CLOSE"}
      />
    </>
  );
};

export default BannerHomePage;

const styles = StyleSheet.create({
  container: {
    height: hp(15),
    width: "100%",
  },
  imageBackground: {
    width: "100%",
    height: "100%",
  },
  content: {
    flexDirection: "column",
    marginTop: "5%",
  },
  offerValue: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(3.5),
    textAlign: "center",
    marginHorizontal: 10
  },
  taglineContainer: {
    marginTop: "2%",
    width: "80%",
    alignSelf:'center'
  },
  tagline: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    textAlign: "center",
  },
});
