import React, { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";
import { FollowButton, RobotoText } from "./index";

const UserItem = ({ user, namePress, exit, userStruct, props }) => {
  const [itemHover, setItemHover] = useState(false);

  let isUserLoggedInListed = Boolean(user.username === userStruct.username);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.playerContainer}>
        <Image style={styles.userIcon} source={require("../assets/user.png")} />
        <RobotoText
          style={{
            ...(itemHover && {
              textDecoration: "underline",
              cursor: "pointer",
            }),
          }}
          variant={"leaderboard_player"}
          adjustsFontSizeToFit
          numberOfLines={1}
          onMouseEnter={() => {
            exit && namePress && setItemHover(true);
          }}
          onMouseLeave={() => {
            exit && namePress && setItemHover(false);
          }}
          onPress={() => {
            exit();
            namePress();
          }}
        >
          {user.username}
        </RobotoText>
      </View>
      <View style={styles.actionContainer}>
        {!Boolean(isUserLoggedInListed) && (
          <FollowButton
            loggedInUserStruct={userStruct}
            props={props}
            otherUsername={user.username}
          />
        )}
      </View>
    </View>
  );
};

export default UserItem;

const styles = StyleSheet.create({
  mainContainer: {
    height: hp(7),
    width: "100%",
    flexDirection: "row",
    marginVertical: 2,
    backgroundColor: Config.Color.WHITE,
  },

  playerContainer: {
    backgroundColor: Config.Color.WHITE,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    flexDirection: "row",
    flex: 4,
  },

  userIcon: {
    height: hp(3.5),
    width: hp(3.5),
    marginHorizontal: 10,
    resizeMode: "contain",
  },

  actionContainer: {
    flex: 2,
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    paddingRight: 10,
  },
});
