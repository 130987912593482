import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import Dimensions, { hp } from "../../dimensions";
import SquadaOneText from "../SquadaOneText";
import Score from "./Score";

const StatusScore = ({ variant, statusDetails }) => {
  const defaultStyles = {};
  let statusText;
  let mainTextComponent;
  let mainTextScore = <Score variant={variant} statusDetails={statusDetails} />;
  let statusTextStyle;

  switch (variant) {
    case "match_stats_overlay":
      statusTextStyle = styles.statusTextOverlay;
      break;
    default:
      statusTextStyle = styles.statusText;
      break;
  }

  if (statusDetails) {
    if (statusDetails.status === "LIVE") {
      statusText = "Live";
      defaultStyles.height = 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT;
      defaultStyles.width = 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT;
      defaultStyles.borderRadius = 10000;
      defaultStyles.backgroundColor = Config.Color.DFM_GREEN;
      defaultStyles.marginRight = 5;
      mainTextComponent = mainTextScore;
    } else if (statusDetails.status === "UPCOMING") {
      statusText = variant === "home_page" ? "Starts at" : statusDetails.date;
      mainTextComponent = (
        <SquadaOneText adjustsFontSizeToFit style={styles.mainText}>
          {statusDetails.time}
        </SquadaOneText>
      );
    } else if (statusDetails.status === "FINISHED") {
      statusText = "Ended";
      mainTextComponent = mainTextScore;
    } else if (
      statusDetails.status === "TBA" ||
      statusDetails.status === "TBD"
    ) {
      statusText = "";
      mainTextComponent = (
        <SquadaOneText adjustsFontSizeToFit style={styles.mainText}>
          {statusDetails.status}
        </SquadaOneText>
      );
    }
  }

  return (
    <View style={styles.liveOrUpcomingContainer}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View style={[defaultStyles]} />
        <SquadaOneText
          adjustsFontSizeToFit
          numberOfLines={1}
          style={statusTextStyle}
        >
          {statusText}
        </SquadaOneText>
      </View>
      {mainTextComponent}
    </View>
  );
};
export default StatusScore;

const styles = StyleSheet.create({
  liveOrUpcomingContainer: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  statusText: {
    fontSize: hp(1.5),
    color: Config.Color.WHITE,
    overflow: "visible",
  },
  statusTextOverlay: {
    fontSize: hp(1.5),
    color: Config.Color.DFM_DARK_PURPLE,
    overflow: "visible",
  },
  mainText: {
    fontSize: hp(3),
    color: Config.Color.DFM_YELLOW,
  },
});
