import {
  BLOCKCHAIN,
  ENVIRONMENT,
  NON_NATIVE_TOKENS,
  TRANSAK_API_KEY,
} from "@env";
import { Config } from "../../config";
import Dimensions from "../../dimensions";
import getNetworkCaption from "./getNetworkCaption";

function parseEnvironment(ENVIRONMENT) {
  return ENVIRONMENT === "PRODUCTION" ? "PRODUCTION" : "STAGING";
}

const TransakConfig = {
  apiKey: TRANSAK_API_KEY, // Your API Key
  environment: parseEnvironment(ENVIRONMENT), // STAGING/PRODUCTION
  network: getNetworkCaption(BLOCKCHAIN),
  cryptoCurrencyList: BLOCKCHAIN + "," + NON_NATIVE_TOKENS,
  defaultCryptoCurrency: NON_NATIVE_TOKENS
    ? NON_NATIVE_TOKENS.split(",")[0]
    : BLOCKCHAIN,
  fiatCurrency: "EUR",
  defaultFiatAmount: 30,
  defaultPaymentMethod: "credit_debit_card",
  disablePaymentMethods: "sepa_bank_transfer",
  exchangeScreenTitle: "TOP UP your wallet to start DEFYING your friends",
  themeColor: Config.Color.DFM_DARK_PURPLE,
  widgetHeight: 0.9 * Dimensions.MAX_HEIGHT + "px",
  widgetWidth: 0.9 * Dimensions.MAX_WIDTH + "px",
};
export default TransakConfig;
