import React from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { RobotoText, SquadaOneText } from "../../../components";
import { Config } from "../../../config";
import Dimensions, { hp } from "../../../dimensions";

const TimelineItem = ({
  mainText,
  secondaryText,
  iconSource,
  hasSecondaryText,
  isHome,
  minutes,
  minutesExtra,
}) => {
  const textAlignmentStyle = isHome
    ? { textAlign: "right" }
    : { textAlign: "left" };

  return (
    <View
      style={
        isHome
          ? styles.specificEventContainerHome
          : styles.specificEventContainerAway
      }
    >
      <View style={isHome ? styles.circleHome : styles.circleAway} />
      <View style={styles.time}>
        <SquadaOneText style={[styles.minutes]}>
          {minutes}
          {Boolean(minutesExtra) && "+" + minutesExtra}'
        </SquadaOneText>
      </View>
      <View style={styles.iconContainer}>
        <Image style={styles.icon} source={iconSource} />
      </View>
      <View style={styles.detailsContainer}>
        <RobotoText
          numberOfLines={1}
          style={[styles.mainText, textAlignmentStyle]}
        >
          {mainText}
        </RobotoText>
        {Boolean(hasSecondaryText) && (
          <RobotoText
            numberOfLines={1}
            style={[styles.secondaryText, textAlignmentStyle]}
          >
            {secondaryText}
          </RobotoText>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  specificEventContainerAway: {
    width: "50%",
    paddingVertical: 5,
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 0.5 * Dimensions.MAX_WIDTH - 6,
    borderLeftWidth: 1,
    borderLeftColor: Config.Color.GREY_67,
  },
  specificEventContainerHome: {
    width: "50%",
    paddingVertical: 5,
    alignItems: "center",
    flexDirection: "row-reverse",
    borderRightWidth: 1,
    borderRightColor: Config.Color.GREY_67,
  },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginHorizontal: 4,
    height: hp(2.3),
    width: hp(2.3),
    resizeMode: "contain",
  },
  detailsContainer: {
    flex: 1,
    flexGrow: 1,
  },
  mainText: {
    width: "100%",
    fontSize: hp(1.8),
    overflow: "hidden",
  },
  secondaryText: {
    width: "100%",
    fontSize: hp(1.5),
    color: Config.Color.GREY,
  },
  circleHome: {
    height: 9,
    width: 9,
    borderRadius: 10000,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    right: Platform.OS === "web" ? -5 : 4,
  },
  circleAway: {
    height: 9,
    width: 9,
    borderRadius: 10000,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    left: -5,
  },
  minutes: {
    fontSize: hp(2.3),
    color: Config.Color.DFM_PINK,
  },
});

export default TimelineItem;
