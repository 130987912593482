import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Web3 from "web3";
import { ActionButton, RobotoText } from "../components";
import { Config, NULL_ADDRESS } from "../config";
import { TransactionContext } from "../contexts/TransactionContext";
import { hp } from "../dimensions";
import { getWeb3 } from "../web3";
import {
  getTokenInst,
  parseToken,
  sendMetaTxWithFallback,
  sendTransaction,
} from "../web3/utils";
import { AmountSelector, Frame, Label, DataText, TextInput } from "./shared";
import ErrorOverlay, { ErrorMessages } from "./ErrorOverlay";
import LoadingOverlay from "./LoadingOverlay";
import MessageOverlay from "./MessageOverlay";

const SendFundsOverlay = ({
  isVisible,
  balance,
  exit,
  tokenAddress,
  userStruct,
}) => {
  const { setTransactionConcluded } = useContext(TransactionContext);
  const [amount, setAmount] = useState(0);
  const [isSendCompleted, setIsSendCompleted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [txSubmittingMessageIndex, setTxSubmittingMessageIndex] = useState(-1);
  const [errorOverlayMessage, setErrorOverlayMessage] = useState(null);

  const token = Config.Referee.Tokens.BY_ADDRESS[tokenAddress];

  useEffect(() => {
    if (
      txSubmittingMessageIndex >= 0 &&
      txSubmittingMessageIndex < Config.TX_SUBMITTING_MSGS.length - 1
    ) {
      const intervalID = setInterval(
        () => setTxSubmittingMessageIndex((i) => i + 1),
        Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex].interval
      );
      return () => clearInterval(intervalID);
    }
  }, [txSubmittingMessageIndex]);

  const sendFunds = () => {
    setTxSubmittingMessageIndex(0);
    setIsSending(true);
    const { account, wallet } = userStruct;
    const web3 = getWeb3(wallet);
    let finalMethod;
    //if it is to transfer the native token
    if (tokenAddress === NULL_ADDRESS) {
      finalMethod = sendTransaction(
        null,
        recipientAddress,
        account,
        amount,
        web3
      );
    } else {
      const tx = getTokenInst(web3, token).methods.transfer(
        recipientAddress,
        String(amount)
      );
      finalMethod = sendMetaTxWithFallback(
        //We support the gas costs of every tx at this moment, therefore the 'true' invariably
        true,
        tx,
        token,
        account,
        web3,
        tokenAddress
      );
    }
    finalMethod
      .finally(() => {
        setIsSending(false);
        setTxSubmittingMessageIndex(-1);
      })
      .then(() => {
        setIsSendCompleted(true);
      })
      .catch((error) => {
        console.error(error);
        setErrorOverlayMessage(ErrorMessages.TRANSACTION_REVERTED);
      });
  };

  if (Boolean(errorOverlayMessage)) {
    return (
      <ErrorOverlay
        exit={() => {
          exit();
          setTxSubmittingMessageIndex(-1);
          setErrorOverlayMessage(null);
        }}
        isVisible
        text={errorOverlayMessage}
      />
    );
  } else if (isSending) {
    return (
      <LoadingOverlay
        exit={() => {
          exit();
          setTxSubmittingMessageIndex(-1);
          setIsSending(false);
        }}
        isVisible
        loadingColor={Config.Color.DFM_PINK}
        title={"SEND " + token.caption}
        text={Config.TX_SUBMITTING_MSGS[txSubmittingMessageIndex]?.text}
      />
    );
  } else if (isSendCompleted) {
    return (
      <MessageOverlay
        exit={() => {
          exit();
          setTxSubmittingMessageIndex(-1);
          setIsSendCompleted(false);
          setTransactionConcluded(true);
        }}
        imageSource={require("../assets/thumbs-up.png")}
        isVisible
        text="Your funds have been transferred."
        title="FUNDS SENT"
      />
    );
  }
  return (
    <Frame
      isVisible={isVisible}
      headerText={"SEND " + token.caption}
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <View style={styles.disclaimer}>
            <RobotoText style={styles.disclaimerMessage}>
              The WELCOME BONUS cannot be sent to another wallet. It can only be
              used to create or join a league.
            </RobotoText>
          </View>
          <View>
            <Label style={styles.labelText}>Current wallet balance</Label>
            <DataText style={styles.dataText}>
              {parseToken(balance, token) + " " + token.caption}
            </DataText>
            <Label style={styles.labelText}>Select amount to send</Label>
            <AmountSelector
              amount={amount}
              label={<Label>AMOUNT TO SEND</Label>}
              maximumAmount={balance}
              setAmount={setAmount}
              showMessage={() =>
                setErrorOverlayMessage(ErrorMessages.INSUFFICIENT_FUNDS)
              }
              token={token}
            />
            <Label style={styles.labelText}>Recipient wallet address</Label>
            <TextInput
              value={recipientAddress}
              onChangeText={setRecipientAddress}
            />
          </View>
        </View>
        <View style={styles.footerView}>
          <ActionButton
            title="SEND"
            onPress={sendFunds}
            disabled={
              amount <= 0 ||
              amount > balance ||
              !Web3.utils.isAddress(recipientAddress)
            }
          />
        </View>
      </ScrollView>
    </Frame>
  );
};
export default SendFundsOverlay;

const styles = StyleSheet.create({
  overlay: { padding: 0, flex: 1 },
  frameView: {
    width: "95%",
    alignItems: undefined,
  },
  dataText: { fontSize: hp(1.6) },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  disclaimer: {
    backgroundColor: Config.Color.DFM_YELLOW,
    borderRadius: 5,
    flexDirection: "row",
    alignSelf: "center",
    width: "100%",
    paddingVertical: "2%",
  },
  disclaimerMessage: {
    color: Config.Color.DFM_DARK_PURPLE,
    width: "100%",
    fontSize: hp(1.7),
    marginLeft: 5,
  },
  labelText: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Light",
  },
});
