import React, { useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import AllowanceOfferOverlay from "../overlays/AllowanceOfferOverlay";

const AllowanceOverlayTrigger = ({ triggerComponent }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <TouchableOpacity
        style={styles.touchableOpacity}
        onPress={() => setShowOverlay(true)}
      >
        {triggerComponent}
      </TouchableOpacity>
      <AllowanceOfferOverlay
        isVisible={showOverlay}
        exit={() => setShowOverlay(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  touchableOpacity: { flexGrow: 1, width: "100%" },
});

export default AllowanceOverlayTrigger;
