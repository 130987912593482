import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import Dimensions from "../../dimensions";
import {
  formatFixtureDate,
  parseElapsedMinutes,
  adjustDates,
  formatDayTime,
} from "../../formatting";
import OswaldText from "../OswaldText";
import RubikText from "../RubikText";

const DateTime = ({ fixture, onlyHours, textStyle }) => {
  const {
    byCode,
    ExtraTimeBreak,
    Finished,
    FullTimeBreak,
    HalfTime,
    Live,
    Penalties,
    Postponed,
    Upcoming,
  } = Config.Status;
  fixture = adjustDates(fixture);
  const { date, status } = fixture;
  const updated_at = fixture.updated_at ?? fixture.updatedAt;
  if (Live.some((s) => s.CODE.includes(status))) {
    let text;
    if (
      [HalfTime, FullTimeBreak, ExtraTimeBreak, Penalties].some((s) =>
        s.CODE.includes(status)
      )
    ) {
      text = byCode[status].SHORT;
    } else {
      const updatedAt = new Date(updated_at);
      const { minutes, extraMinutes } = parseElapsedMinutes(updatedAt, status);
      text = `${minutes}${extraMinutes ? "+" + extraMinutes : ""}'`;
    }
    return (
      <View
        style={{
          height: "70%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Config.Color.DFM_GREEN,
          paddingHorizontal: 8,
        }}
      >
        <RubikText
          adjustsFontSizeToFit
          numberOfLines={1}
          variant="regular"
          style={[
            styles.text,
            {
              fontSize: 0.5 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT,
              color: Config.Color.DFM_DARK_PURPLE,
              paddingRight: 5,
            },
          ]}
        >
          Live Now {text}
        </RubikText>
        <View
          style={{
            height: 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT,
            width: 0.35 * 0.7 * 0.38 * Dimensions.CARD_HEIGHT,
            borderRadius: 10000,
            backgroundColor: Config.Color.DFM_DARK_PURPLE,
          }}
        />
      </View>
    );
  } else if (Upcoming.some((s) => s.CODE.includes(status))) {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!onlyHours && (
          <OswaldText
            style={[styles.text, textStyle, { marginRight: 3 }]}
            adjustsFontSizeToFit
            numberOfLines={1}
          >
            {formatFixtureDate(date, true) + ","}
          </OswaldText>
        )}
        <View style={onlyHours && { maxWidth: 0.25 * Dimensions.MAX_WIDTH }}>
          <OswaldText
            style={[styles.text, textStyle]}
            adjustsFontSizeToFit
            numberOfLines={1}
          >
            {formatDayTime(date)}
          </OswaldText>
        </View>
      </View>
    );
  } else if (Finished.some((s) => s.CODE.includes(status))) {
    return (
      <OswaldText
        style={[styles.text, textStyle]}
        adjustsFontSizeToFit
        numberOfLines={1}
      >
        {formatFixtureDate(date, true)}
      </OswaldText>
    );
  } else if (Postponed.CODE.includes(status)) {
    return (
      <OswaldText
        style={[styles.text, textStyle]}
        adjustsFontSizeToFit
        numberOfLines={1}
      >
        Postponed
      </OswaldText>
    );
  } else return null;
};
export default DateTime;

const styles = StyleSheet.create({
  text: {
    fontSize: 0.16 * Dimensions.CARD_HEIGHT,
    color: Config.Color.CY_PURPLE,
  },
});
