import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import { Loading, OswaldText } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { getAgeByDateOfBirth } from "../formatting";

const PlayerScreen = ({ history, getIsFavorite, match, toggleFavorite }) => {
  const [player, setPlayer] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const id = match.params.id;
    const fetchPlayer = () => {
      fetch(Config.COACH_URL + `/player/${id}`)
        .then((res) => res.json())
        .then((p) => {
          setPlayer({
            id: id,
            name: p.name,
            position: p.position,
            uniformNumber: p.uniform_number,
            dateOfBirth: p.date_of_birth,
            imageURL: p.photo,
            team: p.team,
            location: p.location,
            url: Config.FavoritesCategories.Players.URL + "/" + id,
          });
        });
    };
    setIsFavorite(getIsFavorite(Config.FavoritesCategories.Players.TITLE, id));
    fetchPlayer();
    const intervalId = setInterval(fetchPlayer, 20000);
    return () => clearInterval(intervalId);
  }, [getIsFavorite, match.params.id]);

  const pressStar = () => {
    toggleFavorite(
      Config.FavoritesCategories.Players.TITLE,
      player,
      isFavorite
    );
    setIsFavorite(!isFavorite);
  };

  if (!player) return <Loading />;
  return (
    <View style={styles.main}>
      <View style={styles.top}>
        <TouchableOpacity onPress={pressStar} style={styles.starContainer}>
          <Image
            style={styles.star}
            source={
              isFavorite
                ? require("../assets/favorites-filled.png")
                : require("../assets/favorites.png")
            }
          />
        </TouchableOpacity>
        <View style={styles.playerContainer}>
          <Image style={styles.playerImage} source={{ uri: player.imageURL }} />
          <OswaldText style={[styles.playerName, { paddingTop: hp(1) }]}>
            {player.name}
          </OswaldText>
        </View>
      </View>
      <View style={styles.specsContainer}>
        <View style={styles.specsRow}>
          <TouchableOpacity
            style={styles.spec}
            onPress={() =>
              !!player.team && history.push("/team/" + player.team.id)
            }
          >
            {!!player.team && (
              <Image
                style={styles.teamImage}
                source={{ uri: player.team.logo_url }}
              />
            )}
            <OswaldText
              adjustsFontSizeToFit
              numberOfLines={1}
              style={styles.text}
            >
              {!!player.team ? player.team.name : "Free agent"}
            </OswaldText>
          </TouchableOpacity>
          <View style={styles.spec}>
            {!!player.uniformNumber && (
              <OswaldText style={[styles.text, { fontSize: hp(5) }]}>
                #{player.uniformNumber}
              </OswaldText>
            )}
            {!!player.position && (
              <OswaldText style={styles.text}>{player.position}</OswaldText>
            )}
          </View>
        </View>
        <View style={styles.specsRow}>
          <View style={styles.spec}>
            {!!player.dateOfBirth && (
              <OswaldText style={[styles.text, { fontSize: hp(3.5) }]}>
                {getAgeByDateOfBirth(new Date(player.dateOfBirth))} years
              </OswaldText>
            )}
            {!!player.dateOfBirth && (
              <OswaldText style={[styles.text, { fontSize: hp(2) }]}>
                {player.dateOfBirth}
              </OswaldText>
            )}
          </View>
          <View style={styles.spec}>
            {!!player.location && (
              <Image
                style={styles.nationalityImage}
                source={{ uri: player.location.flag }}
              />
            )}
            {!!player.location && (
              <OswaldText
                adjustsFontSizeToFit
                numberOfLines={1}
                style={styles.text}
              >
                {player.location.name}
              </OswaldText>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
export default PlayerScreen;

const styles = StyleSheet.create({
  main: {
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
  top: {
    height: "35%",
    width: "100%",
    borderTopWidth: 5,
    borderTopColor: Config.Color.DFM_DARK_PURPLE,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  starContainer: {
    flexDirection: "column",
    height: "20%",
    width: "10%",
    paddingTop: "2%",
    paddingRight: "2%",
    alignSelf: "flex-end",
  },
  star: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
    tintColor: Config.Color.WHITE,
  },
  playerContainer: {
    height: "85%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: hp(3),
  },
  playerImage: {
    height: "70%",
    width: "100%",
    resizeMode: "contain",
  },
  playerName: {
    fontSize: hp(2.4),
    fontWeight: "normal",
    paddingTop: "2%",
    fontFamily: "Oswald-Light",
    color: Config.Color.WHITE,
  },
  text: {
    fontSize: hp(2.4),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  specsContainer: {
    height: "65%",
    width: "100%",
    alignItems: "center",
    paddingTop: "20%",
    backgroundColor: Config.Color.LIGHT_GREY,
  },
  specsRow: {
    flexDirection: "row",
  },
  spec: {
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 0.4 * Dimensions.MAX_WIDTH,
    height: 0.32 * Dimensions.MAX_WIDTH,
    borderWidth: 0.5,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    backgroundColor: Config.Color.WHITE,
  },
  teamImage: {
    height: "60%",
    width: "100%",
    resizeMode: "contain",
  },
  nationalityImage: {
    height: "40%",
    width: "100%",
    resizeMode: "contain",
  },
});
