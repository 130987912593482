import React from "react";
import PenaltyImage from "../../../assets/penalty.png";
import MissedPenaltyImage from "../../../assets/missed-penalty.png";
import TimelineItem from "./TimelineItem";

export default function Penalty({ event, isHome }) {
  const { player } = event;

  let hasSecondaryText = false;
  let iconSource = PenaltyImage;
  let secondaryText;
  if (event.missed) {
    hasSecondaryText = true;
    iconSource = MissedPenaltyImage;
    secondaryText = "(Penalty missed)";
  }

  return (
    <TimelineItem
      mainText={player.name}
      secondaryText={secondaryText}
      iconSource={iconSource}
      hasSecondaryText={hasSecondaryText}
      isHome={isHome}
      minutes={event.minute}
      minutesExtra={event.minuteExtra}
    />
  );
}
