import {
  getDataToSignForPersonalSign,
  buildForwardTxRequest,
  getBiconomyForwarderConfig,
  getSignatureParameters,
} from "./biconomyForwarderHelpers";
import { BiconomyConfig } from "./config";
import { infuraWeb3Struct } from "../utils";
import { Config } from "../../config";

export const sendMetaTransaction = async (
  method,
  methodCode,
  userAddress,
  contractAddress,
  walletWeb3
) => {
  const functionSignature = method.encodeABI();
  const txGas = await method.estimateGas({ from: userAddress });

  const forwarder = await getBiconomyForwarderConfig();
  const forwarderContract = new infuraWeb3Struct.web3.eth.Contract(
    forwarder.abi,
    forwarder.address
  );

  //const batchId = await forwarderContract.methods.getBatch(userAddress).call();
  const batchId = 0;
  const batchNonce = await forwarderContract.methods
    .getNonce(userAddress, batchId)
    .call();
  const gasLimitNum = Number(txGas);

  const request = await buildForwardTxRequest({
    account: userAddress,
    to: contractAddress,
    gasLimitNum,
    batchId,
    batchNonce,
    data: functionSignature,
  });
  const hashToSign = getDataToSignForPersonalSign(request);
  const sig = await walletWeb3.eth.sign(hashToSign, userAddress);

  const params = [request, sig];

  return sendTransaction({
    userAddress: userAddress,
    params: params,
    signatureType: "PERSONAL_SIGN",
    methodCode: methodCode,
    contractAddress: contractAddress,
  });
};

export const sendCustomMetaTransaction = async (
  method,
  methodCode,
  userAddress,
  contract,
  contractAddress,
  walletWeb3
) => {
  const domainType = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "verifyingContract", type: "address" },
    { name: "salt", type: "bytes32" },
  ];
  const metaTransactionType = [
    { name: "nonce", type: "uint256" },
    { name: "from", type: "address" },
    { name: "functionSignature", type: "bytes" },
  ];

  let name = await contract.methods.name().call();
  let version = await contract.methods.EIP712_VERSION().call();
  let domainData = {
    name: name,
    version: version,
    verifyingContract: contractAddress,
    salt: "0x" + Config.NETWORK.chainId.toString(16).padStart(64, "0"),
  };

  let nonce = await contract.methods.nonces(userAddress).call();
  const functionSignature = method.encodeABI();

  let message = {};
  message.nonce = parseInt(nonce);
  message.from = userAddress;
  message.functionSignature = functionSignature;

  const dataToSign = JSON.stringify({
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType,
    },
    domain: domainData,
    primaryType: "MetaTransaction",
    message: message,
  });

  let promise = new Promise((resolve, reject) => {
    walletWeb3.currentProvider.send(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [userAddress, dataToSign],
      },
      function (error, response) {
        if (response && response.result) {
          resolve(response.result);
        } else {
          reject(new Error("Could not get user signature"));
        }
      }
    );
  });

  const signature = await promise;
  let { r, s, v } = getSignatureParameters(signature);
  const params = [userAddress, functionSignature, r, s, v];

  return sendTransaction({
    userAddress: userAddress,
    params: params,
    methodCode: methodCode,
    contractAddress: contractAddress,
  });
};

const sendTransaction = async ({
  userAddress,
  params,
  signatureType,
  methodCode,
  contractAddress,
}) => {
  try {
    const body = {
      to: contractAddress,
      apiId: BiconomyConfig.methodApiId[methodCode],
      params: params,
      from: userAddress,
    };
    if (signatureType) {
      body["signatureType"] = signatureType;
    }
    return fetch(`${BiconomyConfig.baseURL}/api/v2/meta-tx/native`, {
      method: "POST",
      headers: {
        "x-api-key": BiconomyConfig.apiKey,
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(async function (result) {
        if (result.txHash) {
          return getTransactionReceiptMined(result.txHash, 2000);
        } else {
          console.log(result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

const getTransactionReceiptMined = (txHash, interval) => {
  const transactionReceiptAsync = async function (resolve, reject) {
    const receipt = await infuraWeb3Struct.web3.eth.getTransactionReceipt(
      txHash
    );
    if (receipt == null) {
      setTimeout(
        () => transactionReceiptAsync(resolve, reject),
        interval ? interval : 500
      );
    } else {
      resolve(receipt);
    }
  };

  if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync);
  } else {
    throw new Error("Invalid Type: " + txHash);
  }
};
