import React, { useState } from "react";
import { View, Platform } from "react-native";
import { ActionButton, LeaguesList, TabList } from "../components";
import { checkLoginAndThen } from "../components/LoginSwitch";
import { Config } from "../config";
import { logEvent, logScreenView } from "../firebase";
import { EVENTS } from "../firebase/events";
import { CreateClashWizard } from "../overlays";
import Banner from "../components/Banner";

const ClashesScreen = ({
  balance,
  competitions,
  createClash,
  logout,
  switchLoginState,
  users,
  userStruct,
}) => {
  const [toCreateClash, setToCreateClash] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    Object.keys(userStruct).length === 0 ? 1 : 0
  );

  const toggleToCreateClash = () => {
    if (toCreateClash) {
      setToCreateClash(false);
    } else {
      checkLoginAndThen(
        () => {
          setToCreateClash(true);
          logEvent(EVENTS.CREATE_LEAGUE_START, { from: "leagues" });
        },
        switchLoginState,
        userStruct,
        logout
      );
    }
  };

  React.useEffect(() => {
    let screenName =
      `leagues_${Config.FIXTURE_SCREEN_TABS[selectedTab]}`.toLowerCase();
    logScreenView(screenName);
  }, [selectedTab]);

  const MyLeagues = () => {
    return (
      <LeaguesList
        isAccessingOwnLeagues
        username={userStruct.username}
        userStruct={userStruct}
        variant={"on_going"}
      />
    );
  };

  const OtherLeagues = () => {
    return (
      <LeaguesList
        username={userStruct.username}
        userStruct={userStruct}
        variant={"other"}
      />
    );
  };

  const History = () => {
    return (
      <LeaguesList
        isAccessingOwnLeagues
        username={userStruct.username}
        userStruct={userStruct}
        variant={"history"}
      />
    );
  };

  return (
    <View
      style={{
        alignItems: "flex-start",
        flex: 1,
      }}
    >
      <View
        style={{
          height: "17%",
          width: "100%",
        }}
      >
        <Banner />
      </View>
      <TabList
        tabs={Config.LEAGUES_SCREEN_TABS}
        selected={selectedTab}
        setSelected={setSelectedTab}
      />
      <View
        style={{
          height: "67%",
          width: "95%",
          marginLeft: "2.5%",
        }}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        {
          {
            0: <MyLeagues />,
            1: <OtherLeagues />,
            2: <History />,
          }[selectedTab]
        }
      </View>
      <View
        style={{
          alignItems: "center",
          height: "9%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {/*CREATE BUTTON ON LEAGUES PAGE*/}
        <ActionButton
          buttonStyle={{
            width: "100%",
            backgroundColor: Config.Color.DFM_GREEN,
            borderRadius: 0,
          }}
          onPress={toggleToCreateClash}
          title="CREATE LEAGUE"
          titleStyle={{ color: Config.Color.DFM_DARK_PURPLE }}
          containerStyle={{ width: "95%", borderRadius: 0 }}
        />
      </View>
      <CreateClashWizard
        balance={balance}
        competitions={competitions}
        createClash={createClash}
        toCreateClash={toCreateClash}
        toggleToCreateClash={toggleToCreateClash}
        users={users}
        userStruct={userStruct}
        sourceScreen="leagues"
      />
    </View>
  );
};

export default ClashesScreen;
