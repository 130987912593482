import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Config } from "../config";
import { hp } from "../dimensions";
import { SquadaOneText } from "./index";
import MessageOverlay from "../overlays/MessageOverlay";

const JoinButton = ({ league, hasJoined, isLoading, join }) => {
  const [isOngoingOverlaySelected, setIsOngoingOverlaySelected] =
    useState(false);
  let isLeagueOngoing = league.hasStarted;

  let upcomingMatches = league.fixtures.filter((f) =>
    Config.Status.Upcoming.some((s) => s.CODE.includes(f.status))
  );
  let hasUpcomingGames = upcomingMatches.length !== 0;
  let canJoin = !league.hasFinished && hasUpcomingGames;

  if (isLoading) {
    return <ActivityIndicator size="large" color={Config.Color.DFM_GREEN} />;
  } else if (hasJoined && join) {
    return (
      <View style={[styles.buttonContainer, styles.joinedButton]}>
        <SquadaOneText style={styles.joinedText}>JOINED</SquadaOneText>
      </View>
    );
  } else if (canJoin && !hasJoined) {
    return (
      <>
        <TouchableOpacity
          style={[styles.buttonContainer, styles.joinButton]}
          onPress={() => {
            isLeagueOngoing ? setIsOngoingOverlaySelected(true) : join();
          }}
        >
          <SquadaOneText style={styles.joinText}>JOIN</SquadaOneText>
        </TouchableOpacity>
        <MessageOverlay
          isVisible={isOngoingOverlaySelected}
          exit={() => {
            setIsOngoingOverlaySelected(false);
          }}
          action={() => {
            setIsOngoingOverlaySelected(false);
            join();
          }}
          title="LEAGUE ONGOING!"
          text={
            "You are joining an ongoing league which means you will get 0 points for all matches that have already started or ended.\n\nYou'll be able to add your predictions for any upcoming matches."
          }
          imageSource={require("../assets/trophy-light-purple.png")}
          buttonText="OK"
          trackingScreenName={"joining_ongoing_league_disclaimer"}
        />
      </>
    );
  } else return null;
};

export default JoinButton;

const styles = StyleSheet.create({
  buttonContainer: {
    width: "43%",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
  },

  joinedButton: {
    borderColor: Config.Color.DFM_DARK_PURPLE,
  },
  joinedText: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },

  joinButton: {
    backgroundColor: Config.Color.DFM_GREEN,
    borderWidth: 0,
  },

  joinText: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },
});
