import { Config } from "../../config";
import {
  adjustDates,
  formatDayTime,
  formatFixturesDayDate,
  getScore,
  parseElapsedMinutes,
} from "../../formatting";

/*
 * MATCH STATUS DETAILS
 * - Status type (UPCOMING | LIVE | FINISHED)
 * - Date
 * - Time
 * - Score
 */
export function getMatchStatus(fixture) {
  const {
    byCode,
    Finished,
    Live,
    FullPenalties,
    Penalties,
    Postponed,
    Upcoming,
    NoDate,
    HalfTime,
    FullTimeBreak,
    ExtraTimeBreak,
  } = Config.Status;
  fixture = adjustDates(fixture);
  const { date, status } = fixture;
  const { score, penaltyScore } = getScore(fixture);
  const updated_at = fixture.updated_at ?? fixture.updatedAt;
  let liveDetail = "";
  let scores = {
    home: -1,
    away: -1,
    home_penalties: -1,
    away_penalties: -1,
  };

  let time = formatDayTime(date);
  let dateOnly = formatFixturesDayDate(date);
  let statusType;
  if (Live.some((s) => s.CODE.includes(status))) {
    statusType = "LIVE";
    scores.home = score.home;
    scores.away = score.away;
    if ([FullPenalties, Penalties].some((s) => s.CODE.includes(status))) {
      scores.home_penalties = penaltyScore.home;
      scores.away_penalties = penaltyScore.away;
    }
    if (
      [HalfTime, FullTimeBreak, ExtraTimeBreak, Penalties].some((s) =>
        s.CODE.includes(status)
      )
    ) {
      liveDetail = byCode[status].SHORT;
    } else {
      const updatedAt = new Date(updated_at);
      const { minutes, extraMinutes } = parseElapsedMinutes(updatedAt, status);
      liveDetail = `${minutes}${extraMinutes ? "+" + extraMinutes : ""}'`;
    }
  } else if (Upcoming.some((s) => s.CODE.includes(status))) {
    statusType = "UPCOMING";
    if ([NoDate].some((s) => s.CODE.includes(status))) {
      statusType = "TBA";
    }
  } else if (Finished.some((s) => s.CODE.includes(status))) {
    statusType = "FINISHED";
    scores.home = score.home;
    scores.away = score.away;
    if ([FullPenalties, Penalties].some((s) => s.CODE.includes(status))) {
      scores.home_penalties = penaltyScore.home;
      scores.away_penalties = penaltyScore.away;
    }
  } else if (Postponed.CODE.includes(status)) {
    statusType = "TBD";
  }
  return {
    status: statusType,
    date: dateOnly,
    time: time,
    scores: scores,
    liveDetail: liveDetail,
  };
}
