import React from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { RobotoText, SquadaOneText } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";
import AllowanceOverlayTrigger from "./AllowanceOverlayTrigger";

const Banner = () => {
  return (
    <AllowanceOverlayTrigger
      triggerComponent={
        <View style={styles.container}>
          <ImageBackground
            source={require("../assets/banner-background.png")}
            style={styles.imageBackground}
          >
            <View style={styles.content}>
              <SquadaOneText style={styles.offerValue}>
                THE FIRST LEAGUE IS ON US!
              </SquadaOneText>
              <View style={styles.taglineContainer}>
                <RobotoText style={styles.tagline} variant={"bold"}>
                  SIGN UP AND GET YOUR WELCOME BONUS
                </RobotoText>
              </View>
            </View>
          </ImageBackground>
        </View>
      }
    />
  );
};

export default Banner;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageBackground: {
    width: "100%",
    height: "100%",
  },
  content: {
    flexDirection: "column",
    marginLeft: "5%",
    marginTop: "7.5%",
  },
  offerValue: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(3.5),
    marginLeft: "5%",
  },
  taglineContainer: {
    marginLeft: "7.5%",
    marginTop: "2%",
  },
  tagline: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
});
