import React from "react";
import { View, StyleSheet, ScrollView, Text, Image } from "react-native";
import { ActionButton, RobotoText, SquadaOneText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { Frame } from "./shared";

const introSteps = [
  {
    icon: require("../assets/game-pink.png"),
    highlight: "DEFYME",
    content:
      "is a fun and engaging game developed on blockchain technology where you can participate in leagues based on football matches and DEFY your friends by beating their scores forecast.",
  },
  {
    icon: require("../assets/wallet-pink.png"),
    highlight: "FUND YOUR WALLET!",
    content:
      "A digital wallet was created on your behalf when you signed in. Fund it with USDC tokens on Polygon using our in-app top up mechanism or any other existing exchange.",
  },
  {
    icon: require("../assets/trophy-pink.png"),
    highlight: "CREATE A LEAGUE",
    content:
      "with one or a set of upcoming matches or simply join an existing one.",
  },
  {
    icon: require("../assets/coins-pink.png"),
    highlight: "OWN YOUR SCORES",
    content:
      "and scoop up your friends tokens. The player/s with the most points in a given league, take/s all the league accumulated tokens.\n\nNo winners? No Problem! All tokens are reverted back to your wallet.",
  },
];

const IntroOverlay = ({ isVisible, endStep, buttonText }) => {
  return (
    <Frame
      isVisible={isVisible}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={"intro_overlay"}
    >
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.bodyView}>
          <View style={styles.welcomeContainer}>
            <SquadaOneText style={styles.welcomeText}>WELCOME TO</SquadaOneText>
            <Image
              source={require("../assets/defyme-name-without-io.png")}
              style={styles.logo}
            />
          </View>
          {introSteps.map((item, index) => (
            <View key={index} style={styles.ruleContainer}>
              <Image source={item.icon} style={styles.icon} />
              <Text style={styles.rulesText}>
                <RobotoText
                  variant={"bold"}
                  style={styles.rulesTextHighlighted}
                >
                  {item.highlight}
                </RobotoText>{" "}
                {item.content}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.footerView}>
          <ActionButton onPress={endStep} title={buttonText} />
        </View>
      </ScrollView>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    flex: 1,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: {
    flex: 1,
  },
  footerView: {
    height: hp(15),
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: hp(5),
  },
  welcomeText: {
    color: Config.Color.DFM_YELLOW,
    fontSize: hp(4),
  },
  logo: {
    height: hp(10),
    width: hp(30),
    resizeMode: "contain",
  },
  icon: {
    height: hp(3),
    width: hp(3),
    resizeMode: "contain",
    marginRight: 15,
  },
  ruleContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "2%",
    marginBottom: "1.5%",
  },
  rulesTextHighlighted: {
    fontSize: hp(2),
    color: Config.Color.DFM_YELLOW,
  },
  rulesText: {
    width: "85%",
    fontSize: hp(2),
    color: Config.Color.WHITE,
    fontFamily: "Roboto-Regular",
    textAlign: "justify",
  },
  ruleNumber: {
    fontSize: hp(3),
    color: Config.Color.DFM_PINK,
    marginRight: 15,
  },
});

export default IntroOverlay;
