import React, { useState } from "react";
import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { ActionButton, Dropdown, Fixture, Loading } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { Frame, Label } from "./shared";
import { sortFixtures } from "../sorting";

const MAX_HEIGHT = 0.85 * Dimensions.MAX_HEIGHT;
const MAX_WIDTH = 0.9 * Dimensions.MAX_WIDTH;
const FIXTURE_HEIGHT = 0.07 * MAX_HEIGHT;
const FIXTURE_WIDTH = 0.85 * MAX_WIDTH;

const SelectFixturesOverlay = ({
  competitions,
  exit,
  fixedFixture,
  initialSelectedFixtures,
  isVisible,
  prev,
  next,
  trackingScreenName,
}) => {
  const [selectedCompetition, setCompetition] = useState(
    fixedFixture
      ? competitions.reduce(
          (acc, c, index) =>
            c.id === fixedFixture.competition.id ? index : acc,
          0
        )
      : 0
  );
  const [fixtures, setFixtures] = useState(initialSelectedFixtures ?? []);

  const handleSelectFixture = (fixture) => {
    if (!fixedFixture || fixedFixture.id !== fixture.id) {
      if (fixtures.some((f) => f.id === fixture.id)) {
        setFixtures([...fixtures.filter((f) => fixture.id !== f.id)]);
      } else {
        fixtures.push(fixture);
        setFixtures([...fixtures]);
      }
    }
  };

  if (!competitions) return <Loading />;
  return (
    <Frame
      isVisible={isVisible}
      headerText="SELECT MATCHES"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      trackingScreenName={trackingScreenName}
    >
      <View
        style={styles.bodyView}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        <View style={styles.dropdownView}>
          <Label style={styles.labelText}>Select a competition</Label>
          <Dropdown
            items={competitions}
            selectedItem={selectedCompetition}
            selectItem={setCompetition}
            style={styles.dropdown}
            textStyle={styles.dropdownText}
          />
        </View>
        <View style={styles.matchesCopyView}>
          <Text style={styles.labelText} adjustsFontSizeToFit>
            Select matches (
            <Text style={styles.numberSelected}>{fixtures.length}</Text>{" "}
            selected)
          </Text>
        </View>
        <FlatList
          data={competitions[selectedCompetition].fixtures}
          extraData={fixtures}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.fixtureTouchable}
              onPress={() => handleSelectFixture(item)}
            >
              <Icon
                name={
                  fixtures.some((f) => f.id === item.id) ? "circle" : "circle-o"
                }
                type="font-awesome"
                size={20}
                color={
                  fixtures.some((f) => f.id === item.id)
                    ? Config.Color.DFM_DARK_PURPLE
                    : Config.Color.DFM_DARK_PURPLE
                }
                containerStyle={styles.fixtureIconContainer}
              />
              <Fixture
                fixture={item}
                height={FIXTURE_HEIGHT}
                width={FIXTURE_WIDTH}
                paddingHorizontal={10}
                pressDisabled
                withoutCompetition
              />
            </TouchableOpacity>
          )}
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
        />
      </View>
      <View style={styles.footerView}>
        <ActionButton
          onPress={() => prev(fixtures)}
          title="PREVIOUS"
          variant="secondary"
        />
        <ActionButton
          onPress={() => next(fixtures.sort(sortFixtures))}
          disabled={fixtures.length < 1}
          title="NEXT"
        />
      </View>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: { width: "95%", alignItems: undefined },
  bodyView: { justifyContent: "space-between", flex: 1 },
  matchesCopyView: { marginBottom: hp(1.5) },
  matchesCopyText: {
    color: Config.Color.WHITE,
    fontSize: hp(1.5),
  },
  dropdownView: {
    // alignItems: "center",
    // justifyContent: "center",
  },
  dropdown: {
    height: hp(6),
    width: "100%",
    backgroundColor: Config.Color.WHITE,
    justifyContent: "center",
    paddingHorizontal: "5%",
    marginBottom: hp(2),
    fontSize: hp(1.5),
    fontFamily: "Roboto-Regular",
  },
  dropdownText: {
    fontSize: hp(2),
    color: Config.Color.CY_DARK_PURPLE,
    fontFamily: "Roboto-Regular",
  },
  fixtureTouchable: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Config.Color.WHITE,
    paddingHorizontal: "5%",
    paddingVertical: "1%",
    marginBottom: hp(2),
  },
  fixtureIconContainer: {
    marginRight: "2%",
    marginLeft: "2%",
  },
  footerView: {
    width: "100%",
    height: hp(15),
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  numberSelected: {
    color: Config.Color.DFM_LIGHT_PURPLE,
    fontFamily: "Roboto-Bold",
  },
  labelText: {
    color: Config.Color.WHITE,
    fontSize: hp(1.5),
    fontFamily: "Roboto-Light",
  },
});

export default SelectFixturesOverlay;
