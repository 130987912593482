import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { RobotoText } from "./index";

const IconText = ({ iconSource, text }) => {
  return (
    <View style={styles.container}>
      <Image source={iconSource} style={styles.icon} />
      <RobotoText style={styles.text} adjustsFontSizeToFit numberOfLines={1}>
        {text}
      </RobotoText>
    </View>
  );
};

export default IconText;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginVertical: 2,
  },
  icon: {
    height: 0.14 * Dimensions.CARD_HEIGHT,
    width: 0.14 * Dimensions.CARD_HEIGHT,
    resizeMode: "contain",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 2,
  },

  text: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: 0.14 * Dimensions.CARD_HEIGHT,
  },
});
