import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  ActionButton,
  EmptyListMessage,
  FixtureCard,
  RobotoText,
  SquadaOneText,
} from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { Frame, Label } from "./shared";
import Badge from "../components/Badge";
import PlayerScore from "../components/fixture-components/PlayerScore";
import { parseToken } from "../web3/utils";

const ConfirmTransactionOverlay = ({
  confirmTransaction,
  estimatedGas,
  exit,
  isVisible,
  prev,
  ticketPrice,
  token,
  title,
  trackingScreenName,
  isListed,
  isAllowance,
  predictions,
  fixtures,
  isJoiningOrCreating,
}) => {
  let listedText = isListed ? "Public" : "Private";
  let sourceOfBalance = isAllowance ? "Welcome Bonus" : "Wallet";
  const Tokens = Config.Referee.Tokens;

  const transactionTitle = {
    join_league_confirmation: "JOIN LEAGUE",
    new_league_confirmation: "CREATE NEW LEAGUE",
    add_scores_confirmation: "ADD SCORE PREDICTIONS",
  };

  let estimatedGasText = parseToken(String(estimatedGas), Tokens.NATIVE);
  if (estimatedGasText === 0) estimatedGasText = "< 0.001";

  let price = isJoiningOrCreating ? Number(ticketPrice) : 0;
  let isNativeTransaction = Tokens.NATIVE.caption === token.caption;
  let isGasSponsored =
    (isJoiningOrCreating && !isNativeTransaction) || !isJoiningOrCreating;

  let totalText;
  if (isNativeTransaction && isJoiningOrCreating) {
    let total = price + estimatedGas;
    totalText =
      parseToken(String(total), Tokens.NATIVE) + " " + Tokens.NATIVE.caption;
  } else {
    totalText = isJoiningOrCreating ? parseToken(String(price), token) : "0";
    totalText += " " + token.caption;
  }

  let isAddingPredictions =
    predictions.filter((p) => p !== undefined).length !== 0;

  return (
    <Frame
      isVisible={isVisible}
      headerText="CONFIRM TRANSACTION"
      onBackdropPress={exit}
      onPressCloseButton={exit}
      overlayStyle={styles.overlay}
      containerStyle={styles.frameView}
      closeButtonTintColor={Config.Color.WHITE}
      trackingScreenName={trackingScreenName}
    >
      <>
        <View style={{ flex: 1, paddingVertical: "1.5%" }}>
          <View style={styles.transactionTitle}>
            <SquadaOneText style={styles.transactionTypeTitle}>
              {transactionTitle[trackingScreenName]}
            </SquadaOneText>
          </View>
          <View>
            <View style={styles.verticalPadded}>
              <Label style={styles.labelText}>LEAGUE TITLE:</Label>
              <RobotoText style={styles.text}>
                {title} ({listedText})
              </RobotoText>
            </View>
            <View style={styles.verticalPadded}>
              <Label style={styles.labelText}>TRANSACTION DETAILS:</Label>
              {isJoiningOrCreating && (
                <View style={styles.transactionRow}>
                  <RobotoText style={styles.transactionDescription}>
                    Ticket Price
                  </RobotoText>
                  <RobotoText style={styles.transactionCost}>
                    {parseToken(String(ticketPrice), token)} {token.caption}
                  </RobotoText>
                </View>
              )}
              <View style={styles.transactionRow}>
                <View style={styles.rowWithBadge}>
                  <RobotoText style={styles.transactionDescription}>
                    Gas Fee
                  </RobotoText>
                  {Boolean(isGasSponsored) && (
                    <Badge
                      icon={require("../assets/gasless-dark-purple.png")}
                      text={"SPONSORED BY DEFYME"}
                      containerStyle={styles.sponsoredByBadgeContainer}
                    />
                  )}
                </View>
                <RobotoText style={styles.transactionCost}>
                  {Boolean(isGasSponsored) && (
                    <Text style={styles.costZero}>0 </Text>
                  )}
                  <Text style={isGasSponsored ? styles.strikethrough : ""}>
                    ≈ {estimatedGasText} {Tokens.NATIVE.caption}
                  </Text>
                </RobotoText>
              </View>
              <View style={styles.transactionTotalRow}>
                <RobotoText
                  variant={"bold"}
                  style={styles.transactionTotalDescription}
                >
                  Total
                </RobotoText>
                <RobotoText
                  variant={"bold"}
                  style={styles.transactionTotalCost}
                >
                  {totalText}
                </RobotoText>
              </View>
              <View
                style={[styles.transactionRow, styles.sourceOfBalanceContainer]}
              >
                <RobotoText
                  variant={"italic"}
                  style={[styles.transactionCost, styles.sourceOfBalance]}
                >
                  Source of Balance: {sourceOfBalance}
                </RobotoText>
              </View>
            </View>
          </View>
          <View style={styles.verticalPadded}>
            <Label style={styles.labelText}>MATCHES & SCORES:</Label>
            {!isAddingPredictions && (
              <EmptyListMessage
                code={"NOT_ADDING_PREDICTIONS"}
                withContainer={true}
              />
            )}
            {isAddingPredictions &&
              fixtures.map((f, index) => {
                let predictionAdded = predictions[index];
                if (predictionAdded !== undefined) {
                  let scoreContainer = (
                    <PlayerScore
                      prediction={predictions[index]}
                      showTitle={true}
                    />
                  );
                  return (
                    <FixtureCard
                      key={index}
                      variant={"confirmation_transaction_overlay"}
                      fixture={f}
                      pressDisabled={true}
                      dynamicContainer={scoreContainer}
                      statusContainerBackground={{
                        backgroundColor: "rgba(255,255,255,0.4)",
                      }}
                    />
                  );
                }
                return null;
              })}
          </View>
        </View>
        <View style={styles.footerView}>
          <ActionButton onPress={prev} title="PREVIOUS" variant="secondary" />
          <ActionButton onPress={confirmTransaction} title="CONFIRM" />
        </View>
      </>
    </Frame>
  );
};
export default ConfirmTransactionOverlay;

const styles = StyleSheet.create({
  overlay: { padding: 0 },
  frameView: {
    width: "95%",
    alignItems: undefined,
  },
  dataText: { fontSize: hp(2) },
  strikethrough: {
    textDecorationLine: "line-through",
    textDecorationColor: Config.Color.DFM_YELLOW,
  },
  verticalPadded: {
    paddingBottom: "3%",
  },
  footerView: {
    height: hp(15),
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  labelText: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Black",
  },
  text: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
    fontFamily: "Roboto-Regular",
    marginLeft: "2.5%",
  },
  transactionTitle: {
    flexDirection: "column",
    paddingBottom: "2%",
  },
  transactionTypeTitle: {
    color: Config.Color.DFM_GREEN,
    fontSize: hp(3),
  },
  leagueTitle: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  transactionRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "2%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  transactionTotalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderTopColor: Config.Color.WHITE,
    borderTopWidth: 2,
    paddingVertical: "2%",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  transactionTotalDescription: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  transactionTotalCost: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  transactionDescription: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  transactionCost: {
    color: Config.Color.WHITE,
    fontSize: hp(2),
  },
  sourceOfBalanceContainer: {
    justifyContent: "flex-end",
  },
  sourceOfBalance: {
    fontSize: hp(1.5),
  },
  rowWithBadge: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  costZero: {
    color: Config.Color.DFM_YELLOW,
  },
  badgeContainer: {
    backgroundColor: Config.Color.WHITE,
    marginLeft: 5,
  },

  sponsoredByBadgeContainer: {
    marginLeft: 5,
  },
});
