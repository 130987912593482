import React, { useEffect, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { gql, useQuery } from "@apollo/client";
import { EmptyListMessage, LeagueCard, Loading } from "../components";
import { sortClashes } from "../sorting";
import {
  fetchUserPredictions,
  infuraWeb3Struct,
  parseClash,
  predictionToClash,
} from "../web3/utils";

const GET_USER = gql`
  query GetProfileUser($username: [String]) {
    users(username: $username) {
      id
      wallets {
        publicAddress
        provider
      }
    }
  }
`;

const LeaguesList = ({
  isAccessingOwnLeagues,
  username = "",
  userStruct,
  variant,
}) => {
  let [leagues, setLeagues] = useState(null);
  const { data, loading } = useQuery(GET_USER, {
    variables: { username: username },
  });
  const user = data?.users[0];
  useEffect(() => {
    const fetchClashes = async () => {
      if (variant === "other") {
        let clashes = await infuraWeb3Struct.refereeInst.methods
          .getUnfinishedClashes()
          .call()
          .then((clashes) =>
            Promise.all(clashes.map(async (clash) => await parseClash(clash)))
          );
        if (user?.wallets.length > 0) {
          clashes = clashes.filter((c) =>
            user.wallets.every((w) => !c.predictions?.get(w.publicAddress))
          );
        }
        return clashes;
      } else {
        if (user?.wallets.length > 0) {
          return await fetchUserPredictions(
            user.wallets.map((w) => w.publicAddress),
            Boolean(isAccessingOwnLeagues)
          ).then((predictions) =>
            predictions
              .filter((p) => (variant === "history") === p.clash.hasFinished)
              .map(predictionToClash)
          );
        } else return [];
      }
    };
    if (!userStruct?.initialLoading && !loading) {
      fetchClashes().then((clashes) => setLeagues(clashes.sort(sortClashes)));
    }
  }, [isAccessingOwnLeagues, loading, user, userStruct, variant]);

  if (!leagues) return <Loading />;

  if (leagues.length <= 0) {
    let code;
    switch (variant) {
      case "on_going":
        code = "NO_LEAGUES_ONGOING";
        break;
      case "history":
        code = "NO_LEAGUES_HISTORY";
        break;
      case "other":
        code = "NO_OTHER_LEAGUES";
        break;
      default:
        break;
    }
    return <EmptyListMessage code={code} />;
  }

  return (
    <FlatList
      data={leagues}
      renderItem={({ item }) => (
        <View style={styles.leagueContainer}>
          <LeagueCard
            userStruct={userStruct}
            account={userStruct.account}
            clash={item}
            prediction={item.pendingPrediction}
            showTitle
            showPrize
          />
        </View>
      )}
      keyExtractor={(item, index) => index.toString()}
      showsVerticalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  leagueContainer: {
    marginTop: "2%",
  },
});

export default LeaguesList;
