import React, { useState } from "react";
import { StyleSheet, Image, Text, View, Linking, Platform } from "react-native";
import { Input } from "react-native-elements";
import { ActionButton, OswaldText, RobotoText } from "../components";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { Frame } from "./shared";
import { preActivateConnector } from "../web3";
import {
  MAGIC_LINK,
  MAGIC_OAUTH_GOOGLE,
  MAGIC_OAUTH_APPLE,
} from "../web3/connectors/types";

const LegalInfo = () => {
  const navigateToUrl = React.useCallback((url) => {
    Linking.canOpenURL(url).then(() => {
      if (Platform.OS === "web") {
        window.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    });
  }, []);

  return (
    <Text style={styles.textCopy}>
      By Signing In, you’re allowing{" "}
      <Text
        style={styles.textHyperLinkYellow}
        onPress={() => navigateToUrl("https://magic.link/")}
      >
        Magic
      </Text>{" "}
      to generate an electronic wallet assigned to your email and acknowledging
      our{" "}
      <Text
        style={styles.textHyperLinkPink}
        onPress={() => navigateToUrl("https://app.defyme.io/terms")}
      >
        Terms & Conditions
      </Text>
      {" and "}
      <Text
        style={styles.textHyperLinkPink}
        onPress={() => navigateToUrl("https://app.defyme.io/privacy")}
      >
        Privacy Policy
      </Text>
    </Text>
  );
};

const LoginOptionsSelector = ({
  login,
  setToLoginWithMagic,
  toggleLoading,
}) => (
  <>
    <ActionButton
      buttonStyle={styles.iconButton}
      containerStyle={styles.iconButtonContainer}
      titleStyle={styles.iconButtonText}
      icon={
        <Image
          source={require("../assets/google-green.png")}
          style={styles.buttonIcon}
        />
      }
      onPress={() => {
        toggleLoading();
        if (Platform.OS === "web") {
          //Google OAuth in Web requires two-step flow for login, therefore this pre-activation as 1st step
          preActivateConnector(MAGIC_OAUTH_GOOGLE, "google");
        } else {
          login(MAGIC_OAUTH_GOOGLE, { provider: "google" });
        }
      }}
      title={"Continue with Google"}
    />
    {Boolean(["web", "ios"].includes(Platform.OS)) && (
      <ActionButton
        buttonStyle={styles.iconButton}
        containerStyle={styles.iconButtonContainer}
        titleStyle={styles.iconButtonText}
        icon={
          <Image
            source={require("../assets/apple-green.png")}
            style={styles.buttonIcon}
          />
        }
        onPress={() => {
          toggleLoading();
          if (Platform.OS === "web") {
            //Apple OAuth in Web requires two-step flow for login, therefore this pre-activation as 1st step
            preActivateConnector(MAGIC_OAUTH_APPLE, "apple");
          } else {
            login(MAGIC_OAUTH_APPLE, { provider: "apple" });
          }
        }}
        title={"Continue with Apple"}
      />
    )}
    <ActionButton
      buttonStyle={styles.iconButton}
      containerStyle={styles.iconButtonContainer}
      titleStyle={styles.iconButtonText}
      icon={
        <Image
          source={require("../assets/email-green.png")}
          style={styles.buttonIcon}
        />
      }
      onPress={() => setToLoginWithMagic(true)}
      title={"Continue with Email"}
    />
    <LegalInfo />
  </>
);

const SignInWithMagic = ({ error, login }) => {
  const [email, setEmail] = useState(null);
  return (
    <>
      <Input
        onChangeText={setEmail}
        label={"Email"}
        autoCompleteType="email"
        keyboardType="email-address"
        labelStyle={styles.labelStyle}
        containerStyle={styles.inputContainerView}
        inputContainerStyle={styles.inputContainer}
        style={[
          styles.input,
          {
            width: "100%",
            outline: "none", // doesnt work in a RN StyleSheet
          },
        ]}
      />
      {error && <OswaldText style={styles.errorMessage}>{error}</OswaldText>}
      <ActionButton
        title="SIGN IN"
        containerStyle={{ width: "90%", borderRadius: 0 }}
        titleStyle={styles.signInText}
        onPress={() => login(MAGIC_LINK, { email: email })}
        disabled={!email}
        disabledStyle={styles.buttonDisabled}
        disabledTitleStyle={{ color: Config.Color.DFM_DARK_PURPLE }}
      />
    </>
  );
};

const LoginOverlay = ({ login, toggleLoading, toggleLoginScreen }) => {
  const [error] = useState(null);
  const [toLoginWithMagic, setToLoginWithMagic] = useState(false);

  return (
    <Frame
      isVisible
      onPressCloseButton={toggleLoginScreen}
      overlayStyle={styles.overlay}
      trackingScreenName="login"
    >
      <Image
        source={Config.Assets.DEFYME_LOGO_TAGLINE}
        style={styles.defymeLogo}
      />
      {!toLoginWithMagic && (
        <LoginOptionsSelector
          login={login}
          setToLoginWithMagic={setToLoginWithMagic}
          toggleLoading={toggleLoading}
          toggleLoginScreen={toggleLoginScreen}
        />
      )}
      {toLoginWithMagic && <SignInWithMagic error={error} login={login} />}
      <View style={styles.magicContainer}>
        <RobotoText variant="powered">{`Secured by`}</RobotoText>
        <Image
          source={require("../assets/magic-logo-white.png")}
          style={styles.magicImage}
        />
      </View>
    </Frame>
  );
};

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  labelStyle: {
    color: Config.Color.WHITE,
    fontSize: hp(1.5),
    fontFamily: "Roboto-Light",
  },
  textTitleContainer: {
    marginBottom: "20%",
    textAlign: "center",
  },
  textCopy: {
    width: "90%",
    fontSize: hp(1.4),
    textAlign: "justify",
    color: Config.Color.WHITE,
  },
  input: {
    color: Config.Color.WHITE,
    fontFamily: "Roboto-Regular",
    fontSize: hp(2),
  },
  inputContainerView: {
    paddingHorizontal: 0,
    width: "90%",
  },
  inputContainer: {
    backgroundColor: "rgba(255,255,255,0.5)",
    height: hp(6),
    paddingHorizontal: "5%",
  },
  defymeLogo: {
    height: "35%",
    width: "90%",
    resizeMode: "contain",
    marginTop: "15%",
  },
  textHyperLinkPink: {
    textDecorationLine: "underline",
    color: Config.Color.DFM_PINK,
  },
  textHyperLinkYellow: {
    textDecorationLine: "underline",
    color: Config.Color.DFM_YELLOW,
  },
  button: {
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    borderColor: Config.Color.DFM_GREEN,
  },
  iconButton: {
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    borderColor: Config.Color.DFM_GREEN,
    width: "100%",
  },
  iconButtonContainer: {
    width: "90%",
    marginBottom: hp(2),
  },
  buttonContainer: {
    width: "100%",
    marginBottom: hp(2),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "red",
    borderRadius: 0,
  },
  buttonIcon: {
    height: hp(3),
    width: hp(3),
    resizeMode: "contain",
    marginRight: hp(2),
  },
  buttonText: {
    color: Config.Color.DFM_GREEN,
    fontFamily: "Roboto-Medium",
  },
  signInText: {
    fontSize: hp(2),
  },
  iconButtonText: {
    color: Config.Color.DFM_GREEN,
    fontFamily: "Roboto-Medium",
    fontSize: hp(2),
  },
  buttonDisabled: {
    backgroundColor: Config.Color.DFM_GREEN,
    opacity: 5,
  },
  magicImage: {
    marginLeft: 5,
    height: hp(4),
    width: hp(12),
    resizeMode: "contain",
  },
  magicContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    marginTop: hp(2),
  },
  errorMessage: {
    color: Config.Color.RED,
    alignSelf: "flex-start",
    marginLeft: 0.05 * Dimensions.MAX_WIDTH,
  },
});

export default LoginOverlay;
