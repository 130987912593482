import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Config } from "../config";
import Dimensions from "../dimensions";
import { DateTime, Score, ResultIcon, Team } from "./fixture-components";
import OswaldText from "./OswaldText";
import SquadaOneText from "./SquadaOneText";

const Fixture = ({
  clashesNumber,
  fixture,
  height,
  history,
  onlyHours,
  paddingHorizontal,
  prediction,
  pressDisabled,
  scoreRightAligned,
  teamId,
  withoutCompetition,
  width,
}) => {
  const { status } = fixture;
  const { Finished, Started } = Config.Status;
  const fixtureHeight = height ?? Dimensions.CARD_HEIGHT;
  const fixtureWidth = width ?? Dimensions.MAX_WIDTH;
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: fixtureHeight,
        width: fixtureWidth,
        marginVertical: 1,
        paddingHorizontal: paddingHorizontal ?? "5%",
        backgroundColor: Config.Color.WHITE,
      }}
      onPress={() => history.push("/match/" + fixture.id)}
      disabled={pressDisabled}
    >
      <View
        style={{
          width: "75%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {!withoutCompetition && (
          <View
            style={{
              height: "38%",
              justifyContent: "center",
            }}
          >
            <SquadaOneText
              adjustsFontSizeToFit
              numberOfLines={1}
              variant="match_competition_name"
              variableHeight={fixtureHeight}
            >
              {fixture.competition.caption}
            </SquadaOneText>
          </View>
        )}
        <View
          style={{
            height: !withoutCompetition ? "62%" : "100%",
            minWidth: "60%",
            maxWidth: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: "100%",
              justifyContent: "space-evenly",
              paddingRight: 20,
            }}
          >
            <Team
              team={fixture.home_team}
              fixtureHeight={
                withoutCompetition ? fixtureHeight : 0.62 * fixtureHeight
              }
            />
            <Team
              team={fixture.away_team}
              fixtureHeight={
                withoutCompetition ? fixtureHeight : 0.62 * fixtureHeight
              }
            />
          </View>
          {!scoreRightAligned &&
            Started.some((s) => s.CODE.includes(status)) && (
              <Score fixture={fixture} textStyle={styles.pinkText} />
            )}
        </View>
      </View>
      <View
        style={{
          maxWidth: "25%",
          minWidth: "20%",
          height: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            height: "38%",
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <DateTime
            fixture={fixture}
            onlyHours={onlyHours}
            textStyle={styles.greyText}
          />
        </View>
        {(Finished.some((s) => s.CODE.includes(status)) ||
          clashesNumber !== undefined ||
          prediction !== undefined) && (
          <View
            style={{
              height: "62%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {clashesNumber !== undefined && (
              <View
                style={{
                  height: "65%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <SquadaOneText
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  variant="match_leagues_counter"
                  variableHeight={fixtureHeight}
                >
                  {clashesNumber + " LEAGUE"}
                  {clashesNumber !== 1 && "S"}
                </SquadaOneText>
              </View>
            )}
            {prediction !== undefined && (
              <View
                style={{
                  height: 1.2 * 0.4 * fixtureHeight,
                  width: 2.2 * 0.4 * fixtureHeight,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingVertical: 0.1 * 0.4 * fixtureHeight,
                  paddingHorizontal: 0.1 * 0.4 * fixtureHeight,
                  borderWidth: 2,
                  // borderRadius: 10,
                  borderColor: Config.Color.DFM_DARK_PURPLE,
                }}
              >
                <OswaldText
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  style={{
                    fontSize: 0.3 * 0.4 * fixtureHeight,
                    color: Config.Color.GREY,
                    fontFamily: "Oswald-ExtraLight",
                  }}
                >
                  Your Score
                </OswaldText>
                <OswaldText
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  style={{
                    fontSize: 0.35 * 0.4 * fixtureHeight,
                    color: Config.Color.DFM_DARK_PURPLE,
                    fontFamily: "Oswald-SemiBold",
                  }}
                >
                  {prediction.homeScore + " - " + prediction.awayScore}
                </OswaldText>
              </View>
            )}
            {teamId && <ResultIcon fixture={fixture} teamId={teamId} />}
            {scoreRightAligned && <Score fixture={fixture} />}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};
export default Fixture;

const styles = StyleSheet.create({
  greyText: {
    color: Config.Color.GREY,
  },
  pinkText: {
    color: Config.Color.DFM_PINK,
  },
});
