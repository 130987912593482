import React, { useRef, useState } from "react";
import {
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Toast from "react-native-toast-notifications";
import { RobotoText } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";
import IntroOverlay from "../overlays/IntroOverlay";
import { RulesOverlay } from "../overlays";

const Settings = ({
  logout,
  username,
  isTopUpSelected,
  setIsTopUpSelected,
}) => {
  const [isIntroSelected, setIsIntroSelected] = useState(false);
  const [isRulesSelected, setIsRulesSelected] = useState(false);

  const navigateToUrl = React.useCallback((url) => {
    Linking.canOpenURL(url).then(() => {
      if (Platform.OS === "web") {
        window.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    });
  }, []);

  const toastRef = useRef();

  const properties = [
    {
      primary: "HOW DEFYME WORKS",
      onPress: () => setIsIntroSelected(!isIntroSelected),
    },
    {
      primary: "GAME RULES",
      onPress: () => setIsRulesSelected(!isRulesSelected),
    },
    {
      primary: "HOW TO TOP UP",
      onPress: () => setIsTopUpSelected(!isTopUpSelected),
    },
    {
      primary: "BE PART OF THE CONVERSATION",
      secondary: "Join us on the DEFYME Private chat on Whatsapp",
      onPress: () =>
        navigateToUrl("https://chat.whatsapp.com/IliA2vWNUeB2VdSJIIxFNo"),
      textStyle: styles.propertiesLink,
    },
    {
      primary: "TERMS AND CONDITIONS",
      onPress: () => navigateToUrl("https://defyme.io/terms"),
      textStyle: styles.propertiesLink,
    },
    {
      primary: "PRIVACY POLICY",
      onPress: () => navigateToUrl("https://defyme.io/privacy"),
      textStyle: styles.propertiesLink,
    },
    {
      primary: "CONTACT US",
      onPress: () => navigateToUrl("https://defyme.io/support"),
      textStyle: styles.propertiesLink,
    },
    {
      primary: "LOGOUT",
      secondary: "User logged in: " + username,
      onPress: logout,
    },
    {
      secondary: "Delete account",
      onPress: () => navigateToUrl("https://defyme.io/account/deletion"),
      textStyle: styles.propertiesLink,
    },
    { primary: "Version", secondary: "Beta" },
  ];

  const SettingsProperties = () => (
    <>
      {properties.map(
        ({ primary, secondary, copiable, onPress, textStyle }, index) => (
          <TouchableOpacity
            key={index}
            style={styles.propertiesView}
            onPress={onPress}
            disabled={!onPress}
          >
            {primary && (
              <RobotoText
                style={[
                  styles.propertiesPrimaryText,
                  secondary ? {} : textStyle,
                ]}
                adjustsFontSizeToFit
                numberOfLines={1}
                variant="medium"
              >
                {primary}
              </RobotoText>
            )}
            {secondary && (
              <View style={[styles.propertiesSecondaryView, textStyle]}>
                <RobotoText
                  style={styles.propertiesSecondaryText}
                  adjustsFontSizeToFit
                  numberOfLines={1}
                  selectable={copiable}
                  variant="regular"
                >
                  {secondary}
                </RobotoText>
              </View>
            )}
          </TouchableOpacity>
        )
      )}
    </>
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <SettingsProperties />
      <IntroOverlay
        isVisible={isIntroSelected}
        endStep={() => setIsIntroSelected(false)}
        buttonText={"CLOSE"}
      />
      <RulesOverlay
        isVisible={isRulesSelected}
        exit={() => setIsRulesSelected(false)}
      />
      <Toast ref={toastRef} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  propertiesView: {
    minHeight: hp(5.5),
    marginTop: "2%",
    justifyContent: "center",
    paddingHorizontal: "2%",
  },
  propertiesPrimaryText: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(2),
  },
  propertiesSecondaryView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  propertiesSecondaryText: {
    color: Config.Color.DFM_DARK_PURPLE,
    fontSize: hp(1.6),
  },
  propertiesLink: {
    textDecorationLine: "underline",
  },
});

export default Settings;
