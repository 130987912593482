import React from "react";
import { Config } from "../config";
import { RobotoText } from "./index";
import { Image, StyleSheet, View } from "react-native";
import { hp } from "../dimensions";
import ScoreDisplay from "./fixture-components/ScoreDisplay";

const OtherPlayerScoreItem = ({
  fixtureId,
  playerPrediction,
  player,
  loggedInUsername,
  hasJoined,
  scoreNowComponent,
}) => {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.usernameContainer}>
        <Image style={styles.userIcon} source={require("../assets/user.png")} />
        <RobotoText
          variant={"medium"}
          style={styles.username}
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {player}
        </RobotoText>
      </View>
      <View style={styles.scoreContainer}>
        <ScoreDisplay
          fixtureId={fixtureId}
          prediction={playerPrediction}
          isAuthUser={loggedInUsername === player}
          hasJoined={hasJoined}
          scoreNowComponent={scoreNowComponent}
        />
      </View>
    </View>
  );
};

export default OtherPlayerScoreItem;

const styles = StyleSheet.create({
  mainContainer: {
    height: hp(6),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
    backgroundColor: Config.Color.WHITE,
  },

  usernameContainer: {
    width: "70%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },

  userIcon: {
    height: hp(3.5),
    width: hp(3.5),
    marginHorizontal: 10,
    resizeMode: "contain",
  },

  username: {
    fontSize: hp(2.2),
    color: Config.Color.DFM_DARK_PURPLE,
    paddingLeft: 5,
  },

  scoreContainer: {
    backgroundColor: Config.Color.WHITE,
    width: "30%",
    height: "100%",
  },
});
