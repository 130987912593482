import React, { useContext, useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { Loading } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { getTokenInst, infuraWeb3Struct, parseToken } from "../web3/utils";
import { TransactionContext } from "../contexts/TransactionContext";

const { web3, refereeAllowanceVaultInst } = infuraWeb3Struct;

const BalanceDisplay = ({ walletAddress }) => {
  const { transactionConcluded } = useContext(TransactionContext);
  const [allowance, setAllowance] = useState(null);
  const [balance, setBalance] = useState(null);
  const Tokens = Config.Referee.Tokens;
  const allowanceToken = Tokens.BY_CAPTION[Config.ALLOWANCE_TOKEN];

  useEffect(() => {
    Config.Referee.Tokens.NON_NATIVE.filter(
      (t) => t.caption === Config.ALLOWANCE_TOKEN
    ).map((t) =>
      getTokenInst(web3, t)
        .methods.balanceOf(walletAddress)
        .call()
        .then((res) => setBalance(res))
    );

    if (allowanceToken !== undefined) {
      refereeAllowanceVaultInst.methods
        .allowanceOf(allowanceToken.address, walletAddress)
        .call()
        .then((allowance) => setAllowance(allowance));
    }
  }, [allowanceToken, transactionConcluded, walletAddress]);

  if (balance === null || allowance === null)
    return <Loading height={15} width={15} />;

  let total = Number(allowance) + Number(balance);
  let balanceToDisplay = total === 0 ? 0 : parseToken(total, allowanceToken);

  let balanceToDisplayText = balanceToDisplay + " " + allowanceToken.caption;

  return (
    <View style={styles.balanceContainer}>
      <Text style={styles.balanceText}>{balanceToDisplayText}</Text>
      <Image
        style={styles.walletIcon}
        source={require("../assets/wallet-white.png")}
      />
    </View>
  );
};

export default BalanceDisplay;

const styles = StyleSheet.create({
  balanceContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  balanceText: {
    fontSize: hp(1.5),
    color: Config.Color.WHITE,
    marginRight: 5,
  },
  walletIcon: {
    resizeMode: "contain",
    height: hp(2),
    width: hp(2),
    marginRight: 5,
  },
});
