import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import { getScore } from "../../formatting";
import SquadaOneText from "../SquadaOneText";

const Score = ({ fixture, textStyle }) => {
  const { status } = fixture;
  const { FullPenalties, Penalties } = Config.Status;
  const { score, penaltyScore } = getScore(fixture);

  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <SquadaOneText
        adjustsFontSizeToFit
        numberOfLines={1}
        style={[styles.text, textStyle]}
      >
        {score.home}
        {[FullPenalties, Penalties].some((s) => s.CODE.includes(status))
          ? " (" + penaltyScore.home + ")"
          : ""}
      </SquadaOneText>
      <SquadaOneText
        adjustsFontSizeToFit
        numberOfLines={1}
        style={[styles.text, textStyle]}
      >
        {score.away}
        {[FullPenalties, Penalties].some((s) => s.CODE.includes(status))
          ? " (" + penaltyScore.away + ")"
          : ""}
      </SquadaOneText>
    </View>
  );
};
export default Score;

const styles = StyleSheet.create({
  text: {
    fontSize: hp(1.8),
    color: Config.Color.CY_PURPLE,
    marginVertical: hp(0.3),
  },
});
