import React from "react";
import { StyleSheet, View, FlatList, Platform } from "react-native";
import { Avatar } from "react-native-elements";
import { Loading, OswaldText, RubikText } from "../components";
import { Config } from "../config";
import Dimensions from "../dimensions";

const FavoritesScreen = ({ favorites, history }) => {
  if (!favorites) return <Loading />;
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Config.Color.LIGHT_GREY,
        paddingLeft: "5%",
        paddingBottom: "3%",
      }}
    >
      <View style={{ justifyContent: "center", height: "8%" }}>
        <RubikText variant="h1">MY FAVORITES</RubikText>
      </View>
      <View style={{ flex: 1 }}>
        {favorites
          .sort((a, b) => a.key - b.key)
          .map(({ title, data }) => (
            <View
              key={title}
              style={{ height: "30%", marginBottom: "1.6%" }}
              onMoveShouldSetResponderCapture={
                Platform.OS === "web" ? () => true : undefined
              }
            >
              <RubikText variant="h2" style={styles.categoryTitle}>
                {title.toUpperCase()}
              </RubikText>
              <FlatList
                data={data}
                horizontal
                renderItem={({ item }) => (
                  <View style={styles.itemContainer}>
                    <Avatar
                      source={{ uri: item.imageURL }}
                      containerStyle={styles.itemAvatar}
                      avatarStyle={{ resizeMode: "contain" }}
                      onPress={() => history.push(item.url)}
                    />
                    <View style={{ height: "15%" }}>
                      <OswaldText
                        adjustsFontSizeToFit
                        numberOfLines={1}
                        style={styles.itemTitle}
                      >
                        {item.name}
                      </OswaldText>
                    </View>
                  </View>
                )}
              />
            </View>
          ))}
      </View>
    </View>
  );
};
export default FavoritesScreen;

const styles = StyleSheet.create({
  categoryTitle: {
    color: Config.Color.DFM_DARK_PURPLE,
    paddingVertical: "1%",
  },
  itemContainer: {
    width: 0.25 * Dimensions.MAX_WIDTH,
    justifyContent: "center",
    alignItems: "center",
  },
  itemAvatar: {
    height: "85%",
    width: "100%",
    resizeMode: "contain",
    paddingBottom: "7%",
  },
  itemTitle: {
    fontSize: 0.015 * Dimensions.MAX_HEIGHT,
    color: Config.Color.DFM_DARK_PURPLE,
  },
});
