import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { SquadaOneText } from "../index";
import { hp } from "../../dimensions";

const ScoreInput = ({ prediction, showTitle, points }) => {
  let showPoints = points !== undefined;

  if (prediction === undefined)
    return (
      <View style={[styles.picksContainer, styles.notAddedContainer]}>
        <SquadaOneText style={styles.viewScores}>
          SCORE{"\n"}LATER
        </SquadaOneText>
      </View>
    );

  return (
    <View style={styles.picksContainer}>
      {showTitle && (
        <SquadaOneText
          adjustsFontSizeToFit
          numberOfLines={1}
          style={styles.title}
        >
          Your Score
        </SquadaOneText>
      )}
      <SquadaOneText style={styles.score}>
        {prediction.homeScore + " : " + prediction.awayScore}
      </SquadaOneText>
      {Boolean(showPoints) && (
        <SquadaOneText style={styles.points}>+{points} pts</SquadaOneText>
      )}
    </View>
  );
};
export default ScoreInput;

const styles = StyleSheet.create({
  title: {
    fontSize: hp(1.5),
    color: Config.Color.DFM_DARK_PURPLE,
    overflow: "visible",
  },
  score: {
    fontSize: hp(3),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  points: {
    fontSize: hp(1.5),
    color: Config.Color.DFM_LIGHT_PURPLE,
  },
  viewScores: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
  },
  notAddedContainer: {
    backgroundColor: Config.Color.WHITE,
  },
  picksContainer: {
    backgroundColor: Config.Color.DFM_YELLOW,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});
