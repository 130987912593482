import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import SquadaOneText from "./SquadaOneText";

const TabList = ({ tabs, selected, setSelected }) => {
  const tabWidth = Dimensions.MAX_WIDTH / tabs.length;
  return (
    <View style={styles.options}>
      {tabs.map((value, index) => (
        <TouchableOpacity
          style={
            selected === index
              ? [styles.option, { width: tabWidth }, styles.optionSelected]
              : [styles.option, { width: tabWidth }]
          }
          onPress={() => setSelected(index)}
          key={index}
        >
          <SquadaOneText
            adjustsFontSizeToFit
            numberOfLines={1}
            variant="h2"
            style={
              selected === index
                ? [styles.optionText, styles.optionTextSelected]
                : styles.optionText
            }
          >
            {value}
          </SquadaOneText>
        </TouchableOpacity>
      ))}
    </View>
  );
};
export default TabList;

const styles = StyleSheet.create({
  options: {
    flexDirection: "row",
    height: Dimensions.TAB_LIST_HEIGHT,
    backgroundColor: Config.Color.LIGHT_GREY,
    width: "100%",
  },
  option: {
    justifyContent: "center",
    alignItems: "center",
  },
  optionText: {
    fontSize: hp(3),
    textAlign: "center",
    color: Config.Color.DFM_DARK_PURPLE,
    opacity: 0.7,
  },
  optionSelected: {
    borderBottomColor: Config.Color.DFM_DARK_PURPLE,
    borderBottomWidth: 2,
  },
  optionTextSelected: {
    color: Config.Color.DFM_DARK_PURPLE,
    opacity: 1,
  },
});
