import { Platform } from "react-native";
import { Config } from "../config";
import { sortFixtures } from "../sorting";

export function adjustDates(fixture) {
  // This entire entire method is super duper hacky, we should aim
  // to get rid of this as soon as we migrate everything to GraphQL
  let tempFixture = JSON.parse(JSON.stringify(fixture));
  if (!(fixture.date instanceof Date)) {
    const fixtureDate = new Date(fixture.date.replace(" ", "T"));
    if (Platform.OS === "web" || Platform.OS === "ios") {
      fixtureDate.setMinutes(
        fixtureDate.getMinutes() - new Date().getTimezoneOffset()
      );
    }
    tempFixture.date = fixtureDate;
  }
  const updatedAtKey = tempFixture.updatedAt ? "updatedAt" : "updated_at";
  if (!(fixture[updatedAtKey] instanceof Date)) {
    const fixtureDate = new Date(
      fixture[updatedAtKey].replace(" ", "T").replace("Z", "")
    );
    if (Platform.OS === "web" || Platform.OS === "ios") {
      fixtureDate.setMinutes(
        fixtureDate.getMinutes() - new Date().getTimezoneOffset()
      );
    }
    tempFixture[updatedAtKey] = fixtureDate;
  }
  return tempFixture;
}

export function getDifferenceDays(a, b) {
  const diffTime = Math.abs(b - a);
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

function padZeroOnLeft(number) {
  return ("0" + number).slice(-2);
}

/**
 * Function takes in a Date object and returns the month in a text format.
 * @param date
 * @returns {string}
 */
function getMonthText(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = date.getMonth();
  return monthNames[month];
}

/**
 * Function takes in two Date objects and returns if they are in the exact same day or not.
 * @param d1
 * @param d2
 * @returns {boolean}
 */
function sameDay(d1, d2) {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
}

export function formatFixtureDate(date, isShort) {
  /**
   * Function takes in a Date object and returns the day of the week in a text format.
   * @param date
   * @returns {string}
   */
  function getWeekDay(date) {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = date.getDay();
    return weekdays[day];
  }

  const d = new Date();
  date = new Date(date);
  if (sameDay(d, date)) return "Today";

  const weekday = getWeekDay(date);
  d.setDate(d.getDate() + 1);
  if (sameDay(d, date)) return isShort ? weekday.slice(0, 3) : "Tomorrow";

  d.setDate(d.getDate() + 6);
  if (date > Date.now() && date < d)
    return isShort ? weekday.slice(0, 3) : weekday;

  return `${padZeroOnLeft(date.getDate())} ${getMonthText(date).slice(0, 3)}`;
}

export function formatHour(date) {
  date = new Date(date);
  return `${padZeroOnLeft(date.getHours())}h${
    date.getMinutes() ? padZeroOnLeft(date.getMinutes()) : ""
  }`;
}

export function formatDayTime(date) {
  date = new Date(date);
  return `${padZeroOnLeft(date.getHours())}:${padZeroOnLeft(
    date.getMinutes()
  )}`;
}

export function isToday(date) {
  const today = new Date();
  return sameDay(new Date(date), today);
}

export function formatFixturesDayDate(date) {
  const today = new Date();
  date = new Date(date);
  if (sameDay(date, today)) return "Today";

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (sameDay(date, yesterday)) return "Yesterday";

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (sameDay(date, tomorrow)) return "Tomorrow";

  return `${padZeroOnLeft(date.getDate())} ${getMonthText(date).slice(0, 3)}`;
}

export function getAgeByDateOfBirth(date) {
  date = new Date(date);
  const ageDifMs = Date.now() - date.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function isAlphanumeric(string) {
  return /^[a-z0-9]+$/i.test(string);
}

/**
 * Removes x from array and returns the new array.
 * @param x
 * @param array
 * @returns {*[]}
 */
export function removeFromArray(x, array) {
  const arr = [...array];
  const index = arr.indexOf(x);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function parseElapsedMinutes(date, status) {
  const { ExtraTimeFirstHalf, ExtraTimeSecondHalf, SecondHalf } = Config.Status;
  let minutes = Math.ceil(Math.abs(Date.now() - date) / 60000);
  let halfTime = [ExtraTimeFirstHalf.CODE, ExtraTimeSecondHalf.CODE].includes(
    status
  )
    ? 15
    : 45;
  let extraMinutes = null;
  if (minutes > halfTime) {
    extraMinutes = minutes - halfTime;
    minutes = halfTime;
  }
  if (SecondHalf.CODE.includes(status)) minutes += 45;
  if (ExtraTimeFirstHalf.CODE.includes(status)) minutes += 90;
  if (ExtraTimeSecondHalf.CODE.includes(status)) minutes += 105;
  return { minutes, extraMinutes };
}

export function textByResult(fixture, isShort) {
  function extractFirst3Letters(str) {
    return str.replace(/\s/g, "").substr(0, 3).toUpperCase();
  }

  const homeTeam = fixture.home_team ?? fixture.homeTeam;
  const awayTeam = fixture.away_team ?? fixture.awayTeam;
  return isShort
    ? [
        extractFirst3Letters(homeTeam.name),
        "DRAW",
        extractFirst3Letters(awayTeam.name),
      ]
    : [homeTeam.name, "Draw", awayTeam.name];
}

export function getScore(fixture) {
  const homeTeam = fixture.home_team ?? fixture.homeTeam;
  const awayTeam = fixture.away_team ?? fixture.awayTeam;
  const countGoals = (teamId) => (acc, event) =>
    acc +
    ((event.type === "GOAL" &&
      !event.disallowed &&
      (event.team_id ?? event.team.id) === teamId) ||
      (event.type === "PENALTY" &&
        !event.missed &&
        !event.is_shootout &&
        (event.team_id ?? event.team.id) === teamId));
  const countPenalties = (teamId) => (acc, event) => {
    const isShootout = event.is_shootout ?? event.isShootout;
    const eventTeamId = event.team_id ?? event.team.id;
    return (
      acc +
      (event.type === "PENALTY" &&
        !event.missed &&
        isShootout &&
        eventTeamId === teamId)
    );
  };
  const homeScore =
    fixture.score?.homeTeam ??
    fixture.events.reduce(countGoals(homeTeam.id), 0);
  const awayScore =
    fixture.score?.awayTeam ??
    fixture.events.reduce(countGoals(awayTeam.id), 0);
  const homePenaltyScore =
    fixture.events?.reduce(countPenalties(homeTeam.id), 0) ?? 0;
  const awayPenaltyScore =
    fixture.events?.reduce(countPenalties(awayTeam.id), 0) ?? 0;
  return {
    score: { home: homeScore, away: awayScore },
    penaltyScore: { home: homePenaltyScore, away: awayPenaltyScore },
  };
}

export function parseActiveCompetitions(competitions) {
  competitions.forEach(
    (c) =>
      (c["fixtures"] = c.fixtures
        .filter(
          (f) =>
            Config.Status.Upcoming.some((s) => s.CODE.includes(f.status)) &&
            f.date.getTime() > Date.now()
        )
        .sort(sortFixtures))
  );
  return competitions
    .filter((c) => c.fixtures.length)
    .sort((a, b) => sortFixtures(a.fixtures[0], b.fixtures[0]));
}

/**
 * Returns 0, if the home team wins
 * Returns 1, if it's a draw
 * Returns 2, if the away team wins
 * @param result - {homeScore, awayScore}
 * @returns {number|number}
 */
export function digestResult(result) {
  const sub = result.awayScore - result.homeScore;
  return sub === 0 ? 1 : sub / Math.abs(sub) + 1;
}
