import React from "react";
import { SectionList, StyleSheet, View } from "react-native";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "../../components";
import { Config } from "../../config";
import { hp } from "../../dimensions";
import { sortByMinutes } from "../../sorting";
import { FixtureStatus } from "../../types/globalTypes";
import { Card, Goal, Penalty, Substitution } from "./timeline-components";
import RobotoText from "../RobotoText";

const GET_FIXTURE_EVENTS = gql`
  fragment playerFields on Player {
    id
    name
    uniformNumber
    photo
    location {
      id
      name
      code
    }
  }
  query GetFixtureEvents($id: [ID]!) {
    fixtures(id: $id) {
      events {
        __typename
        minute
        minuteExtra
        team {
          id
          name
          logo
        }
        ... on Goal {
          player {
            ...playerFields
          }
          assistPlayer {
            ...playerFields
          }
          disallowed
        }
        ... on Substitution {
          playerIn {
            ...playerFields
          }
          playerOut {
            ...playerFields
          }
        }
        ... on Card {
          cardType
          player {
            ...playerFields
          }
        }
        ... on Penalty {
          player {
            ...playerFields
          }
          missed
          isShootout
        }
      }
    }
  }
`;

function getEventComponent(event, fixture) {
  let eventDetailsComponent;
  switch (event.__typename) {
    case "Card":
      eventDetailsComponent = (
        <Card event={event} isHome={event.team.id === fixture.homeTeam.id} />
      );
      break;
    case "Goal":
      eventDetailsComponent = (
        <Goal event={event} isHome={event.team.id === fixture.homeTeam.id} />
      );
      break;
    case "Substitution":
      eventDetailsComponent = (
        <Substitution
          event={event}
          isHome={event.team.id === fixture.homeTeam.id}
        />
      );
      break;
    case "Penalty":
      eventDetailsComponent = (
        <Penalty event={event} isHome={event.team.id === fixture.homeTeam.id} />
      );
      break;
    default:
      break;
  }
  return eventDetailsComponent;
}

export default function Timeline(props) {
  const { fixture } = props;

  const { data, loading } = useQuery(GET_FIXTURE_EVENTS, {
    variables: { id: fixture.id },
  });
  if (loading) return <Loading />;
  const events = data?.fixtures[0]?.events ?? [];

  return (
    <SectionList
      style={styles.sectionList}
      showsVerticalScrollIndicator={false}
      sections={splitEvents(events, fixture.status)}
      renderItem={({ item }) => getEventComponent(item, fixture)}
      renderSectionHeader={({ section: { title } }) => (
        <View style={styles.sectionHeader}>
          <RobotoText variant={"bold"} style={styles.sectionText}>
            {title}
          </RobotoText>
        </View>
      )}
      keyExtractor={(item, index) => index.toString()}
    />
  );
}

const splitEvents = (events, status) => {
  const {
    EXTRA_TIME_BREAK,
    EXTRA_TIME_FIRST_HALF,
    EXTRA_TIME_SECOND_HALF,
    FIRST_HALF,
    FULL_EXTRA_TIME,
    FULL_PENALTIES,
    FULL_TIME,
    FULL_TIME_BREAK,
    HALF_TIME,
    PENALTIES,
    SECOND_HALF,
  } = FixtureStatus;
  let n = 0;
  switch (status) {
    case FIRST_HALF:
    case HALF_TIME:
      n = 1;
      break;
    case SECOND_HALF:
    case FULL_TIME:
    case FULL_TIME_BREAK:
      n = 2;
      break;
    case EXTRA_TIME_BREAK:
    case EXTRA_TIME_FIRST_HALF:
    case EXTRA_TIME_SECOND_HALF:
    case FULL_EXTRA_TIME:
      n = 3;
      break;
    case FULL_PENALTIES:
    case PENALTIES:
      n = 4;
      break;
    default:
      break;
  }
  const isPenaltyShootout = (e) => e.type === "PENALTY" && e.isShootout;
  const headers = [
    {
      title: "FIRST HALF",
      filter: (e) => !isPenaltyShootout(e) && e.minute <= 45,
    },
    {
      title: "SECOND HALF",
      filter: (e) => !isPenaltyShootout(e) && e.minute > 45 && e.minute <= 90,
    },
    {
      title: "EXTRA TIME",
      filter: (e) => !isPenaltyShootout(e) && e.minute > 90,
    },
    { title: "PENALTY SHOOTOUT", filter: isPenaltyShootout },
  ];
  return headers.slice(0, n).map((item) => {
    return {
      title: item.title,
      data: events.filter(item.filter).sort(sortByMinutes),
    };
  });
};

const styles = StyleSheet.create({
  sectionList: {
    paddingHorizontal: 5,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionText: {
    fontSize: hp(1.5),
    textAlign: "center",
    color: Config.Color.GREY_67,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
});
