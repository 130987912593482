import React from "react";
import { Platform, StyleSheet, View, FlatList } from "react-native";
import { Config } from "../config";
import { hp, wp } from "../dimensions";
import OswaldText from "./OswaldText";

const Table = ({ headers, data }) => {
  if (data.length <= 0) return null;
  return (
    <View>
      <Header headers={headers} />
      <FlatList
        data={data}
        renderItem={({ item, index }) => <Row row={item} index={index} />}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};

export default Table;

const Header = ({ headers }) => {
  return (
    <View style={[styles.row, { marginTop: "5%" }]}>
      {Object.entries(headers).map(([k, v]) => (
        <View
          key={k}
          style={[
            styles.cell,
            { backgroundColor: Config.Color.GREY, width: v.width },
          ]}
        >
          <OswaldText
            adjustsFontSizeToFit
            numberOfLines={1}
            style={[styles.text, { color: Config.Color.WHITE }]}
          >
            {k}
          </OswaldText>
        </View>
      ))}
    </View>
  );
};

const Row = ({ row, index }) => {
  return (
    <View key={index} style={styles.row}>
      {row.map((value, index) => (
        <View key={index} style={[styles.cell, { width: value.width }]}>
          {value.component}
        </View>
      ))}
    </View>
  );
};

const stylesWeb = StyleSheet.create({
  row: {
    width: wp(80),
    flexDirection: "row",
  },
  cell: {
    borderWidth: 0.3,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: hp(1),
    paddingHorizontal: wp(1),
  },
});

const stylesNative = StyleSheet.create({
  row: {
    flexDirection: "row",
    paddingHorizontal: "1%",
  },
  cell: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0.3,
    paddingVertical: "1%",
    paddingHorizontal: "1%",
  },
});

const styles = Platform.OS === "web" ? stylesWeb : stylesNative;
