import React from "react";
import { StyleSheet, View } from "react-native";
import useSWR from "swr";
import { Config } from "../../config";
import { infuraWeb3Struct } from "../../web3/utils";
import SquadaOneText from "../SquadaOneText";

const fetcher = (url) =>
  infuraWeb3Struct.refereeInst.methods
    .getMatchClashesCount(url.substr(13))
    .call()
    .then((cs) => cs);

const LeaguesCounter = ({ fixtureId }) => {
  const { data: leaguesCounter } = useSWR(
    "matchClashes/" + fixtureId,
    fetcher,
    {
      revalidateIfStale: false,
    }
  );

  return (
    <View style={styles.leaguesContainer}>
      <SquadaOneText variant={"home_leagues_counter"}>
        {leaguesCounter}
      </SquadaOneText>
      <SquadaOneText variant={"home_leagues_counter"}>
        {"LEAGUE"}
        {leaguesCounter !== 1 && "S"}
      </SquadaOneText>
    </View>
  );
};
export default LeaguesCounter;

const styles = StyleSheet.create({
  leaguesContainer: {
    backgroundColor: Config.Color.WHITE,
    borderLeftWidth: 2,
    borderColor: Config.Color.DFM_DARK_PURPLE,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
});
