import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import SendFundsOverlay from "../overlays/SendFundsOverlay";
import { ActionButton } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";

const TransferButton = ({ balance, tokenAddress, userStruct }) => {
  const [sendFundsOverlayVisibility, setSendFundsOverlayVisibility] =
    useState(false);

  return (
    <View style={styles.buttonContainer}>
      <ActionButton
        variant={"primary"}
        onPress={() => setSendFundsOverlayVisibility(true)}
        title="SEND"
        titleStyle={{ color: Config.Color.DFM_DARK_PURPLE }}
        containerStyle={styles.buttonStyle}
      />
      <SendFundsOverlay
        isVisible={sendFundsOverlayVisibility}
        balance={balance}
        exit={() => setSendFundsOverlayVisibility(false)}
        tokenAddress={tokenAddress}
        userStruct={userStruct}
      />
    </View>
  );
};

export default TransferButton;

const styles = StyleSheet.create({
  buttonContainer: {
    width: hp(10),
  },
  buttonStyle: {
    width: "100%",
    height: hp(4),
  },
});
