import React, { useCallback, useEffect, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { gql } from "@apollo/client";
import { client } from "../apollo";
import { Config } from "../config";
import Dimensions from "../dimensions";
import {
  adjustDates,
  getDifferenceDays,
  parseActiveCompetitions,
} from "../formatting";
import { Route, Switch, Redirect } from "../router";
import {
  ClashScreen,
  CompetitionScreen,
  FixtureScreen,
  HomeScreen,
  LeaderboardScreen,
  ClashesScreen,
  TeamScreen,
  UserScreen,
} from "../screens";
import {
  sortLeaderboard,
  sortFixtures,
  sortUsersAlphabetically,
} from "../sorting";

const AppBody = ({
  createClash,
  logout,
  pendingPredictions,
  submitPrediction,
  switchLoginState,
  userStruct,
}) => {
  const [competitions, setCompetitions] = useState(null);
  const [fixturesByDay, setFixturesByDay] = useState(null);
  const [users, setUsers] = useState(null);

  const fetchCompetitions = useCallback(() => {
    const parseCompetitions = async (competitions) => {
      const LIMIT_OF_DAYS = 5;

      function filterByDate(fixture) {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 1);
        minDate.setHours(0, 0, 0, 0);
        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + LIMIT_OF_DAYS);
        maxDate.setHours(0, 0, 0, 0);
        return (
          (fixture.date.getTime() > minDate.getTime() &&
            fixture.date.getTime() < maxDate.getTime()) ||
          Config.Status.Live.some((s) => s.CODE.includes(fixture.status))
        );
      }

      let fixturesByDay = [];
      for (let i = 0; i <= LIMIT_OF_DAYS; i++) {
        fixturesByDay.push({
          key: i,
          data: [],
        });
      }

      const parsedCompetitions = [];
      const promises = competitions.map(async (competition) => {
        return new Promise(async (resolve) => {
          fetch(Config.COACH_URL + `/competition/${competition.id}/matches`)
            .then((response) => response.json())
            .then((json) => {
              let data = json
                .filter(
                  (fixture) =>
                    !Config.Status.Blacklist.some((s) =>
                      s.CODE.includes(fixture.status)
                    )
                )
                .map((f) => {
                  f["competition"] = competition;
                  return adjustDates(f);
                });
              const parsedCompetition = {
                id: competition.id,
                caption: competition.caption,
                logo_url: competition.logo_url,
                fixtures: data,
              };
              parsedCompetitions.push(parsedCompetition);
              data = data.filter(filterByDate);
              if (data.length) {
                data.forEach((m) => {
                  const minDate = new Date();
                  minDate.setDate(minDate.getDate() - 1);
                  minDate.setHours(0, 0, 0, 0);
                  const n = getDifferenceDays(minDate, m.date);
                  try {
                    fixturesByDay[n].data.push(m);
                  } catch (e) {
                    console.log(e + " for fixture with id " + m.id);
                  }
                });
              }
              resolve();
            })
            .catch(console.error);
        });
      });
      await Promise.all(promises);
      setCompetitions(parsedCompetitions.sort((a, b) => a.id - b.id));
      fixturesByDay = fixturesByDay.filter((item) => item.data.length);
      fixturesByDay.forEach((item) => item.data.sort(sortFixtures));
      return fixturesByDay;
    };

    fetch(Config.COACH_URL + `/competitions`)
      .then((result) => result.json())
      .then(parseCompetitions)
      .then(setFixturesByDay);
  }, []);

  const fetchUsers = () => {
    const getUsersList = client.query({
      query: gql`
        query Users {
          wallets {
            publicAddress
            user {
              username
            }
          }
        }
      `,
    });

    getUsersList.then(async (res) => {
      const { wallets } = res.data;
      const parsedUsers = [];
      const promises = wallets.map((wallet) => {
        return new Promise(async (resolve) => {
          parsedUsers.push({
            user: wallet.publicAddress,
            username: wallet.user.username,
          });
          resolve();
        });
      });
      await Promise.all(promises);
      setUsers(parsedUsers);
    });
  };

  useEffect(() => {
    fetchCompetitions();
    fetchUsers();
    const intervalId = setInterval(() => {
      fetchCompetitions();
      fetchUsers();
    }, 180000);
    return () => clearInterval(intervalId);
  }, [fetchCompetitions]);

  return (
    <View style={styles.appArea}>
      <Switch>
        <Route
          exact
          path={"/"}
          render={(routeProps) => (
            <HomeScreen {...routeProps} fixtures={fixturesByDay} />
          )}
        />
        <Route
          exact
          path="/competition/:id"
          render={(routeProps) => <CompetitionScreen {...routeProps} />}
        />
        <Route
          exact
          path={["/league/:id", "/clash/:id"]}
          render={(routeProps) => (
            <ClashScreen
              {...routeProps}
              logout={logout}
              submitPrediction={submitPrediction}
              switchLoginState={switchLoginState}
              users={users && users.sort(sortUsersAlphabetically)}
              userStruct={userStruct}
            />
          )}
        />
        <Route
          exact
          path={["/match/:id", "/match/:id/timeline"]}
          render={(routeProps) => (
            <FixtureScreen
              {...routeProps}
              competitions={
                competitions && parseActiveCompetitions(competitions)
              }
              createClash={createClash}
              logout={logout}
              pendingPredictions={pendingPredictions}
              switchLoginState={switchLoginState}
              users={users && users.sort(sortUsersAlphabetically)}
              userStruct={userStruct}
            />
          )}
        />
        <Route
          exact
          path="/team/:id"
          render={(routeProps) => <TeamScreen {...routeProps} />}
        />
        <Route
          exact
          path="/user/:username"
          render={(routeProps) => (
            <UserScreen
              {...routeProps}
              logout={logout}
              switchLoginState={switchLoginState}
              userStruct={userStruct}
            />
          )}
        />
        <Route
          exact
          path="/leaderboard"
          render={(routeProps) => (
            <LeaderboardScreen
              {...routeProps}
              users={users && users.sort(sortLeaderboard)}
            />
          )}
        />
        <Route
          exact
          path={["/leagues", "/clashes"]}
          render={(routeProps) => (
            <ClashesScreen
              {...routeProps}
              competitions={
                competitions && parseActiveCompetitions(competitions)
              }
              createClash={createClash}
              logout={logout}
              switchLoginState={switchLoginState}
              users={users && users.sort(sortUsersAlphabetically)}
              userStruct={userStruct}
            />
          )}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </View>
  );
};
export default AppBody;

const stylesWeb = StyleSheet.create({
  appArea: {
    height: Dimensions.MAX_APP_HEIGHT,
    width: "100%",
    backgroundColor: Config.Color.WHITE,
  },
});

const stylesNative = StyleSheet.create({
  appArea: {
    height: "88%",
    backgroundColor: Config.Color.WHITE,
  },
});

const styles = Platform.OS === "web" ? stylesWeb : stylesNative;
