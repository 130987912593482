import React from "react";
import { StyleSheet, View } from "react-native";
import { Config } from "../../config";
import { SquadaOneText } from "../index";
import { hp } from "../../dimensions";

const ScoreInputReadOnly = ({ scores }) => {
  let hasScores = scores !== undefined;
  let homeScore = "-";
  let awayScore = "-";
  if (hasScores) {
    homeScore = scores["homeScore"];
    awayScore = scores["awayScore"];
  }

  return (
    <View style={styles.outerContainer}>
      <View style={styles.predictionContainer}>
        <View style={styles.inputContainer}>
          <View style={styles.innerContainer}>
            <SquadaOneText style={styles.inputReadonly}>
              {homeScore}
            </SquadaOneText>
          </View>
        </View>
        <View style={styles.separatorContainer}>
          <View style={styles.separatorInnerContainer}>
            <SquadaOneText style={styles.separator}>:</SquadaOneText>
          </View>
        </View>
        <View style={styles.inputContainer}>
          <View style={styles.innerContainer}>
            <SquadaOneText style={styles.inputReadonly}>
              {awayScore}
            </SquadaOneText>
          </View>
        </View>
      </View>
    </View>
  );
};
export default ScoreInputReadOnly;

const styles = StyleSheet.create({
  outerContainer: {
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  predictionContainer: {
    flexDirection: "row",
  },
  inputContainer: {
    width: hp(8),
    height: hp(8),
    borderColor: Config.Color.DFM_DARK_PURPLE,
    borderWidth: 2,
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
    alignContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  inputReadonly: {
    fontSize: hp(5),
    textAlign: "center",
    color: Config.Color.WHITE,
  },
  separatorInnerContainer: {
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    height: "100%",
  },
  separatorContainer: {
    height: hp(8),
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
    paddingHorizontal: 5,
  },
  separator: {
    fontSize: hp(5),
    color: Config.Color.DFM_DARK_PURPLE,
    textAlign: "center",
  },
  label: {
    backgroundColor: Config.Color.DFM_YELLOW,
    paddingHorizontal: 4,
    paddingVertical: 2,
    marginTop: 2,
    borderRadius: 2,
  },
  labelText: {
    fontSize: hp(2),
    color: Config.Color.DFM_DARK_PURPLE,
  },
});
