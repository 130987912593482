import FontAwesome_ttf from "react-native-vector-icons/Fonts/FontAwesome.ttf";
import Entypo_ttf from "react-native-vector-icons/Fonts/Entypo.ttf";
import Material_ttf from "react-native-vector-icons/Fonts/MaterialIcons.ttf";
import OswaldBold_ttf from "./assets/fonts/Oswald-Bold.ttf";
import OswaldSemiBold_ttf from "./assets/fonts/Oswald-SemiBold.ttf";
import OswaldLight_ttf from "./assets/fonts/Oswald-Light.ttf";
import OswaldMedium_ttf from "./assets/fonts/Oswald-Medium.ttf";
import OswaldRegular_ttf from "./assets/fonts/Oswald-Regular.ttf";
import OswaldExtraLight_ttf from "./assets/fonts/Oswald-ExtraLight.ttf";
import RubikRegular_ttf from "./assets/fonts/Rubik-Regular.ttf";
import RubikMedium_ttf from "./assets/fonts/Rubik-Medium.ttf";
import RubikBold_ttf from "./assets/fonts/Rubik-Bold.ttf";
import RobotoBlack_ttf from "./assets/fonts/Roboto-Black.ttf";
import RobotoBlackItalic_ttf from "./assets/fonts/Roboto-BlackItalic.ttf";
import RobotoBold_ttf from "./assets/fonts/Roboto-Bold.ttf";
import RobotoBoldItalic_ttf from "./assets/fonts/Roboto-BoldItalic.ttf";
import RobotoItalic_ttf from "./assets/fonts/Roboto-Italic.ttf";
import RobotoLight_ttf from "./assets/fonts/Roboto-Light.ttf";
import RobotoLightItalic_ttf from "./assets/fonts/Roboto-LightItalic.ttf";
import RobotoMedium_ttf from "./assets/fonts/Roboto-Medium.ttf";
import RobotoMediumItalic_ttf from "./assets/fonts/Roboto-MediumItalic.ttf";
import RobotoRegular_ttf from "./assets/fonts/Roboto-Regular.ttf";
import RobotoThin_ttf from "./assets/fonts/Roboto-Thin.ttf";
import RobotoThinItalic_ttf from "./assets/fonts/Roboto-ThinItalic.ttf";
import SquadaOneRegular_ttf from "./assets/fonts/SquadaOne-Regular.ttf";

const IconsCSS = `
@font-face {
  src: url(${FontAwesome_ttf});
  font-family: FontAwesome;
}
@font-face {
  src: url(${Entypo_ttf});
  font-family: Entypo;
}
@font-face {
  src: url(${Material_ttf});
  font-family: MaterialIcons;
}
@font-face {
  src: url(${OswaldBold_ttf});
  font-family: Oswald-Bold;
}
@font-face {
  src: url(${OswaldSemiBold_ttf});
  font-family: Oswald-SemiBold;
}
@font-face {
  src: url(${OswaldLight_ttf});
  font-family: Oswald-Light;
}
@font-face {
  src: url(${OswaldMedium_ttf});
  font-family: Oswald-Medium;
}
@font-face {
  src: url(${OswaldRegular_ttf});
  font-family: Oswald-Regular;
}
@font-face {
  src: url(${OswaldExtraLight_ttf});
  font-family: Oswald-ExtraLight;
}
@font-face {
  src: url(${RubikRegular_ttf});
  font-family: Rubik-Regular;
}
@font-face {
  src: url(${RubikMedium_ttf});
  font-family: Rubik-Medium;
}
@font-face {
  src: url(${RubikBold_ttf});
  font-family: Rubik-Bold;
}
@font-face {
  src: url(${RobotoBlack_ttf});
  font-family: Roboto-Black;
}
@font-face {
  src: url(${RobotoBlackItalic_ttf});
  font-family: Roboto-BlackItalic;
}
@font-face {
  src: url(${RobotoBold_ttf});
  font-family: Roboto-Bold;
}
@font-face {
  src: url(${RobotoBoldItalic_ttf});
  font-family: Roboto-BoldItalic;
}
@font-face {
  src: url(${RobotoItalic_ttf});
  font-family: Roboto-Italic;
}
@font-face {
  src: url(${RobotoLight_ttf});
  font-family: Roboto-Light;
}
@font-face {
  src: url(${RobotoLightItalic_ttf});
  font-family: Roboto-LightItalic;
}
@font-face {
  src: url(${RobotoMedium_ttf});
  font-family: Roboto-Medium;
}
@font-face {
  src: url(${RobotoMediumItalic_ttf});
  font-family: Roboto-MediumItalic;
}
@font-face {
  src: url(${RobotoRegular_ttf});
  font-family: Roboto-Regular;
}
@font-face {
  src: url(${RobotoThin_ttf});
  font-family: Roboto-Thin;
}
@font-face {
  src: url(${RobotoThinItalic_ttf});
  font-family: Roboto-ThinItalic;
}
@font-face {
  src: url(${SquadaOneRegular_ttf});
  font-family: SquadaOne-Regular;
}
`;

const style = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
else style.appendChild(document.createTextNode(IconsCSS));

document.head.appendChild(style);
