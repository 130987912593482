/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CardType {
  RED = "RED",
  YELLOW = "YELLOW",
  YELLOW_RED = "YELLOW_RED",
}

export enum FixtureStatus {
  ABANDONED = "ABANDONED",
  AWAITING_UPDATES = "AWAITING_UPDATES",
  AWARDED = "AWARDED",
  CANCELLED = "CANCELLED",
  DELAYED = "DELAYED",
  DELETED = "DELETED",
  EXTRA_TIME_BREAK = "EXTRA_TIME_BREAK",
  EXTRA_TIME_FIRST_HALF = "EXTRA_TIME_FIRST_HALF",
  EXTRA_TIME_SECOND_HALF = "EXTRA_TIME_SECOND_HALF",
  FINISHED_AFTER_EXTRA_TIME = "FINISHED_AFTER_EXTRA_TIME",
  FIRST_HALF = "FIRST_HALF",
  FULL_TIME = "FULL_TIME",
  FULL_TIME_BREAK = "FULL_TIME_BREAK",
  FULL_TIME_PENALTY = "FULL_TIME_PENALTY",
  HALF_TIME = "HALF_TIME",
  INTERRUPTED = "INTERRUPTED",
  NOT_STARTED = "NOT_STARTED",
  PENALTY_LIVE = "PENALTY_LIVE",
  POSTPONED = "POSTPONED",
  SECOND_HALF = "SECOND_HALF",
  SUSPENDED = "SUSPENDED",
  TO_BE_ANNOUNCED = "TO_BE_ANNOUNCED",
  WALK_OVER = "WALK_OVER",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
