import React from "react";
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import DFMLogo from "../assets/defyme-name-without-io.png";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import { withRouter } from "../router";
import Loading from "./Loading";
import SquadaOneText from "./SquadaOneText";
import BalanceDisplay from "./BalanceDisplay";

const AppHeader = ({ history, toggleLoginScreen, userStruct, pathname }) => {
  const { account, initialLoading, username } = userStruct;
  const isInitialLoading = Boolean(initialLoading);

  let pageName = pathname.split("/")[1];
  let hasPageTitle;
  let pageTitle;
  switch (pageName) {
    case "leagues":
      hasPageTitle = true;
      pageTitle = "LEAGUES";
      break;
    case "league":
      hasPageTitle = true;
      pageTitle = "LEAGUE";
      break;
    case "team":
      hasPageTitle = true;
      pageTitle = "TEAM";
      break;
    case "competition":
      hasPageTitle = true;
      pageTitle = "COMPETITION";
      break;
    case "match":
      hasPageTitle = true;
      pageTitle = "MATCH";
      break;
    case "user":
      hasPageTitle = true;
      pageTitle = "PROFILE";
      break;
    default:
      hasPageTitle = false;
      break;
  }

  return (
    <View style={styles.header}>
      <View style={styles.insideContainer}>
        <TouchableOpacity
          style={styles.container}
          disabled={Platform.OS !== "ios"}
          onPress={history.goBack}
        >
          {Platform.OS === "ios" && (
            <Image
              style={styles.icon}
              source={require("../assets/back-arrow-white.png")}
            />
          )}
        </TouchableOpacity>
        <View style={[styles.container, { alignItems: "center", flex: 2 }]}>
          {hasPageTitle && (
            <SquadaOneText variant={"header"}>{pageTitle}</SquadaOneText>
          )}
          {!hasPageTitle && (
            <Image
              style={{ resizeMode: "contain", height: "50%", width: "80%" }}
              source={DFMLogo}
            />
          )}
        </View>
        {isInitialLoading && (
          <View style={[styles.container, { alignItems: "flex-end" }]}>
            <Loading
              color={Config.Color.WHITE}
              containerStyle={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
              }}
              height="88%"
              type="bubbles"
              width="40%"
            />
          </View>
        )}
        {!isInitialLoading && account && (
          <TouchableOpacity
            style={[styles.container, { alignItems: "flex-end" }]}
            onPress={() => {
              history.push("/user/" + username);
            }}
          >
            <BalanceDisplay walletAddress={account} />
          </TouchableOpacity>
        )}
        {!isInitialLoading && !account && (
          <TouchableOpacity
            style={[styles.container, { alignItems: "flex-end" }]}
            onPress={toggleLoginScreen}
          >
            <SquadaOneText
              adjustsFontSizeToFit
              numberOfLines={1}
              style={{
                fontSize: hp(2.2),
                color: Config.Color.WHITE,
                marginRight: "10%",
              }}
            >
              Sign in
            </SquadaOneText>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default withRouter(AppHeader);

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    height: Dimensions.BAR_HEIGHT,
    width: "100%",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  insideContainer: {
    flexDirection: "row",
    height: Dimensions.BAR_HEIGHT,
    width: "95%",
    marginLeft: "2.5%",
    backgroundColor: Config.Color.DFM_DARK_PURPLE,
  },
  container: {
    flex: 1,
    justifyContent: "center",
  },
  icon: {
    tintColor: Config.Color.WHITE,
    resizeMode: "contain",
    height: "55%",
    width: "28%",
  },
  sessionIcon: {
    resizeMode: "contain",
    height: hp(3),
    width: hp(3),
  },
  balanceContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  balanceText: {
    // backgroundColor: "red",
    fontSize: hp(1.5),
    color: Config.Color.WHITE,
    marginRight: 5,
  },
  loginButton: {
    color: Config.Color.WHITE,
    fontSize: hp(1.6),
  },
});
