import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Config } from "../config";
import Dimensions, { hp } from "../dimensions";
import RobotoText from "./RobotoText";
import { floorToken, parseToken } from "../web3/utils";

const BalancesToggleButton = ({
  options,
  selected,
  setSelected,
  setCurrMax,
}) => {
  const buttonWidth = Dimensions.MAX_WIDTH / options.length;

  return (
    <View style={styles.buttonsContainer}>
      {options.map((value, index) => (
        <TouchableOpacity
          style={
            selected === value.isAllowance
              ? [styles.button, { width: buttonWidth }, styles.buttonSelected]
              : [styles.button, { width: buttonWidth }]
          }
          onPress={() => {
            setSelected(value.isAllowance);
            setCurrMax(floorToken(value.balance, value.token));
          }}
          key={index}
        >
          <RobotoText
            style={
              selected === value.isAllowance
                ? [styles.buttonTitleSelected]
                : [styles.buttonTitle]
            }
          >
            {value.isAllowance ? "WELCOME BONUS" : "WALLET"}
          </RobotoText>
          <RobotoText>
            {parseToken(value.balance, value.token)} {value.token.caption}
          </RobotoText>
        </TouchableOpacity>
      ))}
    </View>
  );
};
export default BalancesToggleButton;

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    backgroundColor: Config.Color.WHITE,
    width: "100%",
    height: hp(6),
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSelected: {
    backgroundColor: Config.Color.YELLOW,
  },
  buttonTitle: {
    fontFamily: "Roboto-Regular",
    fontSize: hp(1.5),
  },
  buttonTitleSelected: {
    fontSize: hp(1.5),
    fontFamily: "Roboto-Bold",
  },
  buttonSubtitle: {
    fontSize: hp(1.5),
  },
});
