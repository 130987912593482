import React from "react";
import GoalImage from "../../../assets/goal.png";
import GoalDisallowedImage from "../../../assets/goal-disallowed.png";
import TimelineItem from "./TimelineItem";

export default function Goal({ event, isHome }) {
  const { player } = event;

  let hasSecondaryText = false;
  let iconSource = GoalImage;
  let secondaryText;
  if (event.disallowed) {
    hasSecondaryText = true;
    iconSource = GoalDisallowedImage;
    secondaryText = "(Goal Disallowed)";
  } else if (event.ownGoal) {
    hasSecondaryText = true;
    secondaryText = "(Own Goal)";
  } else if (event.assistPlayer) {
    hasSecondaryText = true;
    secondaryText = "(" + event.assistPlayer.name + ")";
  }

  return (
    <TimelineItem
      mainText={player.name}
      secondaryText={secondaryText}
      iconSource={iconSource}
      hasSecondaryText={hasSecondaryText}
      isHome={isHome}
      minutes={event.minute}
      minutesExtra={event.minuteExtra}
    />
  );
}
