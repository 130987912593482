import React from "react";
import { Text } from "react-native";

const OswaldText = (props) => {
  return (
    <Text {...props} style={[{ fontFamily: "Oswald-Regular" }, props.style]}/>
  );
};

export default OswaldText;
