import React from "react";
import {
  LoadingOverlay,
  LoginOverlay,
  MessageOverlay,
  SetUsernameOverlay,
} from "../overlays";
import { Config } from "../config";
import IntroOverlay from "../overlays/IntroOverlay";

export class LoginState {
  static DEFAULT = 0;
  static LOADING = 1;
  static LOGIN_SCREEN = 2;
  static ASK_TO_LOGIN = 3;
  static ASK_FOR_USERNAME = 4;
  static INITIAL_ASK_FOR_USERNAME = 5;
  static CHECK_EMAIL_INBOX = 6;
  static ASK_TO_REFRESH_LOGIN = 7;
  static SHOW_INTRO = 8;
  static USERNAME_SUCCESSFULLY_SET = 9;
}

export function checkLoginAndThen(
  calledFunction,
  switchState,
  userStruct,
  logout
) {
  function _checkLoginAndThen(calledFunction, switchState, userStruct, logout) {
    if (Boolean(logout) && Boolean(userStruct.isLightSessionOn)) {
      logout();
      switchState(LoginState.ASK_TO_REFRESH_LOGIN)();
    } else if (userStruct.account) {
      if (userStruct.username) {
        calledFunction(userStruct);
      } else {
        switchState(LoginState.SHOW_INTRO)();
      }
    } else {
      switchState(LoginState.ASK_TO_LOGIN)();
    }
  }

  if (userStruct.initialLoading) {
    switchState(LoginState.LOADING)();
    userStruct.initialLoading.then((_userStruct) => {
      switchState(LoginState.LOADING)();
      _checkLoginAndThen(calledFunction, switchState, _userStruct, logout);
    });
  } else {
    _checkLoginAndThen(calledFunction, switchState, userStruct, logout);
  }
}

const LoginSwitch = ({
  emailToLogin,
  fetchDataAfterRegistration,
  login,
  state,
  switchState,
  userStruct,
}) => {
  switch (state) {
    case LoginState.LOADING:
      return <LoadingOverlay loadingColor={Config.Color.DFM_PINK} isVisible />;
    case LoginState.LOGIN_SCREEN:
      return (
        <LoginOverlay
          login={login}
          toggleLoginScreen={switchState(LoginState.LOGIN_SCREEN)}
          toggleLoading={switchState(LoginState.LOADING)}
        />
      );
    case LoginState.ASK_TO_LOGIN:
      return (
        <MessageOverlay
          isVisible
          action={switchState(LoginState.LOGIN_SCREEN)}
          exit={switchState(LoginState.ASK_TO_LOGIN)}
          // missing the standby icon available on svg
          imageSource={require("../assets/off-light-purple.png")}
          trackingScreenName={"must_login"}
          buttonText="LOGIN"
          title="MUST LOGIN"
          text="To continue you need to login"
        />
      );
    case LoginState.ASK_FOR_USERNAME:
    case LoginState.INITIAL_ASK_FOR_USERNAME:
      return (
        <SetUsernameOverlay
          displaySetUsername={state}
          fetchDataAfterRegistration={fetchDataAfterRegistration}
          toggleSetUsername={switchState(state)}
          userStruct={userStruct}
        />
      );
    case LoginState.CHECK_EMAIL_INBOX:
      const subject = emailToLogin ?? "you";
      return (
        <MessageOverlay
          isVisible
          exit={switchState(LoginState.CHECK_EMAIL_INBOX)}
          title="MAGIC LINK SENT!"
          text={"An email with a link was sent to " + subject}
          imageSource={require("../assets/send-light-purple.png")}
          buttonText="CLOSE"
        />
      );
    case LoginState.ASK_TO_REFRESH_LOGIN:
      return (
        <MessageOverlay
          isVisible
          exit={switchState(LoginState.LOGIN_SCREEN)}
          // missing the standby icon available on svg
          imageSource={require("../assets/off-light-purple.png")}
          trackingScreenName={"session_expired"}
          buttonText="LOGIN"
          title="SESSION EXPIRED"
          text="To continue you need to login"
        />
      );
    case LoginState.SHOW_INTRO:
      return (
        <IntroOverlay
          isVisible
          endStep={() => switchState(LoginState.ASK_FOR_USERNAME)()}
          buttonText={"NEXT"}
        />
      );
    case LoginState.USERNAME_SUCCESSFULLY_SET:
      return (
        <MessageOverlay
          isVisible
          exit={switchState(LoginState.DEFAULT)}
          imageSource={require("../assets/offer-yellow.png")}
          trackingScreenName={"username_set_successfully"}
          buttonText="I'M READY"
          title="WELCOME TO THE GAME"
          text="Your welcome bonus is waiting for you! Head to our leagues page and check what's on!"
        />
      );
    default:
      return null;
  }
};
export default LoginSwitch;
