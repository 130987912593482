import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { SquadaOneText } from "./index";
import { Config } from "../config";
import { hp } from "../dimensions";

const Badge = ({ icon, text, containerStyle }) => {
  return (
    <View style={[styles.newLabel, containerStyle]}>
      {Boolean(icon) && <Image style={styles.newLabelIcon} source={icon} />}
      <SquadaOneText
        adjustsFontSizeToFit
        numberOfLines={1}
        style={[styles.newLabelText]}
      >
        {text}
      </SquadaOneText>
    </View>
  );
};

export default Badge;
const styles = StyleSheet.create({
  newLabel: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Config.Color.DFM_YELLOW,
    paddingHorizontal: "2%",
    paddingVertical: "1%",
    borderRadius: 5,
    alignSelf: "center",
  },
  newLabelIcon: {
    height: hp(1.8),
    width: hp(1.8),
    resizeMode: "contain",
    marginRight: "2%",
  },
  newLabelText: {
    fontSize: hp(1.8),
    overflow: "visible",
  },
});
