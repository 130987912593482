import React from "react";
import { View, FlatList, Platform } from "react-native";
import { LeaderboardItem, Loading, OswaldText } from "../components";
import { Config } from "../config";
import { hp } from "../dimensions";
import { shortenAddress } from "../web3/utils";

const LeaderboardScreen = ({ history, users }) => {
  if (!users) return <Loading />;
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Config.Color.LIGHT_GREY,
        paddingVertical: "3%",
      }}
    >
      <OswaldText
        style={{
          fontSize: hp(3.4),
          fontFamily: "Oswald-Bold",
          color: Config.Color.DFM_DARK_PURPLE,
          paddingLeft: "5%",
          paddingBottom: "3%",
        }}
      >
        LEADERBOARD
      </OswaldText>
      <View
        style={{ flex: 1 }}
        onMoveShouldSetResponderCapture={
          Platform.OS === "web" ? () => true : undefined
        }
      >
        <FlatList
          data={users}
          renderItem={({ item, index }) => {
            const user = users && users.find((u) => u.user === item.user);
            return (
              <LeaderboardItem
                name={user ? user.username : shortenAddress(item.user)}
                namePress={
                  user && (() => history.push("/user/" + user.username))
                }
                position={index + 1}
                value={item.balance}
                withCoin
              />
            );
          }}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    </View>
  );
};
export default LeaderboardScreen;
