import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { Config } from "../../config";
import { hp } from "../../dimensions";

const DataText = ({ style = {}, ...otherProps }) => (
  <View style={styles.container}>
    <View style={styles.textContainer}>
      <Text {...otherProps} style={[styles.text, { outline: "none" }, style]} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  text: {
    color: Config.Color.WHITE,
    fontFamily: "Roboto-Medium",
    fontSize: hp(2),
  },
  textContainer: {
    height: hp(6),
    paddingHorizontal: "2.5%",
    borderWidth: 2,
    borderColor: Config.Color.WHITE,
    justifyContent: "center",
  },
  container: {
    paddingHorizontal: 0,
  },
  errorStyle: { margin: 0 },
});

export default DataText;
